import React, { useState, useEffect } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { add_purchase_order, get_all_approved_vendors, get_all_products_against_vendors } from '../api/purchasesApi';
import { KTSVG } from '../../../../_metronic/helpers';
import { allPoType } from '../../../modules/accounts/components/settings/SettingsModel';
import { get_all_active_vendors, get_all_vendors } from '../../vendors/api/vendorsApi';
import { get_all_active_products } from '../../products/api/productsApi';
import AddNewProduct from './AddNewProduct';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';
import Select from 'react-select';

interface approvedVendorType {
    id: number;
    supplier_name: string | undefined;
}
interface ProductType {
    id: number;
    product_id: number;
    name: string;
    product_name: string;
    product_type: string;
    discountedPriceProduct: number;
    discountedPrice: number;
    price: number;
    quantity: number;
    rate: number;
    quotation_detail_price: object;
    created_at: string;
    updated_at: string;
}
const initialValues: any = {
    supplier_name: "",
    po_date: "",
};
const ApprovdVendorSchma = Yup.object().shape({
    supplier_name: Yup.string().required('Please Select a Vendor'),
    product_type: Yup.string().required('Please Select a Product Type'),
    po_date: Yup.date().required('Please Select a PO Date'),
});
function AddPurchase() {
    const [approvedVendor, setApprovedVendor] = useState<approvedVendorType[]>([]);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
    const [showAllVendor, setShowAllVendor] = useState<approvedVendorType[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
    const [priceProduct, setPriceProduct] = useState(1);
    const [product_type, setProduct_type] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [discountedPrice, setDiscountedPrice] = useState<any>(0);
    const [discountedPriceProduct, setDiscountedPriceProduct] = useState<any>(0);
    const [supplierValidationError, setSupplierValidationError] = useState('');
    const [selectedVendorProducts, setSelectedVendorProducts] = useState<any[]>([]);
    const [allProduct, setAllProduct] = useState<ProductType[]>([]);
    const [showAllVendors, setShowAllVendors] = useState(false);
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const { data, isError, error } = useQuery('approvedVendor', get_all_approved_vendors, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (data && data.data && Array.isArray(data.data)) {
            setApprovedVendor(data.data as approvedVendorType[]);
        }
    }, [data]);
    const { data: allProducts } = useQuery('allProductSystem', get_all_active_products, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (allProducts && Array.isArray(allProducts.data)) {
            setAllProduct(allProducts.data as ProductType[]);
        } else {
            setAllProduct([]);
        }
    }, [allProducts]);
    const { data: AllVendors } = useQuery('allVendors', get_all_active_vendors, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (AllVendors && Array.isArray(AllVendors.data)) {
            setShowAllVendor(AllVendors.data as approvedVendorType[]);
        }
    }, [AllVendors]);

    const { data: AllProductAgainstVendors } = useQuery('allProductsAgainstVendors', get_all_products_against_vendors, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (AllProductAgainstVendors && Array.isArray(AllProductAgainstVendors.data)) {
            setSelectedVendorProducts(AllProductAgainstVendors.data as ProductType[]);
        } else {
            // If no products against the vendor, set an empty array
            setSelectedVendorProducts([]);
        }
    }, [AllProductAgainstVendors]);

    useEffect(() => {
        // Initialize filteredProducts with all products
        setFilteredProducts(Array.isArray(allProduct) ? allProduct : []);
    }, [allProduct]);

    const handleDiscountedPriceChange = (productId, value) => {
        // Check if the value is either empty or a valid number
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue) || value === '') {
            // Update the product in the state
            setSelectedVendorProducts((prevProducts) =>
                prevProducts.map((prevProduct) =>
                    prevProduct.product_id === productId
                        ? {
                            ...prevProduct,
                            discount: isNaN(numericValue) ? 0 : numericValue, // If value is empty, set discount to 0
                        }
                        : prevProduct
                )
            );
        }
    };
    const generateTempId = () => {
        return Math.floor(100 + Math.random() * 900).toString(); // Generate a random 3-digit number
    };
    const handleProceedToPurchase = async () => {
        await formik.validateForm();
        if (formik.errors.supplier_name) {
            // If there are validation errors, set the error message
            setSupplierValidationError(formik.errors.supplier_name);
        } else {
            // If no validation errors, proceed with the purchase
            setSupplierValidationError('');
            setLoading(true);
        }
        try {
            const { supplier_name, po_date } = formik.values;
            const PO_date = po_date;
            const purchase_order_products = selectedVendorProducts.map((product) => {
                const { id, product_name, quantity, rate, created_at, updated_at, ...otherProductDetails } = product;
                let grandTotal = 0; // Replace this with the logic to get the rate
                const priceproduct = (product &&
                    (product.quotation_detail_price
                        ? product.quotation_detail_price[0]?.price
                        : product.rate)) * product.quantity;
                let finalPrice;
                if (product.discount) {
                    const discountedAmount = priceproduct - product.discount;
                    finalPrice = discountedAmount;
                } else {
                    // If no discount, the final price is the same as the calculated price
                    finalPrice = priceproduct;
                }
                totalPrice += finalPrice;
                if (discountTotal) {
                    const discountAmount = totalPrice - discountTotal;
                    grandTotal = discountAmount;
                } else {
                    grandTotal = totalPrice;
                }
                return {
                    id: id || generateTempId(),
                    product_name,
                    quantity,
                    rate: product.quotation_detail_price &&
                        product.quotation_detail_price.length > 0
                        ? product.quotation_detail_price[0].price
                        : priceProduct,
                    price: priceproduct,
                    discount: product.discount || 0,
                    final_price: finalPrice,
                    created_at,
                    updated_at,
                    ...otherProductDetails,
                };
            });
            const supplier_id = supplier_name;
            const grand_total = grandTotal;
            const purchaseData = {
                supplier_id,
                PO_date,
                grand_total,
                discount: discountTotal,
                status: "PO-Generated",
                purchase_order_products,
                // ...other formik values if needed
            };
            const response = await add_purchase_order(purchaseData);
            navigate('/purchase-order/purchase-order-list');
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
        } catch (error: any) {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
            }
        } finally {
            setLoading(false);
        };
    };
    const formik = useFormik<allPoType>({
        initialValues,
        validationSchema: ApprovdVendorSchma,
        onSubmit: handleProceedToPurchase,
    });
    let grandTotal = 0;
    let totalPrice = 0;
    const handleDiscountTotalChange = (e) => {
        const value = parseFloat(e.target.value);
        // Update the discountTotal state
        setDiscountTotal(isNaN(value) ? 0 : value);
    };
    const handleCheckboxChange = () => {
        setShowAllVendors(!showAllVendors);
    };
    const handleVendorSelection = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        formik.handleChange(e);
        const selectedVendorId = e.target.value;
        // Clear the selected products and reset grandTotal when changing vendors
        setSelectedVendorProducts([]);
        grandTotal = 0;
        setDiscountTotal(0);
        // Fetch products for the selected vendor
        if (selectedVendorId) {
            queryClient.invalidateQueries('allProductsAgainstVendors');
            queryClient.prefetchQuery(['productsAgainstVendor', selectedVendorId], () =>
                get_all_products_against_vendors(selectedVendorId)
            );
            try {
                const response = await get_all_products_against_vendors(selectedVendorId);
                // Set the selectedVendorProducts to the response data (empty array if no products)
                setSelectedVendorProducts(response.data || []);
            } catch (error) {

            }
        }
    };
    const navigate = useNavigate();
    // Handle Product in Modal
    const handleProductChange = (e) => {
        const selectedProductId = e.value;
        setSelectedProduct(selectedProductId);
        // Find the selected product details
        const selectedProductDetails = allProduct.find((product) => product.id === parseInt(selectedProductId));
        // Update the state with selected product details
        if (selectedProductDetails) {
            setPriceProduct(selectedProductDetails.price || 1);
            setProduct_type(selectedProductDetails.product_type || '');
            setDiscountedPriceProduct(selectedProductDetails.discountedPrice || 0);
            setQuantity(selectedProductDetails.quantity || 1);
            // You may set other related fields here if needed
        } else {
            // // Handle the case when the selected product details are not found
            setSelectedProduct(null);
            setPriceProduct(1);
            setProduct_type('');
            setDiscountedPrice(0);
            // Reset other related fields if needed
        }
    };
    const handleAddProduct = () => {
        // Check if a product is selected
        if (selectedProduct) {
            // Find the selected product details directly using the selectedProduct object
            const selectedProductDetails = allProduct.find((product) => product.id === parseInt(selectedProduct));
            // Check if selectedProductDetails is defined
            if (selectedProductDetails) {
                // Check if the product with the selected ID already exists in the table
                const isProductExists = Array.isArray(selectedVendorProducts) && selectedVendorProducts.some(product => product.product_id === selectedProductDetails.id);
                if (isProductExists) {
                    // Show a toast message if the product already exists
                    toast.warning('Product Already Exists in the Table.', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                } else {
                    // Create a new product object with details
                    const newProduct = {
                        product_id: selectedProductDetails.id,
                        product_name: selectedProductDetails.name || '',
                        quantity: quantity || 1,
                        rate: selectedProductDetails.price || 0, // Set the rate here
                        discount: discountedPriceProduct,
                        finalPrice: selectedProductDetails.price * quantity || 1,
                    };
                    // Update the state with the new product
                    setSelectedVendorProducts((prevProducts) => {
                        const updatedProducts = Array.isArray(prevProducts) ? [...prevProducts, newProduct] : [newProduct];
                        return updatedProducts;
                    });
                    // Clear input values for the next product
                    setSelectedProduct(null);
                    setProduct_type('');
                    setDiscountedPriceProduct(0);
                    setQuantity(1);
                    // Show a success toast message
                    toast.success('Product Added Successfully.', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                }
            }
        }
    };
    const handleQuantityChange = (productId: number, value: string) => {
        if (value === '' || !isNaN(Number(value))) {
            setSelectedVendorProducts((prevProducts: any) =>
                prevProducts.map((product) =>
                    product.product_id === productId ? { ...product, quantity: value === '' ? '' : Number(value) } : product
                )
            );
        }
    };
    const handlePriceChange = (productId, value) => {
        // Check if the entered value is either empty or a valid number
        const numericValue = parseFloat(value);
        // Format the numeric value to have only two decimal places
        const formattedValue = isNaN(numericValue) ? '' : parseFloat(numericValue.toFixed(2));
        // Find the product in the state and update its price
        setSelectedVendorProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.product_id === productId
                    ? {
                        ...product,
                        quotation_detail_price: product.quotation_detail_price && product.quotation_detail_price.length > 0
                            ? [{ ...product.quotation_detail_price[0], price: formattedValue }]
                            : [{ price: formattedValue }] // Create a new price object if quotation_detail_price is undefined or empty
                    }
                    : product
            )
        );
    };
    // Define a function to handle product removal
    const handleRemoveProduct = (productId) => {
        // Implement the logic to remove the product from state
        setSelectedVendorProducts((prevProducts) =>
            prevProducts.filter((product) => product.id !== productId)
        );
    };
    // Add an event listener for keydown events
    const handleKeyDown = (event, productId) => {
        // Check if the pressed key is Enter (key code 13)
        if (event.keyCode === 13) {
            const targetTagName = event.target.tagName.toLowerCase();

            // Check if the event target is a button
            if (targetTagName === 'button') {
                event.preventDefault(); // Prevent the default form submission behavior
                handleRemoveProduct(productId);
            }
        }
    };
    return (
        <div className='card card-custom shadow'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Create Purchase Order</h3>
                </div>
            </div>
            <div className='card-header border-0 pt-5'>
                <div className="card-body">
                    <Form>
                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>PO Date</span>
                            </label>
                            <div className='col-lg-4 fv-row'>
                                <input
                                    type='date'
                                    className='form-control form-control-lg form-control-solid'
                                    {...formik.getFieldProps('po_date')}
                                    value={formik.values.po_date || ''}
                                />

                                {formik.errors.po_date && (
                                    <div className='text-danger'>{formik.errors.po_date}</div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                <span className='required'>Vendor</span>
                            </label>
                            <div className='col-lg-4 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('supplier_name')}
                                    onChange={handleVendorSelection}
                                >
                                    <option value=''>Select a Vendor...</option>
                                    {showAllVendors ? (
                                        <>
                                        {
                                            Array.isArray(showAllVendor) && showAllVendor.length > 0 ? (
                                                showAllVendor.map((vendor) => (
                                                    <option key={vendor.id} value={vendor.id}>
                                                        {vendor.supplier_name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value='' disabled>No vendors available</option>
                                            )
                                        }
                                        </>
                                    ) : (
                                        <>
                                        {
                                            Array.isArray(approvedVendor) && approvedVendor.length > 0 ? (
                                                approvedVendor.map((vendor) => (
                                                    <option key={vendor.id} value={vendor.id}>
                                                        {vendor.supplier_name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value='' disabled>No vendors available</option>
                                            )
                                        }
                                        </>
                                    )}

                                </select>
                                {formik.errors.supplier_name && (
                                    <div className='text-danger'>{formik.errors.supplier_name}</div>
                                )}
                            </div>
                            <div className='col-lg-5 mt-4'>
                                <div className='form-check form-switch'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='showAllVendor'
                                        onChange={handleCheckboxChange}
                                        checked={showAllVendors}
                                    />
                                    <label className='form-check-label' htmlFor='showAllVendor'>
                                        Show All Vendors
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div
                    className='card-toolbar'
                >
                    <div className='card-title align-items-start flex-column'>
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Navigate to Add New Product"
                            className='btn btn-sm btn-light-dark'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_2'
                        >
                            <KTIcon iconName='plus' className='fs-3' />
                            Add New Product
                        </button>
                    </div>
                    <button
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Add Product"
                        onClick={() => {
                            if (formik.values.supplier_name) {
                                setSelectedProduct("");
                                setDiscountedPrice(0);
                                setQuantity(1);
                                // Open the modal only if a vendor is selected
                                const modalElement = document.getElementById("kt_modal_1");
                                if (modalElement) {
                                    modalElement.style.display = "block";
                                }
                            } else {
                                // Optionally, you can show an alert or handle the case when no vendor is selected
                                alert("Please Select a Vendor before Adding a Product.");
                            }
                        }}
                        className="btn btn-primary"
                        disabled={!formik.values.supplier_name} // Disable the button if no vendor is selected
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_1"
                    >
                        Add Product
                    </button>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <Form>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='min-w-100px required'>Product</th>
                                    <th className='min-w-100px required'>Quantity</th>
                                    <th className='min-w-140px required'>Rate ({getSettingValue('currency_symbol')})</th>
                                    <th className='min-w-160px required'>Price ({getSettingValue('currency_symbol')})</th>
                                    <th className='min-w-170px'>Discount ({getSettingValue('currency_symbol')})</th>
                                    <th className='min-w-200px required'>Final Price ({getSettingValue('currency_symbol')})</th>
                                    <th className='min-w-100px text-end'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Inside the table body */}
                                {showAllVendors
                                    ? selectedVendorProducts.length > 0 &&
                                    selectedVendorProducts.map((product) => {
                                        const price = (product &&
                                            (product.quotation_detail_price
                                                ? product.quotation_detail_price[0]?.price
                                                : product.rate)) * product.quantity;
                                        let finalPrice;

                                        if (product.discount || discountedPriceProduct) {
                                            const discountedAmount = price - product.discount;
                                            finalPrice = discountedAmount;
                                        } else {
                                            // If no discount, the final price is the same as the calculated price
                                            finalPrice = price;
                                        }
                                        totalPrice += finalPrice;
                                        if (discountTotal) {
                                            const discountAmount = totalPrice - discountTotal;
                                            grandTotal = discountAmount;
                                        } else {
                                            grandTotal = totalPrice;
                                        }
                                        return (
                                            <tr key={product.id}>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="text"
                                                        {...formik.getFieldProps(`product_name.${product.id}`)}
                                                        readOnly  // Make the input read-only to display the calculated value
                                                        value={product.product_name}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="text"
                                                        placeholder='Quantity'
                                                        {...formik.getFieldProps(`quantity.${product.product_id}`)}
                                                        onChange={(e) => handleQuantityChange(product.product_id, e.target.value)}
                                                        value={product.quantity}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="text"
                                                        placeholder='Rate'
                                                        {...formik.getFieldProps(`rate.${product.product_id}`)}
                                                        onChange={(e) => handlePriceChange(product.product_id, e.target.value)}
                                                        value={
                                                            product.quotation_detail_price &&
                                                                product.quotation_detail_price.length > 0
                                                                ? product.quotation_detail_price[0].price
                                                                : product.rate
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="number"
                                                        {...formik.getFieldProps(`price.${product.id}`)}
                                                        readOnly  // Make the input read-only to display the calculated value
                                                        value={price.toFixed(2)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="text"
                                                        placeholder='Discount'
                                                        {...formik.getFieldProps(`discount.${product.product_id}`)}
                                                        onChange={(e) => handleDiscountedPriceChange(product.product_id, e.target.value)}
                                                        value={product.discount ? product.discount : discountedPriceProduct}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="number"
                                                        {...formik.getFieldProps(`final_price.${product.id}`)}
                                                        readOnly  // Make the input read-only to display the calculated value
                                                        value={finalPrice.toFixed(2)}
                                                    />
                                                </td>
                                                <td className='text-end'>
                                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                                        {/* Add a Remove button with an onClick event handler */}
                                                        <button
                                                            type='button'
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Delete Product"
                                                            className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                                                            onClick={() => handleRemoveProduct(product.id)}
                                                            onKeyDown={(e) => handleKeyDown(e, product.id)}
                                                            tabIndex={0}
                                                        >
                                                            <KTIcon iconName='trash' className='fs-3' />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : selectedVendorProducts.length > 0 &&
                                    selectedVendorProducts.map((product) => {
                                        const price = (product &&
                                            (product.quotation_detail_price
                                                ? product.quotation_detail_price[0]?.price
                                                : product.rate)) * product.quantity;
                                        let finalPrice;

                                        if (product.discount || discountedPriceProduct) {
                                            const discountedAmount = price - product.discount;
                                            finalPrice = discountedAmount;
                                        } else {
                                            // If no discount, the final price is the same as the calculated price
                                            finalPrice = price;
                                        }
                                        totalPrice += finalPrice;
                                        if (discountTotal) {
                                            const discountAmount = totalPrice - discountTotal;
                                            grandTotal = discountAmount;
                                        } else {
                                            grandTotal = totalPrice;
                                        }
                                        return (
                                            <tr key={product.id}>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="text"
                                                        {...formik.getFieldProps(`product_name.${product.id}`)}
                                                        readOnly  // Make the input read-only to display the calculated value
                                                        value={product.product_name}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="text"
                                                        placeholder='Quantity'
                                                        {...formik.getFieldProps(`quantity.${product.product_id}`)}
                                                        onChange={(e) => handleQuantityChange(product.product_id, e.target.value)}
                                                        value={product.quantity}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="text"
                                                        placeholder='Rate'
                                                        {...formik.getFieldProps(`rate.${product.product_id}`)}
                                                        onChange={(e) => handlePriceChange(product.product_id, e.target.value)}
                                                        value={
                                                            product.quotation_detail_price &&
                                                                product.quotation_detail_price.length > 0
                                                                ? product.quotation_detail_price[0].price
                                                                : product.rate
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="number"
                                                        {...formik.getFieldProps(`price.${product.id}`)}
                                                        readOnly  // Make the input read-only to display the calculated value
                                                        value={price.toFixed(2)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="text"
                                                        placeholder='Discount'
                                                        {...formik.getFieldProps(`discount.${product.product_id}`)}
                                                        onChange={(e) => handleDiscountedPriceChange(product.product_id, e.target.value)}
                                                        value={product.discount ? product.discount : discountedPriceProduct}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className='card'
                                                        type="number"
                                                        {...formik.getFieldProps(`final_price.${product.id}`)}
                                                        readOnly  // Make the input read-only to display the calculated value
                                                        value={finalPrice.toFixed(2)}
                                                    />
                                                </td>
                                                <td className='text-end'>
                                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                                        {/* Add a Remove button with an onClick event handler */}
                                                        <button
                                                            type='button'
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Delete Product"
                                                            className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                                                            onClick={() => handleRemoveProduct(product.id)}
                                                            onKeyDown={(e) => handleKeyDown(e, product.id)}
                                                            tabIndex={0}
                                                        >
                                                            <KTIcon iconName='trash' className='fs-3' />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                        {/* Additional Field: Total Price */}
                                        Total Price ({getSettingValue('currency_symbol')}):
                                        <input
                                            className='card'
                                            type="number"
                                            {...formik.getFieldProps('total_price')}
                                            readOnly
                                            value={totalPrice.toFixed(2)}
                                        />
                                    </td>
                                    <td colSpan={2}>
                                        Total Discount({getSettingValue('currency_symbol')}):
                                        <input
                                            className='card'
                                            type="text"
                                            placeholder='Total Discount'
                                            {...formik.getFieldProps('discount')}
                                            onChange={handleDiscountTotalChange}
                                            value={discountTotal.toFixed(2)}
                                        />
                                    </td>
                                    <td colSpan={2}>
                                        {/* Grand Total */}
                                        Grand Total ({getSettingValue('currency_symbol')}):
                                        <input
                                            className='card'
                                            type="number"
                                            {...formik.getFieldProps('grand_total')}
                                            readOnly
                                            value={grandTotal.toFixed(2)}
                                        />
                                    </td>
                                    <td className='min-w-140px'></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={1}></td>
                                </tr>
                                {/* Additional fields go here */}
                            </tfoot>
                        </table>
                    </Form>
                </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title='Proceed To Purchase'
                    type='button'
                    className='btn btn-primary'
                    disabled={loading || !!formik.errors.supplier_name || !!formik.errors.po_date || formik.isValid}
                    onClick={handleProceedToPurchase}
                >
                    {!loading && 'Proceed To Purchase'}
                    {loading && (
                        <span className='indicator-progress d-block'>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Add Product</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <Form>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Product</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Select
                                            placeholder="Select "
                                            options={filteredProducts.map((product) => ({
                                                value: product.id,
                                                label: product.name,
                                            }))}
                                            className="basic-multi-select form-select-lg p-2 bg-light text-black"
                                            classNamePrefix="select border-light fw-bold"
                                            onChange={handleProductChange}
                                        />
                                    </div>
                                </div>
                                {selectedProduct && (
                                    <div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Product Type</label>
                                            <div className='col-lg-8 fv-row'>
                                                <select
                                                    className='form-control form-control-lg form-control-solid'
                                                    value={product_type}
                                                    onChange={(e) => setProduct_type(e.target.value)}
                                                >
                                                    <option value='asset'>Asset</option>
                                                    <option value='consumable'>Consumable</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Quantity</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Quantity'
                                                    value={quantity}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Rate ({getSettingValue('currency_symbol')})</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Rate'
                                                    value={priceProduct}
                                                    onChange={(e: any) => setPriceProduct(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Discounted Price ({getSettingValue('currency_symbol')})</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Discount'
                                                    value={discountedPriceProduct}
                                                    onChange={(e: any) => setDiscountedPriceProduct(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>)}
                            </Form>
                        </div>
                        <div className='modal-footer'>
                            <button
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Close"
                                type='button'
                                className='btn btn-light'
                                data-bs-dismiss='modal'
                            >
                                Close
                            </button>
                            <button
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Add Product"
                                className='btn btn-sm btn-light-dark'
                                onClick={() => {
                                    handleAddProduct();
                                }}
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_1'
                            >
                                <KTIcon iconName='plus' className='fs-3' />
                                Add Product
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            {/* New Product Modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Add New Product</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <AddNewProduct />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddPurchase;