import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon } from '../../../../_metronic/helpers';
import { KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { formatDateFlatpickr, formatDateWithTime, formatDateWithoutTime } from '../../../helper-functions/DateFunction';
import Flatpickr from 'react-flatpickr';
import { delete_stock_out, get_all_filters_item_stock_out, get_all_stock_out } from '../api/stocksApi';
import { get_all_department } from '../../departments/api/departmentsApi';
import { get_all_users } from '../../users/api/usersApi';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
type StockOut = {
    id: number;
    stock_type: string;
    department_id: number | undefined;
    department_name: string | undefined;
    user_id: number | undefined;
    user_name: string | undefined;
    date_time: string;
    updated_by_name: string | undefined;
    updated_at: string;
};
interface DepartmentType {
    id: number;
    name: string | undefined;
}
interface UserType {
    id: number;
    full_name: string | undefined;
};
var stock_out_add, stock_out_edit, stock_out_view, stock_out_logs, stock_out_delete
function StockOutList() {
    const [stockOutList, setStockOutList] = useState<StockOut[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [showAllDepartment, setShowAllDepartment] = useState<DepartmentType[]>([]);
    const [showAllUser, setShowAllUser] = useState<UserType[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleData, setVisibleData] = useState<StockOut[]>([]);
    const [selectedStockOut, setSelectedStockOut] = useState<StockOut | null>();
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [fromDateFilter, setFromDateFilter] = useState('');
    const [toDateFilter, setToDateFilter] = useState('');
    const fromDateRef = useRef<Flatpickr>(null);
    const toDateRef = useRef<Flatpickr>(null);
    const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null);
    const navigate = useNavigate();
    stock_out_add = permissionCheck('stock-create')
    stock_out_edit = permissionCheck('stock-update')
    stock_out_view = permissionCheck('stock-view')
    stock_out_logs = permissionCheck('stock-activity-log')
    stock_out_delete = permissionCheck('stock-delete')
    const { data: filterData, isLoading: isStockOutLoading, isError: isStockOutError, refetch: filterRefetch } = useQuery<any>(
        'filterData',
        () => fromDateFilter || toDateFilter || selectedDepartment || selectedUser
            ? get_all_filters_item_stock_out(fromDateFilter, toDateFilter, selectedDepartment, selectedUser)
            : get_all_stock_out(),
        {
            refetchOnWindowFocus: false,
            onSuccess: (filteredData) => {
                setStockOutList(filteredData?.data);
            },
            onError: (error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    });
                }
            },
        }
    );
    const handleApplyFilters = () => {
        filterRefetch(); // Call the function to fetch data with applied filters
    };
    const handleClearFilters = () => {
        if (fromDateRef.current) {
            fromDateRef.current.flatpickr.clear();
        }
        if (toDateRef.current) {
            toDateRef.current.flatpickr.clear();
        }
        setFromDateFilter('');
        setToDateFilter('');
        setSelectedDepartment('');
        setSearchInput('');
        setSelectedUser('');
        filterRefetch()
    };
    const { data: AllDepartments } = useQuery('allDepartments', get_all_department, { refetchOnWindowFocus: false });
    useEffect(() => {
        if (AllDepartments && Array.isArray(AllDepartments.data)) {
            setShowAllDepartment(AllDepartments.data as DepartmentType[]);
        }
    }, [AllDepartments]);
    const { data: AllUsers } = useQuery('allUser', get_all_users, { refetchOnWindowFocus: false });
    useEffect(() => {
        if (AllUsers && Array.isArray(AllUsers.data)) {
            setShowAllUser(AllUsers.data as UserType[]);
        }
    }, [AllUsers]);

    const itemsPerPage = [10, 20, 30, 50, 100];
    const totalItems = stockOutList.length;
    const totalPages = Math.ceil(totalItems / selectedItemsPerPage)
    useEffect(() => {
        if (stockOutList.length > 0) {
            let sortedData = [...stockOutList];
            if (sortBy) {
                sortedData.sort((a, b) => {
                    const aValue = a[sortBy.key] || '';
                    const bValue = b[sortBy.key] || '';
                    if (typeof aValue === 'string' && typeof bValue === 'string') {
                        return sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                    }
                    return sortBy.ascending ? aValue - bValue : bValue - aValue;
                });
            }
            const startIndex = (currentPage - 1) * selectedItemsPerPage;
            const endIndex = currentPage * selectedItemsPerPage;
            const visibleData = sortedData.slice(startIndex, endIndex);
            setVisibleData(visibleData);
        }
    }, [stockOutList, currentPage, selectedItemsPerPage, sortBy]);

    const handleDeleteStockOut = useMutation((id: number) => {
        return delete_stock_out(id)
            .then((response) => {
                toast.success(response.message, {
                    position: 'top-right',
                    autoClose: 3000,
                });
            })
            .then(() => {
                return get_all_stock_out().then((data) => {
                    setStockOutList(data.data);
                });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                };
            });
    });
    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const searchTerm = e.currentTarget.value;
        setSearchInput(searchTerm);
        const filteredItems = filterData.data.filter((stock) => {
            return (
                stock.user_name?.toLowerCase().includes(searchTerm) ||
                stock.department_name?.toLowerCase().includes(searchTerm)
            )
        });
        setStockOutList(filteredItems);
    };
    const handleDateFromChange = (date) => {
        if (date && date[0]) {
            const selectedDate = new Date(date[0]);
            selectedDate.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(selectedDate);
            setFromDateFilter(formattedDate);

            // Update the "To Date" Flatpickr options
            if (toDateRef.current) {
                toDateRef.current.flatpickr.set('minDate', selectedDate);
            }
        } else {
            setFromDateFilter('');
            // Reset the "To Date" picker minDate when "From Date" is cleared
            if (toDateRef.current) {
                toDateRef.current.flatpickr.set('minDate', null);
            }
        }
    };
    const handleDateToChange = (date) => {
        if (date && date[0]) {
            const selectedDate = new Date(date[0]);
            selectedDate.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(selectedDate);
            setToDateFilter(formattedDate);
        } else {
            setToDateFilter('');
        }
    };
    const handleSort = (key: string) => {
        if (!sortBy || sortBy.key !== key || !sortBy.ascending) {
            setSortBy({ key, ascending: true }); // Sort in ascending order initially
        } else {
            setSortBy({ key, ascending: false }); // Sort in descending order
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleStockOutLogs = (stock: any) => {
        navigate("/stocks/stock-out-logs", { state: { id: stock.id } });
    };
    const handleEditStockOut = (stock: any) => {
        navigate("/stocks/edit-stock-out", { state: { stock: stock } });
    };
    const handleViewStockOut = (stock) => {
        navigate("/stocks/view-stock-out", { state: { id: stock.id } });
    }
    return (
        <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>List of Stock Out</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    {stock_out_add && (
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Navigate to Stock Out"
                            className='btn btn-sm btn-light-dark'
                            onClick={() => navigate('/stocks/add-stock-out')}
                        >
                            <KTIcon iconName='plus' className='fs-3' />
                            Stock Out
                        </button>
                    )}
                </div>
            </div>
            {/* commenting for future use */}
            {/* filter is not currently working because api's hasn't created yet for filtering */}
            {/* <div className='col-lg-6 row '>
                    <div className='col-lg-2 p-3 fv-row'>
                        <h5>Filter by:</h5>
                    </div>
                    <div className='col-lg-5 fv-row'>
                        <Form.Group controlId='type'>
                            <Form.Select
                                name='type'
                                className='form-select form-select-solid form-select-lg fw-bold'
                            >
                                <option value={1}>Date</option>
                                <option value={2}>Price</option>
                                <option value={3}>Status</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='col-lg-5 fv-row'>
                        {secondFilter === 1 && (
                            <Form.Group controlId='subCategorytypeNew'>
                                <Form.Select
                                    name='subCategorytypeNew'
                                    className={`form-select form-select-solid form-select-lg fw-bold`}
                                >
                                    <option value={"PO-Generated"}>Sort by</option>
                                    <option value={"Receipt-Generated"}>Ascending</option>
                                    <option value={"Stock-Taken"}>Decending</option>

                                </Form.Select>
                            </Form.Group>
                        )}
                        {secondFilter === 2 && (
                            <Form.Group controlId='subCategorytypeNew'>
                                <Form.Select
                                    name='subCategorytypeNew'
                                    className={`form-select form-select-solid form-select-lg fw-bold`}
                                >
                                    <option value={"PO-Generated"}>Sort by</option>
                                    <option value={"Receipt-Generated"}>Ascending</option>
                                    <option value={"Stock-Taken"}>Decending</option>
                                </Form.Select>
                            </Form.Group>
                        )}
                        {secondFilter === 3 && (
                            <Form.Group controlId='subCategorytypeNew'>
                                <Form.Select
                                    name='subCategorytypeNew'
                                    className={`form-select form-select-solid form-select-lg fw-bold`}
                                >
                                    <option value={"PO-Generated"}>PO-Generated</option>
                                    <option value={"Receipt-Generated"}>Receipt-Generated</option>
                                    <option value={"Stock-Taken"}>Stock-Taken</option>

                                </Form.Select>
                            </Form.Group>
                        )}
                    </div>
                </div> */}
            {/*Search And Filter*/}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                        data-toggle="tooltip" data-placement="bottom" title="Search a Product"
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search Stock In'
                        value={searchInput}
                        onChange={handleInputChange}
                    />
                </h3>
                <div
                    className='card-toolbar'
                >
                    <h5 className='p-3'>Filter by:</h5>
                    <div className='mx-3'>
                        <Flatpickr
                            ref={fromDateRef}
                            onChange={handleDateFromChange}
                            className='form-control form-control-solid'
                            placeholder='From Date'
                            options={{
                                dateFormat: 'd/m/Y',
                            }}
                        />
                    </div>
                    <div className='mx-3'>
                        <Flatpickr
                            ref={toDateRef}
                            onChange={handleDateToChange}
                            className='form-control form-control-solid'
                            placeholder='From Date'
                            options={{
                                dateFormat: 'd/m/Y',
                            }}
                        />
                    </div>
                    <div className='mx-3'>
                        <button
                            className='btn btn-light-danger '
                            onClick={() => {
                                if (fromDateRef.current) {
                                    fromDateRef.current.flatpickr.clear();
                                }
                                if (toDateRef.current) {
                                    toDateRef.current.flatpickr.clear();
                                }
                                setFromDateFilter('');
                                setToDateFilter('');
                            }}
                        >
                            Clear Dates
                        </button>
                    </div>
                </div>
            </div>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                </h3>
                <div
                    className='card-toolbar'
                >
                    <h5 className='p-3'>Filter by:</h5>
                    <div className='mx-3'>
                        <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            value={selectedDepartment}
                            onChange={(e) => setSelectedDepartment(e.target.value)}
                        >
                            <option value=''>Filter by Department...</option>
                            {Array.isArray(showAllDepartment) && showAllDepartment.length > 0 ? (
                                showAllDepartment.map((department) => (
                                    <option key={department.id} value={department.id}>
                                        {department.name}
                                    </option>
                                ))
                            ) : (
                                <option value='' disabled>No Departments Available</option>
                            )}
                        </select>
                    </div>
                    <div className='mx-3'>
                        <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                        >
                            <option value=''>Filter by User...</option>
                            {Array.isArray(showAllUser) && showAllUser.length > 0 ? (
                                showAllUser.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.full_name}
                                    </option>
                                ))
                            ) : (
                                <option value='' disabled>No Users Available</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
            <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
                <div>

                    <button
                        className='btn btn-primary me-3'
                        onClick={handleApplyFilters}
                    >
                        Apply Filters
                    </button>
                    <button
                        className='btn btn-light-danger'
                        onClick={handleClearFilters}
                    >
                        Clear Filters
                    </button>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* Headings of columns in table  */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-150px'>Department <span onClick={() => handleSort('department_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px'>User <span onClick={() => handleSort('user_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px'>Date <span onClick={() => handleSort('date_time')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-100px text-center'>Updated By <span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-100px'>Updated At <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                {stock_out_delete || stock_out_edit || stock_out_logs || stock_out_view ? (
                                    <th className='min-w-100px text-center'>Actions</th>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            {isStockOutError ? (
                                <tr>
                                    <td>Error loading data. Please try again later.</td>
                                </tr>
                            ) : isStockOutLoading ? (
                                <tr>
                                    <td colSpan={6}>
                                        <div className='text-center'>
                                            <div className='spinner-border' role='status'>
                                                <span className='visually-hidden'>Loading...</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : stockOutList.length > 0 ? (
                                visibleData.map((stock) => (
                                    <tr key={stock.id}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                                                        {stock.department_name}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                                                        {stock.user_name}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                                                        {formatDateWithoutTime(stock.date_time)}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex flex-column w-100 me-2'>
                                                <div className='d-flex flex-stack mb-2 cursor-pointer'>
                                                    {stock.updated_by_name}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start cursor-pointer'>
                                                    {formatDateWithTime(stock.updated_at)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                {stock_out_edit && (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="Edit Stock Out"
                                                        onClick={() => handleEditStockOut(stock)}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='pencil' className='fs-3' />
                                                    </button>
                                                )}
                                                {stock_out_view && (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="See Stock Out Details"
                                                        onClick={() => handleViewStockOut(stock)}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='eye' className='fs-3' />
                                                    </button>
                                                )}
                                                {stock_out_logs && (
                                                    <button
                                                        onClick={() => {
                                                            handleStockOutLogs(stock);
                                                        }}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-placement='top'
                                                        data-bs-custom-class='tooltip-dark'
                                                        title='Stock Out Logs'
                                                    >
                                                        <i className='bi bi-journal-text'></i>
                                                    </button>
                                                )}
                                                {stock_out_delete && (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="Delete a Stock"
                                                        onClick={() => setSelectedStockOut(stock)}
                                                        className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#kt_modal_1'
                                                    >
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>Empty Stock Out</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Delete modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Delete Stock Out Confirmation</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedStockOut ? (
                                <>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Stock Out Against <b>{selectedStockOut.department_name}</b> department</span>
                                    </p>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Stock Out Against <b>{selectedStockOut.user_name}</b> of <b>{selectedStockOut.department_name}</b> department </span>
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Stock"
                                type='button'
                                onClick={() => {
                                    if (selectedStockOut && selectedStockOut.id) {
                                        handleDeleteStockOut.mutate(selectedStockOut.id)
                                    }
                                }}
                                className='btn btn-danger'
                                data-bs-dismiss='modal'
                            >
                                Delete Stock
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-footer d-flex justify-content-between py-4'>
                <div className='align-items-center row'>
                    <div className='d-flex'>
                        <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
                        <select
                            style={{ width: '60px' }}
                            className='form-select form-select-md px-2 col-md-1'
                            value={selectedItemsPerPage}
                            onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
                        >
                            {itemsPerPage.map(option => (
                                <option key={option} value={option} className='p-3 '>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    {currentPage === 1 ? (
                        <button
                            className='btn btn-light'
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    ) : (
                        <button
                            className='btn btn-primary'
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    )}
                    <button
                        className='btn btn-primary ms-2'
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
};

export default StockOutList;