import { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {get_single_vendor } from '../api/vendorsApi';
function ViewVendorLogs() {
    const [selectedVendor, setSelectedVendor] = useState<any | null>();
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const row = (location.state as {row: any}).row;
    useEffect(() => {
        const fetchData = async () => {
          try {
            // Assuming your API response structure is like: { status: true, message: "", data: {...vendorDetails} }
            const response = await get_single_vendor(row); // Pass the ID to your API function
            const vendorData = response.data;
            // Set the selected vendor in the state
            setSelectedVendor(vendorData);
          } catch (error:any) {
            if (error.response) {
              const errorMessage = error.response.data.message;
              toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 2000,
              });
            }
          } finally {
            // Set loading to false once data is fetched (whether successful or not)
            setLoading(false);
          }
        };
    
        if (row) {
          fetchData();
        }
      }, [row]);
  return (
    <div className='card mb-5 mb-xl-10'>
    <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
    >
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>View Vendor</span>
        </h3>
        <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
        >
            <button
                data-toggle="tooltip" data-placement="bottom" title="Navigate to List Vendors"
                className='btn btn-sm btn-light-dark fs-5'
                onClick={() => navigate('/vendors/vendors-list')}
            >
                List of Vendors
            </button>
        </div>
    </div>
    <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
        {loading ? (
            <div className='text-center'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : 
            selectedVendor ? (
                <>
                    {/* ... Edit Form ... */}
                    <Form>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Vendor Type:</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.supplier_type}
                                </label>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Vendor's Name</label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.supplier_name}
                                </label>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Vendor's Email</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.email}
                                </label>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Telephone</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.telephone}
                                </label>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Mobile</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.mobile_no}
                                </label>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Address Line 1</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.address_line_1}
                                </label>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Address Line 2</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.address_line_2}
                                </label>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Town</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.town}
                                </label>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>City</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.city}
                                </label>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Postcode</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <label className='form-control form-control-lg form-control-solid'>
                                    {selectedVendor.post_code}
                                </label>
                            </div>
                        </div>
                    </Form>
                </>
            ) : (
                <p>No Vendor Available.</p>
              )}
        </div>

    </div>
</div>
  )
}

export default ViewVendorLogs