export interface IProfileDetails {
  email: string
  title: string
  first_name: string
  middle_name: string
  last_name: string
  phone_number: string
  telephone: string
  picture: string | null
}
export interface IUpdatePassword {
  password: string
  new_password: string
  confirm_password: string
}
export const updatePassword: IUpdatePassword = {
  password: '',
  new_password: '',
  confirm_password: '',
}

// Getting user Data
export interface UserProfile {
  id: number
  role: string
  title: string
  first_name: string
  middle_name: string
  last_name: string
  email: string
  email_verified_at: string | null
  full_name: string
  created_at: string
  updated_at: string
  phone_number: string
  telephone: string
  picture: string
}

export interface TitleList {
  value: string
  label: string
}
