import secureLocalStorage from "react-secure-storage";

export const permissionCheck = (key) => {
    const storedPermissionsString: any = secureLocalStorage.getItem('user_permissions');
    const all_permissions: any = storedPermissionsString ? JSON.parse(storedPermissionsString) : null;

    // Check if the key is present in the permissions object
    if (all_permissions && Object.keys(all_permissions).includes(key)) {
        // Key found
        return key;
    } else {
        // Key not found
        return false; // or handle accordingly based on your use case
    }
};





