import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../app/modules/auth';
import { useMutation, useQuery } from 'react-query';
import { get_user_all_notifications, notification_status_change } from '../../../../app/modules/auth/core/_requests';
import { KTIcon } from '../../../helpers';
import clsx from 'clsx'
import { TimeAgo } from '../../../../app/helper-functions/TimeAgo';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type Notifications = {
    id: number;
    notification_title: string | undefined;
    notification_description: any;
    notification_type: string | undefined;
    is_read: number;
    updated_by: string | undefined;
    created_at: string;
};
function AllNotifications() {
    const [allNotificationsList, setAllNotificationsList] = useState<Notifications[]>([]);
    const { auth } = useAuth()
    const navigate = useNavigate();

    const { data: allNotificationsData } = useQuery(['allNotifications', auth?.id], () => get_user_all_notifications(auth?.id), {refetchOnWindowFocus: false});
    useEffect(() => {
        if (allNotificationsData) {
            setAllNotificationsList(allNotificationsData.data as Notifications[]);
        };
    }, [allNotificationsData]);

    const handleStatusChange = useMutation((id: number,) => {
        return notification_status_change(id, auth?.id).then((response) => {
        })
            .then(() => {
                return
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                }
            });
    });

    const parseDescription = (description) => {
        try {
            const parsedDescription = JSON.parse(description);
            return parsedDescription.description;
        } catch (error) {
            return
        }
    };

    const handleViewRequisition = (description: any) => {
        const parsedDescription = JSON.parse(description);
        navigate("/requisitions/view-requisition", { state: { id: parsedDescription.requisition_id } });
    }
    const handleViewQuotation = (description: any) => {
        const parsedDescription = JSON.parse(description);
        navigate("/quotations/view-quotation", { state: { id: parsedDescription.quotation_id } });
    };
    const handleViewStockOut = (description: any) => {
        const parsedDescription = JSON.parse(description);
        navigate("/stocks/view-stock-out", { state: { id: parsedDescription.stockout_id } });
    }
    const handleViewStockIn = (description: any) => {
        const parsedDescription = JSON.parse(description);
        navigate("/stocks/view-stock-out", { state: { id: parsedDescription.stockout_id } });
    }
    return (
        <>
            <h3 className='px-8'>Notifications</h3>
            <div className='scroll-y mh-325px my-5 px-8'>
                {allNotificationsList.map((notification, index) => (
                    <div key={`notification${index}`} >
                        {notification.is_read === 1 && (
                            <div className='d-flex flex-stack py-4 border-bottom rounded'>
                                <div className='d-flex align-items-center'>
                                    <div className='symbol symbol-35px me-4'>
                                        {notification.notification_type === 'Requisition' && (
                                            <span className={clsx('symbol-label', `bg-light-primary`)}>
                                                {' '}
                                                <KTIcon iconName="abstract-25" className={`fs-2 text-primary`} />
                                            </span>
                                        )}
                                        {notification.notification_type === 'Quotation' && (
                                            <span className={clsx('symbol-label', `bg-light-warning`)}>
                                                {' '}
                                                <KTIcon iconName="abstract-28" className={`fs-2 text-warning`} />
                                            </span>
                                        )}
                                        {notification.notification_type === 'Stockout' && (
                                            <span className={clsx('symbol-label', `bg-light-danger`)}>
                                                {' '}
                                                <KTIcon iconName="package" className={`fs-2 text-danger`} />
                                            </span>
                                        )}
                                    </div>
                                    <div className='mb-0 me-2'>
                                        {notification.notification_type === 'Requisition' && (
                                            <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                                                onClick={() => handleViewRequisition(notification.notification_description) }>
                                                {notification.notification_title}
                                            </span>
                                        )}
                                        {notification.notification_type === 'Quotation' && (
                                            <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                                                onClick={() => handleViewQuotation(notification.notification_description)}>
                                                {notification.notification_title}
                                            </span>
                                        )}
                                        {notification.notification_type === 'Stockout' && (
                                            <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                                                onClick={() => handleViewStockOut(notification.notification_description)}>
                                                {notification.notification_title}
                                            </span>
                                        )}
                                        {notification.notification_type === 'Stockin' && (
                                            <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                                                onClick={() =>handleViewStockIn(notification.notification_description)}>
                                                {notification.notification_title}
                                            </span>
                                        )}
                                        <div className='text-gray-400 fs-7'>{parseDescription(notification.notification_description)}</div>
                                    </div>
                                </div>
                                <span className='badge badge-light fs-8'>{TimeAgo(notification.created_at)}</span>
                            </div>
                        )}
                        {notification.is_read === 0 && (
                            <>
                                {notification.notification_type === 'Requisition' && (
                                    <div className='border-bottom rounded d-flex flex-stack py-2 px-2 bg-light-primary'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-35px me-4'>
                                                <span className={clsx('symbol-label', `bg-light-primary`)}>
                                                    {' '}
                                                    <KTIcon iconName="abstract-25" className={`fs-2 fw-bolder text-primary`} />
                                                </span>

                                            </div>
                                            <div className='mb-0 me-2'>
                                                <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                                                    onClick={() => {
                                                        handleViewRequisition(notification.notification_description)
                                                        if (notification && notification.id) {
                                                            handleStatusChange.mutate(notification.id);
                                                        }
                                                    }
                                                    }>
                                                    {notification.notification_title}
                                                </span>
                                                <div className='text-gray-400 fs-7'> {parseDescription(notification.notification_description)} </div>
                                            </div>
                                        </div>
                                        <span className='badge badge-primary fs-8'>{TimeAgo(notification.created_at)}</span>
                                    </div>
                                )}
                                {notification.notification_type === 'Quotation' && (
                                    <div className='border-bottom rounded d-flex flex-stack py-2 px-2 bg-light-warning'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-35px me-4'>
                                                <span className={clsx('symbol-label', `bg-light-warning`)}>
                                                    {' '}
                                                    <KTIcon iconName="abstract-28" className={`fs-2 fw-bolder text-warning`} />
                                                </span>

                                            </div>
                                            <div className='mb-0 me-2'>
                                                <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                                                    onClick={() => {
                                                        handleViewQuotation(notification.notification_description)
                                                        if (notification && notification.id) {
                                                            handleStatusChange.mutate(notification.id);
                                                        }
                                                    }
                                                    }>
                                                    {notification.notification_title}
                                                </span>
                                                <div className='text-gray-400 fs-7'> {parseDescription(notification.notification_description)}</div>
                                            </div>
                                        </div>
                                        <span className='badge badge-warning fs-8'>{TimeAgo(notification.created_at)}</span>
                                    </div>
                                )}
                                {notification.notification_type === 'Stockout' && (
                                    <div className='border-bottom rounded d-flex flex-stack py-2 px-2 bg-light-info'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-35px me-4'>
                                                <span className={clsx('symbol-label', `bg-light-info`)}>
                                                    {' '}
                                                    <KTIcon iconName="package" className={`fs-2 fw-bolder text-info`} />
                                                </span>

                                            </div>
                                            <div className='mb-0 me-2'>
                                                <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                                                    onClick={() => {
                                                        handleViewStockOut(notification.notification_description)
                                                        if (notification && notification.id) {
                                                            handleStatusChange.mutate(notification.id);
                                                        }
                                                    }
                                                    }>
                                                    {notification.notification_title}
                                                </span>
                                                <div className='text-gray-400 fs-7'> {parseDescription(notification.notification_description)}</div>
                                            </div>
                                        </div>
                                        <span className='badge badge-info fs-8'>{TimeAgo(notification.created_at)}</span>
                                    </div>
                                )}
                                {notification.notification_type === 'Stockin' && (
                                    <div className='border-bottom rounded d-flex flex-stack py-2 px-2 bg-light-info'>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-35px me-4'>
                                                <span className={clsx('symbol-label', `bg-light-info`)}>
                                                    {' '}
                                                    <KTIcon iconName="package" className={`fs-2 fw-bolder text-info`} />
                                                </span>
                                            </div>
                                            <div className='mb-0 me-2'>
                                                <span className='fs-6 text-gray-800 text-hover-primary fw-bolder cursor-pointer'
                                                    onClick={() => {
                                                        handleViewStockIn(notification.notification_description)
                                                        if (notification && notification.id) {
                                                            handleStatusChange.mutate(notification.id);
                                                        }
                                                    }
                                                    }>
                                                    {notification.notification_title}
                                                </span>
                                                <div className='text-gray-400 fs-7'> {parseDescription(notification.notification_description)}</div>
                                            </div>
                                        </div>
                                        <span className='badge badge-info fs-8'>{TimeAgo(notification.created_at)}</span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
        </>
    )
}

export default AllNotifications