import { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon } from '../../../../_metronic/helpers';
import { KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { formatDateWithTime } from '../../../helper-functions/DateFunction';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
import { change_active_status_vendor, delete_vendor, get_all_filters_status_vendor, get_all_vendors } from '../api/vendorsApi';
type Vendor = {
  id: number;
  supplier_type: string | undefined;
  supplier_name: string | undefined;
  email: string | undefined;
  mobile_no: string | undefined;
  telephone: string | undefined;
  address_line_1: string | undefined;
  address_line_2: string | undefined;
  town: string | undefined;
  city: string | undefined;
  post_code: string | undefined;
  status: number | undefined;
  updated_by_name: string | undefined;
  created_at: string | undefined;
  updated_at: string;
};

var vendor_add, vendor_edit, vendor_view, vendor_logs, vendor_delete
function VendorsList() {
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const navigate = useNavigate();
  const [visibleData, setVisibleData] = useState<Vendor[]>([]);
  vendor_add = permissionCheck('supplier-create')
  vendor_edit = permissionCheck('supplier-update')
  vendor_view = permissionCheck('supplier-view')
  vendor_logs = permissionCheck('supplier-activity-log')
  vendor_delete = permissionCheck('supplier-delete')
  const { data, isLoading, isError } = useQuery('vendors', get_all_vendors, {refetchOnWindowFocus: false});
  useEffect(() => {
    if(data){
      setVendorList(data.data)
    }
  }, [data])
  useEffect(() => {
    const fetchData = async () => {
      try {
        let filteredData;
        if (selectedStatus) {
          filteredData = await get_all_filters_status_vendor(selectedStatus);
          setVendorList(filteredData.data);
        }
        else {          
        setVendorList(data.data);
        }
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      }
    };
    fetchData();
  }, [selectedStatus]);
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems = vendorList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  useEffect(() => {
    if (vendorList.length > 0) {
      let sortedData = [...vendorList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          if (sortBy && sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else {
            const aValue = (a[sortBy?.key] || '') as string;
            const bValue = (b[sortBy?.key] || '') as string;
            return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage, // Update slice based on selected items per page
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [vendorList, currentPage, selectedItemsPerPage, sortBy]);
  const handleDeleteVendor = useMutation((id: number) => {
    return delete_vendor(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        return get_all_vendors().then((data) => {
          setVendorList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearchInput(searchTerm); // Update the search input state
    // Filter the vendorList based on the search input
    const filteredItems = data.data.filter((vendor) => {
      return (
        vendor.supplier_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vendor.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        vendor.mobile_no?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setVendorList(filteredItems);
  };
  const handleVendorLogs = (vendor: any) => {
    navigate("/vendors/vendor-logs", { state: { id: vendor.id } });
  };
  const handleVendorEdit = (vendor: any) => {
    navigate("/vendors/edit-vendor", { state: { vendor: vendor } });
  };
  const handleVendorView = (vendor: any) => {
    navigate("/vendors/view-vendor", { state: { vendor: vendor } });
  };
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_vendor(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        return get_all_vendors().then((data) => {
          setVendorList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    } else if (key === "status") {
      // Toggle between ascending and descending order
      setSortBy({ key, ascending: !sortBy.ascending });
     }  else if (key === "updated_at") {
      // Toggle between ascending and descending order
      setSortBy({ key, ascending: !sortBy.ascending });
     }else {
      setSortBy(null); // Revert to unsorted state
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10 ' style={{ position: 'relative' }}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>List of Vendors</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >{vendor_add && (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Navigate to Add vendor"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/vendors/add-vendor')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add Vendor
            </button>
          )}

          </div>
        </div>
        {/* Search And Filter */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              data-toggle="tooltip" data-placement="bottom" title="Search a Vendor"
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Vendor'
              value={searchInput}
              onChange={handleInputChange}
            />
          </h3>
          <div
            className='card-toolbar'
          >
            <h5 className='p-3'>Filter by:</h5>
            <div>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value=''>Filter by Status...</option>
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </select>
            </div>
          </div>
        </div>
        <div className='card-body py-3 '>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 '>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px' >Vendor Name<span onClick={() => handleSort('supplier_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  <th className='min-w-140px'>Mobile No</th>
                  <th className='min-w-120px'>Email</th>
                  <th className='min-w-140px'>Status<span onClick={() => handleSort('status')}><KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  <th className='min-w-200px' >Updated By<span onClick={() => handleSort('updated_by_name')}><KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  <th className='min-w-200px'>Updated At<span onClick={() => handleSort('updated_at')}><KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  {vendor_delete || vendor_edit || vendor_logs || vendor_view ? (
                    <th className='min-w-100px text-center'>Actions</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {isError ? (
                  <tr>
                    <td>Error loading data. Please try again later.</td>
                  </tr>
                ) : isLoading ? (
                  <tr>
                    <td colSpan={6}>
                      <div className='text-center'>
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : vendorList.length > 0 ? (
                  visibleData.map((vendor) => (
                    <tr key={vendor.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                              {vendor.supplier_name}
                            </h3>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                              {vendor.mobile_no}
                            </h3>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                              {vendor.email}
                            </h3>
                          </div>
                        </div>
                      </td>
                      <td>{vendor.status === 1 ? (
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                              Active
                            </h3>
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>
                              Inactive
                            </h3>
                          </div>
                        </div>
                      )}
                      </td>
                      <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2 cursor-pointer'>
                            {vendor.updated_by_name}
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2 cursor-pointer'>
                            {formatDateWithTime(vendor.updated_at)}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          {vendor_edit && (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Edit Vendor"
                              onClick={() => handleVendorEdit(vendor)}
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </button>
                          )}
                          {vendor_view && (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="See Vendor Details"
                              onClick={() => handleVendorView(vendor)}
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            >
                              <KTIcon iconName='eye' className='fs-3' />
                            </button>
                          )}
                          {vendor_add || vendor_edit ? (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Switch Status"
                              onClick={() => handleStatusUpdate.mutate(vendor.id)}
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            >
                              <KTIcon iconName='update-folder' className='fs-3' />
                            </button>
                          ) : null}
                          {vendor_logs && (
                            <button
                              onClick={() => {
                                handleVendorLogs(vendor);
                              }}
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-custom-class='tooltip-dark'
                              title='Vendor Logs'
                            >
                              <i className='bi bi-journal-text'></i>
                            </button>
                          )}
                          {vendor_delete && (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Delete Vendor"
                              onClick={() => setSelectedVendor(vendor)}
                              className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_1'
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No Vendors available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-between py-4'>
          <div className='align-items-center row'>
            <div className='d-flex'>
              <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
              <select
                style={{ width: '60px' }}
                className='form-select form-select-md px-2 col-md-1'
                value={selectedItemsPerPage}
                onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
              >
                {itemsPerPageOptions.map(option => (
                  <option key={option} value={option} className='p-3 '>{option}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            {currentPage === 1 ? (
              <button
                className='btn btn-light'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            ) : (
              <button
                className='btn btn-primary'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            )}
            <button
              className='btn btn-primary ms-2'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {/* Delete Modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete Vendor Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedVendor ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold'>Vendor Name:</span> {selectedVendor.supplier_name}
                  </p>
                  <p className='mb-2'>
                    <span className='fw-bold'>Mobile No:</span> {selectedVendor.mobile_no}
                  </p>
                  <p className='mb-2'>
                    <span className='fw-bold'>Email:</span> {selectedVendor.email}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button data-toggle="tooltip" data-placement="bottom" title="close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Delete this Vendor"
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  if (selectedVendor) {
                    handleDeleteVendor.mutate(selectedVendor.id)
                  }
                }}
                data-bs-dismiss='modal'
              >
                Delete Vendor
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VendorsList;