import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { KTIcon } from '../../../../../_metronic/helpers'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Alert } from 'react-bootstrap'
import { delete_requisition_type, get_all_requisition_type, update_requisition_type } from '../api/requisitionsTypeApi'
import { toast } from 'react-toastify';
import { formatDateWithTime } from '../../../../helper-functions/DateFunction'
import { permissionCheck } from '../../../../helper-functions/PermissionCheck'
type RequisitionType = {
  id: number;
  title: string | undefined;
  updated_by: string | undefined;
  updated_by_name: string | undefined;
  updated_at: string;
}
var requisition_type_add, requisition_type_edit, requisition_type_logs, requisition_type_delete

function RequisitionTypeList() {
  const [searchInput, setSearchInput] = useState('')
  const [requisitionTypeList, setRequisitionTypeList] = useState<RequisitionType[]>([])
  const [nameValidationError, setNameValidationError] = useState<string | null>(null)
  const [selectedRequisitionType, setSelectedRequisitionType] = useState<RequisitionType | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null);
  const [errorMessage, setErrorMessage] = useState(null)
  const [show, setShow] = useState(false)
  const [visibleData, setVisibleData] = useState<RequisitionType[]>([]);
  const navigate = useNavigate()
  requisition_type_add = permissionCheck('requisitionType-create')
  requisition_type_edit = permissionCheck('requisitionType-update')
  requisition_type_logs = permissionCheck('requisitionType-activity-log')
  requisition_type_delete = permissionCheck('requisitionType-delete')
  const { data, isLoading, isError } = useQuery('requisitionType', get_all_requisition_type, {refetchOnWindowFocus: false})
  useEffect(() => {
    if (data) {
      setRequisitionTypeList(data.data)
    }
  }, [data])
  const itemsPerPage = [10, 20, 30, 50, 100];
  const totalItems = requisitionTypeList.length
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage)

  useEffect(() => {
    if (requisitionTypeList.length > 0) {
      let sortedData = [...requisitionTypeList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          const aValue = (a[sortBy?.key] || '') as string;
          const bValue = (b[sortBy?.key] || '') as string;
          return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage, // Update slice based on selected items per page
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [requisitionTypeList, currentPage, selectedItemsPerPage, sortBy]);
  const handleUpdateRequisitionType = useMutation(
    (updatedRequisitionType: { id: number; title: string }) => {
      if (updatedRequisitionType.title.trim() === "") {

        return Promise.reject(new Error("Category name is required."));
      }
      return update_requisition_type(updatedRequisitionType.id, updatedRequisitionType.title).then((response) => {
        toast.success("The requisition type has been edited successfully", {
          position: "top-right",
          autoClose: 3000,
        });
      })

    },
    {
      onSuccess: () => {
        get_all_requisition_type().then((data) => {
          setRequisitionTypeList(data.data);

        });
      },
    }
  );

  const handleDeleteRequisitionType = useMutation((id: number) => {
    return delete_requisition_type(id)
      .then((response) => {
        toast.success("The requisition type has been deleted successfully", {
          position: "top-right",
          autoClose: 3000,
        });
      })
      .then(() => {
        return get_all_requisition_type().then((data) => {
          setRequisitionTypeList(data.data);
        })
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value
    setSearchInput(searchTerm)
    const filteredItems = data.data.filter((requisitionType) => {
      return (
        requisitionType.title?.toLowerCase().includes(searchTerm)
      )
    })
    setRequisitionTypeList(filteredItems)
  }
  const resetValidationErrors = () => {
    setNameValidationError("");
  }
  const handleSort = (key: string) => {
    if (sortBy && sortBy.key === key) {
      setSortBy({ key, ascending: !sortBy.ascending });
    } else if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false });
    } else {
      setSortBy(null);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleRequisitionTypeLogs = (requisitionType: any) => {
    navigate("/requisition-type/requisition-type-logs", { state: { id: requisitionType.id } });
  }
  return (
    <>
      {errorMessage && show && (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          {errorMessage}
        </Alert>
      )}
      <div className="card card-xxl-stretch mx-4 mb-5 mb-xl-10">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">List of Requisition Types</span>
          </h3>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >{requisition_type_add && (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Navigate to Add Requisition Type"
              className="btn btn-sm btn-light-dark"
              onClick={() => navigate("/requisition-type/add-requisition-type")}
            >
              <KTIcon iconName="plus" className="fs-3" />
              Add Requisition Type
            </button>
          )}
          </div>
        </div>
        <div className="card-body py-3">
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                data-toggle="tooltip" data-placement="bottom" title="Search a Requisition Type"
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Requisition Type'
                value={searchInput}
                onChange={handleInputChange}
              />
            </div>
            {/* end::Search */}
          </div>
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted">
                  <th className='min-w-200px' >Requisition Type <span onClick={() => handleSort('title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  <th className="min-w-140px">Updated By <span onClick={() => handleSort('updated_by_name')}><KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  <th className="min-w-120px">Updated At <span onClick={() => handleSort('updated_at')}><KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  {requisition_type_add || requisition_type_delete || requisition_type_edit || requisition_type_logs ? (
                    <th className="min-w-100px text-center">Actions</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {isError ? (
                  <tr>
                    <td>Error loading data. Please try again later.</td>
                  </tr>
                ) : isLoading ? (
                  <tr>
                    <td colSpan={6}>
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : requisitionTypeList.length > 0 ? (
                  visibleData.map((requisitiontype) => (
                    <tr key={requisitiontype.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column">
                            <h3 className="text-dark fw-bold cursor-pointer text-hover-success fs-6">
                              {requisitiontype.title}
                            </h3>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column w-100 me-2">
                          <div className="d-flex flex-stack mb-2 cursor-pointer">
                            {requisitiontype.updated_by_name}
                          </div>
                        </div>
                      </td>
                      <td className="text-end">
                        <div className="d-flex flex-column w-100 me-2">
                          <div className="d-flex flex-stack mb-2 cursor-pointer">
                            {formatDateWithTime(requisitiontype.updated_at)}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end flex-shrink-0">
                          {requisition_type_edit && (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Edit Requisition Type"
                              onClick={() => {
                                setSelectedRequisitionType(requisitiontype);
                                resetValidationErrors();
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_2"
                              className="btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1"
                            >
                              <KTIcon iconName="pencil" className="fs-3" />
                            </button>
                          )}
                          {requisition_type_logs && (
                            <button
                              onClick={() => {
                                handleRequisitionTypeLogs(requisitiontype);
                              }}
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-custom-class='tooltip-dark'
                              title='Requisition Type Logs'
                            >
                              <i className='bi bi-journal-text'></i>
                            </button>
                          )}
                          {requisition_type_delete && (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Delete Requisition Type"
                              className="btn btn-icon btn-light-dark btn-active-color-danger btn-sm"
                              onClick={() => setSelectedRequisitionType({ ...requisitiontype })}
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_1"
                            >
                              <KTIcon iconName="trash" className="fs-3" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No RequisitionType available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-between py-4'>
          <div className='align-items-center row'>
            <div className='d-flex'>
              <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
              <select
                style={{ width: '60px' }}
                className='form-select form-select-md px-2 col-md-1'
                value={selectedItemsPerPage}
                onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
              >
                {itemsPerPage.map(option => (
                  <option key={option} value={option} className='p-3 '>{option}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            {currentPage === 1 ? (
              <button
                className='btn btn-light'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            ) : (
              <button
                className='btn btn-primary'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            )}
            <button
              className='btn btn-primary ms-2'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <div className="modal fade" tabIndex={-1} id="kt_modal_1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Requisition Type Confirmation</h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </div>
              </div>
              <div className="modal-body">
                {selectedRequisitionType ? (
                  <>
                    <p className="mb-2">
                      <span className="fw-bold">Requisition Type Name:</span> {selectedRequisitionType.title}
                    </p>
                  </>
                ) : null}
              </div>
              <div className="modal-footer">
                <button data-toggle="tooltip" data-placement="bottom" title="close" type="button" className="btn btn-light" data-bs-dismiss="modal">
                  Close
                </button>
                <button
                  data-toggle="tooltip" data-placement="bottom" title="Delete this Requisition Type"
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    if (selectedRequisitionType) {
                      handleDeleteRequisitionType.mutate(selectedRequisitionType.id);
                    }
                  }}
                  data-bs-dismiss="modal"
                >
                  Delete Requisition Type
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit modal */}
      <div className="modal fade" tabIndex={-1} id="kt_modal_2">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Requisition Type</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              {selectedRequisitionType ? (
                <>
                  <div className='mb-2 row mb-6'>
                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>
                      Requisition Type:
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        value={selectedRequisitionType.title}
                        className='form-control form-control-lg form-control-solid'
                        onChange={(e) => {
                          if (!e.target.value) {
                            setNameValidationError(
                              'Requisition Type is required.'
                            )
                          } else {
                            setNameValidationError('')
                          }
                          setSelectedRequisitionType({
                            ...selectedRequisitionType,
                            title: e.target.value,
                          })
                        }}
                        type='text'
                      />
                      {nameValidationError && (
                        <div className='text-danger'>{nameValidationError}</div>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="modal-footer">
              <button data-toggle="tooltip" data-placement="bottom" title="close" type="button" className="btn btn-light" data-bs-dismiss="modal">
                Close
              </button>
              {selectedRequisitionType?.title === '' ? (
                <>
                  <button
                    data-toggle="tooltip" data-placement="bottom" title="Update Requisition Type"
                    type='button'
                    className='btn btn-light-dark'
                    disabled={true}
                  >
                    Update Requisition Type
                  </button>
                </>
              ) : (
                <>
                  <button
                    data-toggle="tooltip" data-placement="bottom" title="Update Requisition Type"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      if (selectedRequisitionType && selectedRequisitionType.title) {
                        handleUpdateRequisitionType.mutateAsync({
                          id: selectedRequisitionType.id,
                          title: selectedRequisitionType.title,
                        })
                      }
                    }}
                    data-bs-dismiss='modal'

                  >
                    Update Requisition Type
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default RequisitionTypeList
