import axios from "axios";
import { config_multipart } from "../../../api-helper/api-header-config";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

// List Of Type Requisition
export const get_all_requisitions = async () => {
    let response = await axios.get(baseURL + "/api/requisition");
    return response.data;
}

// Delete Requisition Type
export const delete_requisition = async (id: number) => {
    let response = await axios.delete(baseURL + "/api/requisition/" + id);
    return response.data;
}

//signle requisition
export const get_single_requisition = async (id: number) => {
    let response = await axios.get(baseURL + "/api/requisition/" + id);
    return response.data;
}
// Add New Requisition
export const add_requisition = async (values: object) => {
    let response = await axios.post(baseURL + "/api/requisition", values,{
        headers: {
            'Content-Type': 'multipart/form-data',
          }
    });
    return response.data;  
}
export const update_requisition = async (updatedRequisition) => {
    let id: number;
    id = updatedRequisition.id
    let response = await axios.patch(baseURL + "/api/requisition/" + id, updatedRequisition);
    return response.data;
}

// Get Single Requisitions Logs by ID
export const get_requisition_logs = async (id: number) => {
    let response = await axios.get(baseURL + "/api/requisition-actity-log/" + id);
    return response.data;
};
// Get All Requisitions Logs
export const get_all_requisitions_logs = async () => {
    let response = await axios.get(baseURL + "/api/requisition-activity-logs");
    return response.data;
};

// Get All Requisitions Logs
export const get_requisitions_status = async (id: number | null, flag: string) => {
    let response = await axios.get(baseURL + "/api/requisition-status/" + id + "/" + flag);
    return response.data;
};

//get all accepted requisition
export const all_accepted_requisitions = async () => {
    let response = await axios.get(baseURL + "/api/accepted-requisitions")
    return response.data;
}

export const all_accepted_requisitions_products = async (id: number) => {
    let response = await axios.get(baseURL + "/api/all-requisition-products-against-requisition/" + id)
    return response.data;
}

export const all_requsition_assignees = async () => {
    let response = await axios.get(baseURL + "/api/requisition-assignees")
    return response.data;
}

// List Of Requisition Document
export const get_all_requisition_documents = async (id: number) => {
    let response = await axios.get(baseURL + "/api/get-requisition-documents/" + id);
    return response.data;
}
export const add_requisitoin_document = async (values: object) => {
    const response = await axios.post(baseURL + "/api/requisition-document", values, config_multipart);
    return response.data;
};
// Delete Requisition document
export const delete_requisition_document = async (id: number) => {
    let response = await axios.delete(baseURL + "/api/delete-requisition-document/" + id);
    return response.data;
}
export const update_requisition_document = async (updatedDocument) => {
    let id: number;
    id = updatedDocument.id
    let response = await axios.post(baseURL + "/api/update-requisition-document/" + id, updatedDocument, config_multipart);
    return response.data;
}
export const get_filtered_requisitions = async (requisitionTypeid, fromDate, toDate) => {
    const response = await axios.post(baseURL + '/api/get-requisition-by-filter', {
        date_flag: fromDate ? "specific" : "",
        from_date: fromDate,
        to_date: toDate,
        requisition_type_flag: requisitionTypeid ? "specific" : "",
        requisition_type_id: requisitionTypeid
    });
    return response.data;
};