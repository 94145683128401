import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon } from '../../../../_metronic/helpers';
import { KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { formatDate, formatDateFlatpickr, formatDateWithTime, formatDateWithoutTime } from '../../../helper-functions/DateFunction';
import { delete_purchase_order, get_all_filters_item, get_all_purchase_orders, purchase_order_status } from '../api/purchasesApi';
import { add_stock_in_against_po } from '../../stocks/api/stocksApi';
import Flatpickr from 'react-flatpickr';
import { get_all_vendors } from '../../vendors/api/vendorsApi';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';
type PurchaseOrder = {
    id: number;
    PO_date: string;
    vendor_id: number | undefined;
    vendor_name: number | undefined;
    status: string;
    grand_total: number | undefined;
    updated_by_name: string | undefined;
    updated_by: string | undefined;
    updated_at: string;
};
interface VendorType {
    id: number;
    supplier_name: string | undefined;
};
var purchaseorder_add, purchaseorder_edit, purchaseorder_view, purchaseorder_logs, purchaseorder_delete
function PurchasesList() {
    const [purchaseList, setPurchaseList] = useState<PurchaseOrder[]>([]);
    const [fromDateFilter, setFromDateFilter] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [showAllVendor, setShowAllVendor] = useState<VendorType[]>([]);
    const [toDateFilter, setToDateFilter] = useState('');
    const [startingPricefilter, setStartingPriceFilter] = useState('');
    const [endingPriceFilter, setEndingPriceFilter] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleData, setVisibleData] = useState<PurchaseOrder[]>([]);
    const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
    const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
    const [selectedPurchaseOrder, setSelectedpurchaseOrder] = useState<PurchaseOrder | null>();
    purchaseorder_add = permissionCheck('purchaseOrder-create')
    purchaseorder_edit = permissionCheck('purchaseOrder-update')
    purchaseorder_view = permissionCheck('purchaseOrder-view')
    purchaseorder_logs = permissionCheck('purchaseOrder-activity-log')
    purchaseorder_delete = permissionCheck('purchaseOrder-delete')
    const { data: AllVendors } = useQuery('allVendors', get_all_vendors, { refetchOnWindowFocus: false });
    useEffect(() => {
        if (AllVendors && Array.isArray(AllVendors.data)) {
            setShowAllVendor(AllVendors.data as VendorType[]);
        }
    }, [AllVendors]);
    const { data: purchasesData, isLoading: isPurchasesLoading, isError: isPurchasesError, refetch: purchaseRefetch } = useQuery('purchases', get_all_purchase_orders, { refetchOnWindowFocus: false });
    const navigate = useNavigate();
    useEffect(() => {
        if (purchasesData) {
            setPurchaseList(purchasesData.data)
        }
    }, [purchasesData]);
    const { data: filterData, refetch: filterRefetch } = useQuery(
        'filterData',
        () => get_all_filters_item(fromDateFilter, toDateFilter, startingPricefilter, endingPriceFilter, selectedVendor, selectedStatus),
        {
            onSuccess: (filteredData) => {
                setPurchaseList(filteredData.data);
            },
            onError: (error: any) => {
                // Optionally, you can display a toast or any other error handling mechanism
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                };
            },
            enabled: false,
            refetchOnWindowFocus: false,
        }
    );
    const handleApplyFilters = () => {
        // Manually refetch data when applying filters
        filterRefetch();
    };
    const handleClearFilters = () => {
        if (fromDateRef.current) {
            fromDateRef.current.flatpickr.clear();
        }
        if (toDateRef.current) {
            toDateRef.current.flatpickr.clear();
        }
        setFromDateFilter('');
        setToDateFilter('');
        fromDateRef.current?.flatpickr.clear(); // Clear the selected dates in the from date Flatpickr
        toDateRef.current?.flatpickr.clear(); // Clear the selected dates in the to date Flatpickr
        setSelectedVendor('');
        setStartingPriceFilter("");
        setEndingPriceFilter("");
        setSelectedStatus("");
        if (purchasesData) {
            setPurchaseList(purchasesData.data);
        }
    }
    const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
    const totalItems = purchaseList.length;
    const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
    useEffect(() => {
        if (purchaseList.length > 0) {
            let sortedData = [...purchaseList];
            if (sortBy) {
                sortedData.sort((a, b) => {
                    if (sortBy && sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
                        return sortBy.ascending ? a.status - b.status : b.status - a.status;
                    } else {
                        const aValue = (a[sortBy?.key] || '') as string;
                        const bValue = (b[sortBy?.key] || '') as string;
                        return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                    }
                });
            }
            setVisibleData(
                sortedData.slice(
                    (currentPage - 1) * selectedItemsPerPage, // Update slice based on selected items per page
                    currentPage * selectedItemsPerPage
                )
            );
        }
    }, [purchaseList, currentPage, selectedItemsPerPage, sortBy]);
    const fromDateRef = useRef<Flatpickr>(null); // Ref for the from date Flatpickr instance
    const toDateRef = useRef<Flatpickr>(null); // Ref for the to date Flatpickr instance
    const handleDeletePurchaseOrder = useMutation((id: number) => {
        return delete_purchase_order(id)
            .then((response) => {
                toast.success(response.message, {
                    position: 'top-right',
                    autoClose: 3000,
                });
            })
            .then(() => {
                return get_all_purchase_orders().then((data) => {
                    setPurchaseList(data.data);
                });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                };
            });
    });
    const handleStatusRecieptPurchaseOrder = useMutation((id: number) => {
        return purchase_order_status(id, "Receipt-Generated")
            .then((response) => {
                toast.success(response.message, {
                    position: 'top-right',
                    autoClose: 3000,
                });
            })
            .then(() => {
                return get_all_purchase_orders().then((data) => {
                    setPurchaseList(data.data);
                });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                };
            });
    });
    const handleStatusStockPurchaseOrder = useMutation((id: number) => {
        return purchase_order_status(id, "Stock-Taken")
            .then((response) => {
                toast.success(response.message, {
                    position: 'top-right',
                    autoClose: 3000,
                });
            })
            .then(() => {
                return get_all_purchase_orders().then((data) => {
                    setPurchaseList(data.data);
                });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                };
            });
    });
    const handleDateFromChange = (date) => {
        if (date && date[0]) {
            const selectedDate = new Date(date[0]);
            selectedDate.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(selectedDate);
            setFromDateFilter(formattedDate);

            // Update the "To Date" Flatpickr options
            if (toDateRef.current) {
                toDateRef.current.flatpickr.set('minDate', selectedDate);
            }
        } else {
            setFromDateFilter('');
            // Reset the "To Date" picker minDate when "From Date" is cleared
            if (toDateRef.current) {
                toDateRef.current.flatpickr.set('minDate', null);
            }
        }
    };
    const handleDateToChange = (date) => {
        if (date && date[0]) {
            const selectedDate = new Date(date[0]);
            selectedDate.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(selectedDate);
            setToDateFilter(formattedDate);
        } else {
            setToDateFilter('');
        }
    };
    const handleSort = (key: string) => {
        if (!sortBy || sortBy.key !== key) {
            // If sortBy is not defined or if sorting a different column, set ascending to true
            setSortBy({ key, ascending: true });
        } else {
            // Toggle between ascending and descending order
            setSortBy({ key, ascending: !sortBy.ascending });
        }
    };
    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const searchTerm = e.currentTarget.value;
        setSearchInput(searchTerm);
        const filteredItems = purchasesData.data.filter((purchase) => {
            return (
                purchase.vendor_name?.toLowerCase().includes(searchTerm) ||
                purchase.status?.toLowerCase().includes(searchTerm)
            )
        });
        setPurchaseList(filteredItems);
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handlePurchaseLogs = (purchase: any) => {
        navigate("/purchase-order/purchase-order-logs", { state: { id: purchase.id } });
    };
    const handleEditPurchaseOrder = (purchase: any) => {
        navigate("/purchase-order/edit-purchase-order", { state: { purchase: purchase } });
    };
    const handleViewPurchaseOrder = (purchase: any) => {
        navigate("/purchase-order/view-purchase-order", { state: { id: purchase.id } });
    };
    return (
        <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>List of Purchase Orders</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    {purchaseorder_add ? (
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Navigate to Create Purchase Order"
                            className='btn btn-sm btn-light-dark'
                            onClick={() => navigate('/purchase-order/add-purchase-order')}
                        >
                            <KTIcon iconName='plus' className='fs-3' />
                            Add Purchase Order
                        </button>
                    ) : null}
                </div>
            </div>
            {/*Search And Filter*/}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                        data-toggle="tooltip" data-placement="bottom" title="Search a Purchase Order"
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search Purchase Order'
                        value={searchInput}
                        onChange={handleInputChange}
                    />
                </h3>
                <div
                    className='card-toolbar'
                >
                    <h5 className='p-3'>Filter by:</h5>
                    <div className='mx-3'>
                        <Flatpickr
                            ref={fromDateRef}
                            onChange={handleDateFromChange}
                            className='form-control form-control-solid'
                            placeholder='From Date'
                            options={{
                                dateFormat: 'd/m/Y',
                            }}
                        />
                    </div>
                    <div className='mx-3'>
                        <Flatpickr
                            ref={toDateRef}
                            onChange={handleDateToChange}
                            className='form-control form-control-solid'
                            placeholder='To Date'
                            options={{
                                dateFormat: 'd/m/Y', // Adjust date format to day-month-year (DD/MM/YYYY)
                            }}
                        />
                    </div>
                    <div className='mx-3'>
                        <button
                            className='btn btn-light-danger '
                            onClick={() => {
                                fromDateRef.current?.flatpickr.clear(); // Clear the selected dates in the from date Flatpickr
                                toDateRef.current?.flatpickr.clear(); // Clear the selected dates in the to date Flatpickr
                            }}
                        >
                            Clear Dates
                        </button>
                    </div>
                </div>
            </div>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                </h3>
                <div
                    className='card-toolbar'
                >
                    <h5 className='p-3'>Filter by:</h5>
                    <div className='mx-3'>
                        <input
                            type='number'
                            value={startingPricefilter}
                            onChange={(e) => setStartingPriceFilter(e.target.value)}
                            className='form-control form-control-solid'
                            placeholder='Starting Price'
                        />
                    </div>
                    <div className='mx-3'>
                        <input
                            type='number'
                            value={endingPriceFilter}
                            onChange={(e) => setEndingPriceFilter(e.target.value)}
                            className='form-control form-control-solid'
                            placeholder='Ending Price'
                        />
                    </div>
                    <div className='mx-3'>
                        <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            value={selectedVendor}
                            onChange={(e) => setSelectedVendor(e.target.value)}
                        >
                            <option value=''>Filter by Vendor...</option>
                            {Array.isArray(showAllVendor) && showAllVendor.length > 0 ? (
                                showAllVendor.map((vendor) => (
                                    <option key={vendor.id} value={vendor.id}>
                                        {vendor.supplier_name}
                                    </option>
                                ))
                            ) : (
                                <option value='' disabled>No vendors available</option>
                            )}
                        </select>
                    </div>
                    <div className='mx-3'>
                        <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value=''>Filter by Status...</option>
                            <option value='PO-Generated'>PO-Generated</option>
                            <option value='Receipt-Generated'>Receipt-Generated</option>
                            <option value='Stock-Taken'>Stock-Taken</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
                <div>

                    <button
                        className='btn btn-primary me-3'
                        onClick={handleApplyFilters}
                    >
                        Apply Filters
                    </button>
                    <button
                        className='btn btn-light-danger'
                        onClick={handleClearFilters}
                    >
                        Clear Filters
                    </button>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* Headings of columns in table  */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-200px'>Vendor Name <span onClick={() => handleSort('vendor_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px'>Date <span onClick={() => handleSort('PO_date')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px'>Status <span onClick={() => handleSort('status')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px'>Grand Total ({getSettingValue('currency_symbol')})<span onClick={() => handleSort('grand_total')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px text-center'>Updated By <span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px'>Updated At <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                {purchaseorder_edit || purchaseorder_delete || purchaseorder_logs || purchaseorder_view ? (
                                    <th className='min-w-100px text-center'>Actions</th>
                                ) : null}
                                {purchaseorder_edit || purchaseorder_add ? (
                                    <th className='min-w-150px text-center'>Switch Status</th>

                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            {isPurchasesError ? (
                                <tr>
                                    <td>Error loading data. Please try again later.</td>
                                </tr>
                            ) : isPurchasesLoading ? (
                                <tr>
                                    <td colSpan={6}>
                                        <div className='text-center'>
                                            <div className='spinner-border' role='status'>
                                                <span className='visually-hidden'>Loading...</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : purchaseList.length > 0 ? (
                                visibleData?.map((purchase) => (
                                    <tr key={purchase.id}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {purchase.vendor_name}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {formatDateWithoutTime(purchase.PO_date)}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {purchase.status}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {purchase.grand_total}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex flex-column w-100 me-2'>
                                                <div className='d-flex flex-stack mb-2 cursor-pointer'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {purchase.updated_by_name}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start cursor-pointer'>
                                                    {formatDateWithTime(purchase.updated_at)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                {purchase.status === "PO-Generated" && purchaseorder_edit ? (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="Edit Purchase Order"
                                                        onClick={() => {
                                                            handleEditPurchaseOrder(purchase)
                                                        }
                                                        }
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='pencil' className='fs-3' />
                                                    </button>
                                                ) : null}
                                                {purchaseorder_view && (
                                                    <button
                                                        onClick={() => {
                                                            handleViewPurchaseOrder(purchase);
                                                        }}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-placement='top'
                                                        data-bs-custom-class='tooltip-dark'
                                                        title='See Purchase order Details'
                                                    >
                                                        <KTIcon iconName='eye' className='fs-3' />
                                                    </button>
                                                )}
                                                {purchaseorder_logs && (
                                                    <button
                                                        onClick={() => {
                                                            handlePurchaseLogs(purchase);
                                                        }}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-placement='top'
                                                        data-bs-custom-class='tooltip-dark'
                                                        title='Purchase Logs'
                                                    >
                                                        <i className='bi bi-journal-text'></i>
                                                    </button>

                                                )}
                                                {purchase.status === "PO-Generated" && purchaseorder_delete ? (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="Delete a Purchase Order"
                                                        onClick={() => setSelectedpurchaseOrder(purchase)}
                                                        className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#kt_modal_1'
                                                    >
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </button>
                                                ) : null}

                                            </div>
                                        </td>
                                        {purchaseorder_edit || purchaseorder_add ? (
                                            <td>

                                                <div>
                                                    {purchase.status === 'PO-Generated' ? (
                                                        <div className='d-flex align-items-center maxn-w-60px'>
                                                            <div className='d-flex justify-content-end flex-shrink-0 px-2'>
                                                                <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-primary btn-sm'
                                                                    onClick={() => {
                                                                        handleStatusRecieptPurchaseOrder.mutate(purchase.id)
                                                                    }}
                                                                >
                                                                    Generate Receipt
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : purchase.status === 'Receipt-Generated' ? (
                                                        <div className='d-flex align-items-center maxn-w-60px'>
                                                            <div className='d-flex justify-content-end flex-shrink-0 px-2'>
                                                                <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-success btn-sm'
                                                                    onClick={() => {
                                                                        handleStatusStockPurchaseOrder.mutate(purchase.id)
                                                                    }}
                                                                >
                                                                    Stock In
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex align-items-center maxn-w-60px'>
                                                            <div className='d-flex justify-content-end flex-shrink-0 px-2'>
                                                                <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-secondary btn-sm disabled'

                                                                >
                                                                    Added to Stock
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                    }
                                                </div>


                                            </td>
                                        ) : null}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>No Purchase Orders available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Delete modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Delete Purchase Order Confirmation</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedPurchaseOrder ? (
                                <>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Purchase Order of Vendor: </span> {selectedPurchaseOrder.vendor_name}
                                    </p>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Grand Total ({getSettingValue('currency_symbol')}): </span> {selectedPurchaseOrder.grand_total}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Purchase Order"
                                type='button'
                                onClick={() => {
                                    if (selectedPurchaseOrder && selectedPurchaseOrder.id) {
                                        handleDeletePurchaseOrder.mutate(selectedPurchaseOrder.id)
                                    }
                                }}
                                className='btn btn-danger'
                                data-bs-dismiss='modal'
                            >
                                Delete Purchase Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-footer d-flex justify-content-between py-4'>
                <div className='align-items-center row'>
                    <div className='d-flex'>
                        <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
                        <select
                            style={{ width: '60px' }}
                            className='form-select form-select-md px-2 col-md-1'
                            value={selectedItemsPerPage}
                            onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
                        >
                            {itemsPerPageOptions.map(option => (
                                <option key={option} value={option} className='p-3 '>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    {currentPage === 1 ? (
                        <button
                            className='btn btn-light'
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    ) : (
                        <button
                            className='btn btn-primary'
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    )}
                    <button
                        className='btn btn-primary ms-2'
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}
export default PurchasesList;