import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
// List Of Venders
export const get_all_vendors = async () => {
  let response = await axios.get(baseURL + "/api/vendors");
  return response.data;
}
// List Of All Active Vendors
export const get_all_active_vendors= async () => {
  let response = await axios.get(baseURL + "/api/vendors/1");
  return response.data;
}
// Status Filter Vendor
   export const get_all_filters_status_vendor=async (status) => {
    const response = await axios.get(baseURL + '/api/vendors/'+status);
      return response.data;
  };
// Add New supplier
export const add_vendor = async (values) => {
    let response = await axios.post(baseURL + "/api/vendor", values);
    return response.data;
}
//Delete Vendor Api
export const delete_vendor = async (id) => {
  let response = await axios.delete(baseURL + "/api/vendor/"+id);
  return response.data;
}

// Update the updateVendor function to accept an object as a parameter
export const update_vendor = async (updatedVendor) => {
  const {
    id,
    supplier_type,
    supplier_name,
    email,
    mobile_no,
    telephone,
    address_line_1,
    address_line_2,
    town,
    city,
    post_code,
    status,
  } = updatedVendor;

  try {
    const response = await axios.patch(baseURL + "/api/vendor/" + id, {
      supplier_type,
      supplier_name,
      email,
      mobile_no,
      telephone,
      address_line_1,
      address_line_2,
      town,
      city,
      post_code,
      status,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
// Get Single Vendor Logs by ID
export const get_vendor_logs = async (id: number) => {
  let response = await axios.get(baseURL + "/api/supplier-activity-log/" + id);
  return response.data;
};
// Get All Vendors Logs
export const get_all_vendors_logs = async () => {
  let response = await axios.get(baseURL + "/api/supplier-activity-logs");
  return response.data;
};
// change status 
export const change_active_status_vendor = async (id:number) => {
  let response = await axios.post(baseURL + "/api/update-status/" + id, {
    model_name:"supplier",
  });
  return response.data;  
}
// Single Vendor
export const get_single_vendor = async (id:number) => {
  let response = await axios.get(baseURL + "/api/vendor/"+id);
  return response.data;
}