import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_users_requisitions = async () => {
    let response = await axios.get(baseURL + "/api/get-created-requisition");
    return response.data;
}

export const get_assigned_requisitions = async () => {
    let response = await axios.get(baseURL + "/api/requisitions-assignees");
    return response.data;
}
export const get_assigned_quotations = async () => {
    let response = await axios.get(baseURL + "/api/quotation-assignees");
    return response.data;
}