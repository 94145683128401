import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon } from '../../../../_metronic/helpers';
import { KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { formatDateFlatpickr, formatDateWithTime, formatDateWithoutTime } from '../../../helper-functions/DateFunction';
import { delete_stock_in, get_all_filters_item_stock_in, get_all_stock_in } from '../api/stocksApi';
import Flatpickr from 'react-flatpickr';
import { get_all_vendors } from '../../vendors/api/vendorsApi';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
interface VendorType {
  id: number;
  supplier_name: string | undefined;
};
var stock_in_add, stock_in_edit, stock_in_view, stock_in_logs, stock_in_delete
function StockInList() {
  const [stockList, setStockList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [showAllVendor, setShowAllVendor] = useState<VendorType[]>([]);
  const [selectedStock, setSelectedStock] = useState<any | null>(null)
  const [visibleData, setVisibleData] = useState<any[]>([]);
  const [toDateFilter, setToDateFilter] = useState('');
  const fromDateRef = useRef<Flatpickr>(null);
  const toDateRef = useRef<Flatpickr>(null);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null);
  stock_in_add = permissionCheck('stock-create')
  stock_in_edit = permissionCheck('stock-update')
  stock_in_view = permissionCheck('stock-view')
  stock_in_logs = permissionCheck('stock-activity-log')
  stock_in_delete = permissionCheck('stock-delete')

  const { data: filterData, isLoading: isStocksLoading, isError: isStocksError, refetch: filterRefetch } = useQuery<any>(
    'filterData',
    () => fromDateFilter || toDateFilter || selectedVendor
      ? get_all_filters_item_stock_in(fromDateFilter, toDateFilter, selectedVendor)
      : get_all_stock_in(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (filteredData) => {
        setStockList(filteredData?.data);
      },
      onError: (error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      },
    }
  );
  const navigate = useNavigate();
  const handleApplyFilters = () => {
    filterRefetch(); // Call the function to fetch data with applied filters
  };

  const handleClearFilters = () => {
    if (fromDateRef.current) {
      fromDateRef.current.flatpickr.clear();
    }
    if (toDateRef.current) {
      toDateRef.current.flatpickr.clear();
    }
    setFromDateFilter('');
    setToDateFilter('');
    setSelectedVendor('');
    setSearchInput('');
    filterRefetch();
  };
  const { data: AllVendors } = useQuery('allVendors', get_all_vendors, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (AllVendors && Array.isArray(AllVendors.data)) {
      setShowAllVendor(AllVendors.data as VendorType[]);
    }
  }, [AllVendors]);
  const itemsPerPage = [10, 20, 30, 50, 100];
  const totalItems = stockList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage)
  useEffect(() => {
    if (stockList.length > 0) {
      let sortedData = [...stockList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          const aValue = a[sortBy.key] || '';
          const bValue = b[sortBy.key] || '';
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
          return sortBy.ascending ? aValue - bValue : bValue - aValue;
        });
      }
      const startIndex = (currentPage - 1) * selectedItemsPerPage;
      const endIndex = currentPage * selectedItemsPerPage;
      const visibleData = sortedData.slice(startIndex, endIndex);
      setVisibleData(visibleData);
    }
  }, [stockList, currentPage, selectedItemsPerPage, sortBy]);

  const handleDeleteStock = useMutation((id: number) => {
    return delete_stock_in(id).then((response) => {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 3000,
      });
    })
      .then(() => {
        return get_all_stock_in().then((data) => {
          setStockList(data.data);
        })
      })
      .catch((error: any) => {
        if (error.response) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 3000,
          });
        }
      });
  });
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value;
    setSearchInput(searchTerm);
    const filteredItems = filterData.data.filter((stock) => {
      return (
        stock.stock_type?.toLowerCase().includes(searchTerm) ||
        stock.vendor_name?.toLowerCase().includes(searchTerm) ||
        stock.date_time?.toLowerCase().includes(searchTerm)
      );
    });
    setStockList(filteredItems);
  };
  const handleDateFromChange = (date) => {
    if (date && date[0]) {
      const selectedDate = new Date(date[0]);
      selectedDate.setHours(0, 0, 0, 0);
      const formattedDate = formatDateFlatpickr(selectedDate);
      setFromDateFilter(formattedDate);

      // Update the "To Date" Flatpickr options
      if (toDateRef.current) {
        toDateRef.current.flatpickr.set('minDate', selectedDate);
      }
    } else {
      setFromDateFilter('');
      // Reset the "To Date" picker minDate when "From Date" is cleared
      if (toDateRef.current) {
        toDateRef.current.flatpickr.set('minDate', null);
      }
    }
  };
  const handleDateToChange = (date) => {
    if (date && date[0]) {
      const selectedDate = new Date(date[0]);
      selectedDate.setHours(0, 0, 0, 0);
      const formattedDate = formatDateFlatpickr(selectedDate);
      setToDateFilter(formattedDate);
    } else {
      setToDateFilter('');
    }
  };
  const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key || !sortBy.ascending) {
      setSortBy({ key, ascending: true }); // Sort in ascending order initially
    } else {
      setSortBy({ key, ascending: false }); // Sort in descending order
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleViewStockIn = (stock: any) => {
    navigate("/stocks/view-stock-in", { state: { id: stock.id } });
  }
  const handleStockInEdit = (stock: any) => {
    navigate("/stocks/edit-stock-in", { state: { id: stock.id } });
  }
  const handleStockInLogs = (stock: any) => {
    navigate("/stocks/stock-in-logs", { state: { id: stock.id } });
  };
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>List of Stock In</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {stock_in_add && (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Stock In"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/stocks/add-stock-in')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Stock In
            </button>
          )}
        </div>
      </div>
      {/*Search And Filter*/}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            data-toggle="tooltip" data-placement="bottom" title="Search a Product"
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search Stock In'
            value={searchInput}
            onChange={handleInputChange}
          />
        </h3>
        <div
          className='card-toolbar'
        >
          <h5 className='p-3'>Filter by:</h5>
          <div className='mx-3'>
            <Flatpickr
              ref={fromDateRef}
              onChange={handleDateFromChange}
              className='form-control form-control-solid'
              placeholder='From Date'
              options={{
                dateFormat: 'd/m/Y',
              }}
            />
          </div>
          <div className='mx-3'>
            <Flatpickr
              ref={toDateRef}
              onChange={handleDateToChange}
              className='form-control form-control-solid'
              placeholder='From Date'
              options={{
                dateFormat: 'd/m/Y',
              }}
            />
          </div>
          <div className='mx-3'>
            <button
              className='btn btn-light-danger '
              onClick={() => {
                setFromDateFilter('');
                setToDateFilter('');
                if (fromDateRef.current) {
                  fromDateRef.current.flatpickr.clear();
                }
                if (toDateRef.current) {
                  toDateRef.current.flatpickr.clear();
                }
              }}
            >
              Clear Dates
            </button>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
        </h3>
        <div
          className='card-toolbar'
        >
          <h5 className='p-3'>Filter by:</h5>
          <div className='mx-3'>
            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedVendor}
              onChange={(e) => setSelectedVendor(e.target.value)}
            >
              <option value=''>Filter by Vendor...</option>
              {Array.isArray(showAllVendor) && showAllVendor.length > 0 ? (
                showAllVendor.map((vendor) => (
                  <option key={vendor.id} value={vendor.id}>
                    {vendor.supplier_name}
                  </option>
                ))
              ) : (
                <option value='' disabled>No vendors available</option>
              )}
            </select>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
        <div>

          <button
            className='btn btn-primary me-3'
            onClick={handleApplyFilters}
          >
            Apply Filters
          </button>
          <button
            className='btn btn-light-danger'
            onClick={handleClearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* Headings of columns in table  */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Vendor Name <span onClick={() => handleSort('vendor_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Date <span onClick={() => handleSort('date_time')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Has PO <span onClick={() => handleSort('has_po')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-100px'>Updated At <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Updated By <span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                {stock_in_delete || stock_in_edit || stock_in_logs || stock_in_view ? (
                  <th className='min-w-100px text-center'>Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {isStocksError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isStocksLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : stockList.length > 0 ? (
                visibleData.map((stock) => (
                  <tr key={stock.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {stock.vendor_name ? stock.vendor_name : "No Vendor"}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {formatDateWithoutTime(stock.date_time)}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start cursor-pointer'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {stock.has_po === 1 ? 'Yes' : 'No'}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start cursor-pointer'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {formatDateWithTime(stock.updated_at)}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {stock.updated_by_name}
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {stock.has_po === 0 && stock_in_edit && (
                          <button
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Edit Stock In"
                            onClick={() => handleStockInEdit(stock)}
                            className="btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1"
                          >
                            <KTIcon iconName="pencil" className="fs-3" />
                          </button>
                        )}
                        {stock_in_view && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="View Stock In"
                            onClick={() => handleViewStockIn(stock)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </button>
                        )}
                        {stock_in_logs && (
                          <button
                            onClick={() => {
                              handleStockInLogs(stock);
                            }}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-custom-class='tooltip-dark'
                            title='Stock Logs'
                          >
                            <i className='bi bi-journal-text'></i>
                          </button>
                        )}
                        {stock_in_delete && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Delete Stock In"
                            onClick={() => setSelectedStock(stock)}
                            className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Stocks Available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
        <div className='align-items-center row'>
          <div className='d-flex'>
            <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
            <select
              style={{ width: '60px' }}
              className='form-select form-select-md px-2 col-md-1'
              value={selectedItemsPerPage}
              onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
            >
              {itemsPerPage.map(option => (
                <option key={option} value={option} className='p-3 '>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {currentPage === 1 ? (
            <button
              className='btn btn-light'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          ) : (
            <button
              className='btn btn-primary'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          <button
            className='btn btn-primary ms-2'
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
      {/* Delete modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete Stock In Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedStock ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold'>Stock Type:</span> {selectedStock.stock_type}
                  </p>
                  <p className='mb-2'>
                    <span className='fw-bold'>Vendor Name</span> {selectedStock.vendor_name ? selectedStock.vendor_name : "No Vendor"}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Delete this Stock"
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  if (selectedStock) {
                    handleDeleteStock.mutate(selectedStock.id);
                  }
                }}
              >
                Delete Stock In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StockInList;