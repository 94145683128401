import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import { add_quotation_document, delete_quotation_document, get_all_quotation_documents, get_all_quotation_vendors, get_all_quotations, update_quotation, update_quotation_document } from '../api/quotationsApi';
import { get_all_requisitions } from '../../../requisitions/requisitions/api/requisitionsApi';
import { get_all_active_products } from '../../../products/api/productsApi';
import { get_all_active_vendors } from '../../../vendors/api/vendorsApi';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { formatDateFlatpickr, formatDateWithoutTime } from '../../../../helper-functions/DateFunction';
import Select from 'react-select';
import { get_all_users } from '../../../users/api/usersApi';
import { KTIcon, KTSVG } from '../../../../../_metronic/helpers';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { mediaPath } from '../../../../helper-functions/ImageStoragePath';
const imagePng = `${mediaPath()}/png.png`;
const imagePdf = `${mediaPath()}/pdf.png`;
const imageJpg = `${mediaPath()}/jpg-file.png`;
type Quotation = {
    id: number;
    title: string | undefined;
    description: string | undefined;
    due_date: string;
    requisition_id: number | undefined;
    requisition_title: string | undefined;
    updated_by: string;
    updated_at: string;
    quotation_products: {
        id: number;
        quotation_id: number;
        quantity: number;
        product_id: number;
        product_name: string;
        quotation_detail_price: {
            id: number;
            supplier_id: number;
            supplier_name: string;
            price: number;
            approved: number;
            updated_by: string;
            updated_at: string;
        }[];
        updated_by: string;
        updated_at: string;
    }[];
    quotation_assignees: {
        id: number;
        user_id: number;
        user_name: string;
        updated_by: string;
        updated_at: string;
        product_id: number;
        requisition_id: number;
    }[];
};
interface Requisition {
    id: number
    title: string
};
interface Product {
    id: number;
    name: string;
};
interface Vendor {
    id: number;
    name: string;
    supplier_name: string;
};
interface User {
    id: number;
    full_name: string;
};
type QuotationDocument = {
    id: number;
    document_title: string | undefined;
    document_description: string | undefined;
    document_path: string | undefined;
    vendor_id: number;
    vendor_name: string | undefined;
    updated_by: string | undefined;
    updated_at: string;
};
function EditQuotation() {
    const [selectedQuotation, setSelectedQuotation] = useState<Quotation | null>();
    const [requisition, setRequisition] = useState<Requisition[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [vendor, setVendor] = useState<Vendor[]>([]);
    const [titleValidationError, setTitleValidationError] = useState('');
    const [dateValidationError, setDateValidationError] = useState('');
    const [requisitionValidationError, setRequisitionValidationError] = useState('');
    const [productValidationError, setProductValidationError] = useState<string[]>([]);
    const [quantityValidationError, setQuantityValidationError] = useState<any>([]);
    const [priceValidationError, setPriceValidationError] = useState<any>([]);
    const [vendorValidationError, setVendorValidationError] = useState<string[]>([]);
    const [assigneeValidationError, setAssigneeValidationError] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const [documentList, setDocumentList] = useState<QuotationDocument[]>([])
    const [previousProductIndex, setPreviousProductIndex] = useState(0);
    const [vendorValue, setVendorValue] = useState<Vendor[]>([]);
    const [productValue, setProductValue] = useState<Product[]>([]);
    const [documentVendorArray, setDocumentVendorArray] = useState<Vendor[]>([]);
    const [duplicateDocumentVendorArray, setDuplicateDocumentVendorArray] = useState<Vendor[]>([]);
    const [documentVendor, setDocumentVendor] = useState('');
    const [documentVendorValidation, setDocumentVendorValidation] = useState('');
    const [documentTitleValue, setDocumentTitleValue] = useState('');
    const [documentDescriptionValue, setDocumentDescriptionValue] = useState('');
    const [fileValue, setFileValue] = useState<File | null>(null);
    const [fileValidationError, setFileValidationError] = useState('');
    const [selectedDocument, setSelectedDocument] = useState<QuotationDocument | null>();
    const [showNewFields, setShowNewFields] = useState(true);
    const [loading, setLoading] = useState(false);
    const [documentLoading, setDocumentLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);
    const quotation = (location.state as { quotation: Quotation }).quotation;
    const allQuotationProducts: any = []

    const { refetch } = useQuery('quotations', get_all_quotations, {refetchOnWindowFocus: false});
    useEffect(() => {
        setSelectedQuotation(quotation);
    }, [quotation]);

    const { data: requisitionData } = useQuery('requisition', get_all_requisitions, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (requisitionData) {
            setRequisition(requisitionData.data as Requisition[]);
        };
    }, [requisitionData]);

    const { data: productData } = useQuery('products', get_all_active_products, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (productData) {
            setProducts(productData.data as Product[]);
        }
    }, [productData]);

    const { data: vendorData } = useQuery('vendors', get_all_active_vendors, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (vendorData) {
            setVendor(vendorData.data as Vendor[]);
        }
    }, [vendorData]);
    const { data: userData } = useQuery('users', get_all_users, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (userData) {
            setUsers(userData.data as User[]);
        }
    }, [userData]);

    const updateQuotationMutation = useMutation(update_quotation, {
        onSuccess: (response) => {
            refetch();
            setLoading(false);
            navigate('/quotations/quotations-list');
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
        },
        onError: (error: any) => {
            setLoading(false)
            toast.error(error.message, {
                position: 'top-right',
                autoClose: 3000,
            });
        },
    });
    const generateTempId = () => {
        return Math.floor(100 + Math.random() * 900).toString(); // Generate a random 3-digit number
    };
    
    const handleUpdateQuotation = () => {
        setLoading(true);
        if (selectedQuotation) {
            const { id, title, description, due_date, requisition_id, requisition_title, quotation_products, quotation_assignees } = selectedQuotation;
            const isProductNameEmpty = quotation_products.some(product => !product.product_id);
            if (isProductNameEmpty) {
                toast.error('Product name is required for all products.', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                setLoading(false);
                return;
            }
            const isVendorMissing = quotation_products?.some(product => {
                const isVendorDetailMissing = product.quotation_detail_price?.some(detail => !detail.supplier_id);
                if (isVendorDetailMissing) {
                    toast.error('Vendor is Required For Product.', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                    return true; // Stop processing if a vendor is missing
                }
                return false;
            });
            if (isVendorMissing) {
                setLoading(false);
                return; // Stop the update if a vendor is missing
            }
            // Continue with the update process
            const updatedProducts = quotation_products?.map(product => {
                const { id, product_id, quantity, quotation_detail_price } = product;
                // Map over the nested quotation_detail_price array to ensure proper structure
                const updatedDetailPrices = quotation_detail_price?.map(detail => ({
                    id: detail.id || generateTempId(), // Generate a temporary ID if id is missing
                    supplier_id: detail.supplier_id,
                    price: detail.price || 0,
                    approved: detail.approved,
                })) || []; // Default to an empty array if quotation_detail_price is undefined
    
                return {
                    id,
                    quantity,
                    product_id,
                    quotation_detail_prices: updatedDetailPrices,
                };
            }).filter(Boolean);
            const updatedAssignees = quotation_assignees.map((assignee) => ({
                id: assignee.id,
                user_id: assignee.user_id,
            }));
            updateQuotationMutation.mutate({
                id,
                title,
                description,
                due_date,
                requisition_id,
                quotation_products: updatedProducts,
                quotation_assignees: updatedAssignees,
            });
        }
    };
    
    const handleQuotationDateChange = (selectedDates) => {
        if (selectedQuotation) {
            const newQuotationDate = selectedDates[0];

            if (!newQuotationDate) {
                setDateValidationError('Due Date is required.');
            } else {
                setDateValidationError('');
            }
            newQuotationDate?.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(newQuotationDate);
            setSelectedQuotation({
                ...selectedQuotation,
                due_date: formattedDate,
            });
        }
    };

    const handleQuotationTitleChange = (e) => {
        if (selectedQuotation) {
            const newQuotationTitle = e.target.value;
            if (!newQuotationTitle) {
                setTitleValidationError('Quotation Title is required.');
            } else {
                setTitleValidationError('');
            };
            setSelectedQuotation({
                ...selectedQuotation,
                title: newQuotationTitle,
            });
        };
    };

    const handleQuotationDesChange = (e) => {
        if (selectedQuotation) {
            const newQuotationDes = e.target.value;
            setSelectedQuotation({
                ...selectedQuotation,
                description: newQuotationDes,
            });
        };
    };

    const handleAssigneeChange = (selectedOptions) => {
        if (!selectedOptions || selectedOptions.length === 0) {
            setAssigneeValidationError('Assignee is required.');
        } else {
            setAssigneeValidationError('');
        }
        if (selectedQuotation) {
            const updatedAssignees = selectedOptions.map((selectedOption, index) => ({
                id: index + 1,
                user_id: parseInt(selectedOption.value, 10),
                user_name: selectedOption.label,
            }));
            setSelectedQuotation({
                ...selectedQuotation,
                quotation_assignees: updatedAssignees,
            });
        }
    };

    const handleProductChange = (e, index) => {
        const { value } = e.target;
        if (!value) {
            setProductValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = 'Product is required.';
                return updatedErrors;
            });
        } else {
            setProductValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = '';
                return updatedErrors;
            });
        }
        if (selectedQuotation) {
            const updatedProducts = [...selectedQuotation.quotation_products];
            updatedProducts[index].product_id = parseInt(value, 10);
            setSelectedQuotation({
                ...selectedQuotation,
                quotation_products: updatedProducts,
            });
        }
    };

    const handleQuantityChange = (e, index) => {
        const { value } = e.target;
        if (value === '') {
            setQuantityValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = 'Quantity is required.';
                return updatedErrors;
            });
        } else {
            setQuantityValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = ''; // Clear the error for the specific index
                return updatedErrors;
            });
        }
        if (selectedQuotation) {
            const updatedProducts = [...selectedQuotation.quotation_products];
            updatedProducts[index].quantity = value !== '' ? parseInt(value, 10) : 0;

            setSelectedQuotation({
                ...selectedQuotation,
                quotation_products: updatedProducts,
            });
        }
    };

    const handleVendorChange = (e, productIndex, index) => {
        const { value } = e.target;
        if (!value) {
            setVendorValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = 'Vendor is required.';
                return updatedErrors;
            });
        } else {
            setVendorValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = '';
                return updatedErrors;
            });;
        };
        // Ensure that selectedRequisition is not null or undefined
        if (selectedQuotation) {
            const updatedProducts = [...selectedQuotation.quotation_products];
            updatedProducts[productIndex].quotation_detail_price[index].supplier_id = parseInt(value, 10);
            setSelectedQuotation({
                ...selectedQuotation,
                quotation_products: updatedProducts,
            });
        };
    };

    const handlePriceChange = (e, productIndex, index) => {
        const { value } = e.target;
        if (!selectedQuotation || !selectedQuotation.quotation_products[productIndex]) {
            // Handle the case when selectedQuotation or quotation_products[productIndex] is undefined
            return;
        }
        const updatedProducts = [...selectedQuotation.quotation_products];
        const product = updatedProducts[productIndex];
        if (!product.quotation_detail_price) {
            // If quotation_detail_price is undefined, initialize it as an empty array
            product.quotation_detail_price = [];
        }
        product.quotation_detail_price[index] = {
            ...product.quotation_detail_price[index],
            price: value !== '' ? parseInt(value, 10) : 0,
        };
        setSelectedQuotation({
            ...selectedQuotation,
            quotation_products: updatedProducts,
        });
    };
    const handleRemoveProduct = (index) => {
        // Remove the product at the specified index from the selectedQuotation state
        setSelectedQuotation((prev: any) => ({
            ...prev,
            quotation_products: [
                ...prev.quotation_products.slice(0, index),
                ...prev.quotation_products.slice(index + 1),
            ],
        }));
    };

    const handleAddVendor = (productIndex) => {
        // Ensure that selectedQuotation is not null or undefined
        if (selectedQuotation) {
            const updatedProducts = [...selectedQuotation.quotation_products];
            if (productIndex !== previousProductIndex) {
                setVendorValue([])
                setPreviousProductIndex(productIndex)
                setDuplicateDocumentVendorArray([])

            }
            // Ensure that the current product has a valid array for quotation_detail_price
            const currentDetailPrices = updatedProducts[productIndex]?.quotation_detail_price || [];
            // Add a default vendor field
            const updatedDetailPrices: any = [...currentDetailPrices, { supplier_id: null, price: 0, approved: 0 }];
            updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                quotation_detail_price: updatedDetailPrices,
            };
            // Update the state
            setSelectedQuotation({
                ...selectedQuotation,
                quotation_products: updatedProducts,
            });
        }
    };

    const handleApproveVendor = (productIndex, detailIndex) => {
        if (selectedQuotation) {
            const updatedProducts = [...selectedQuotation.quotation_products];
            // Set the approval status to 1 for the clicked vendor and 0 for all other vendors
            updatedProducts[productIndex].quotation_detail_price.forEach((vendor, index) => {
                vendor.approved = index === detailIndex ? 1 : 0;
            });
            // Update the state
            setSelectedQuotation({
                ...selectedQuotation,
                quotation_products: updatedProducts,
            });
        }
    };

    const handleAddProduct = () => {
        // Check if the products list is not empty
        if (products.length === 0) {
            // If the products list is empty, show an error
            toast.error('No products available. Add products before adding to the requisition.', {
                position: 'top-right',
                autoClose: 3000,
            });
            return;
        };
        // Check if the last product in the list is not empty
        if (
            selectedQuotation &&
            selectedQuotation.quotation_products.length > 0 &&
            !selectedQuotation.quotation_products[selectedQuotation.quotation_products.length - 1].product_id
        ) {
            // If the last product is empty, show an error
            toast.error('Product is required before adding a new one.', {
                position: 'top-right',
                autoClose: 3000,
            });
            return;
        };
        setSelectedQuotation((prev: any) => ({
            ...prev,
            quotation_products: [
                ...prev.quotation_products,
                { id: null, quantity: 0, product_id: null, updated_by: '', updated_at: '' },
            ],
        }));
    };
    const handleRemoveVendor = (productIndex, detailIndex) => {
        if (selectedQuotation) {
            const updatedProducts = [...selectedQuotation.quotation_products];
            const currentDetailPrices = updatedProducts[productIndex]?.quotation_detail_price || [];
            const updatedDetailPrices = [...currentDetailPrices];
            // Remove the vendor at the specified detailIndex
            updatedDetailPrices.splice(detailIndex, 1);
            // Update the state
            updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                quotation_detail_price: updatedDetailPrices,
            };
            setSelectedQuotation({
                ...selectedQuotation,
                quotation_products: updatedProducts,
            });
        }
    };
    const handleVendorListChange = (e, productIndex) => {
        const valueIz = e.target.value
        if (productIndex === previousProductIndex) {
            const newVendorvalue = vendor.find((vendor) => vendor.id == valueIz);
            if (newVendorvalue) {
                //setting an array to check if the vendor is already selected
                const currentArray = [...vendorValue];
                currentArray.push(newVendorvalue);
                setVendorValue(currentArray);
                // setting a new array to all selected vendors for documents
                if (!documentVendorArray.includes(newVendorvalue)) {
                    const currentDocumentArray = [...documentVendorArray];
                    const duplicateCurrentDocumentArray = [...duplicateDocumentVendorArray];
                    currentDocumentArray.push(newVendorvalue);
                    duplicateCurrentDocumentArray.push(newVendorvalue);
                    setDocumentVendorArray(currentDocumentArray);
                    setDuplicateDocumentVendorArray(duplicateCurrentDocumentArray)
                }
            }
        };
    };
    const handleProductListChange = (e) => {
        const newProduct = e.target.value
        const newProductValue = products.find((product) => product.id == newProduct);
        if (newProductValue) {
            //setting an array to check if the vendor is already selected
            const currentArray = [...productValue];
            currentArray.push(newProductValue);
            setProductValue(currentArray);
        }
        console.log(newProductValue)
        console.log(productValue)
    };
    const handleQuotationProductsList = (productIndex) => {
        allQuotationProducts.push(selectedQuotation?.quotation_products[productIndex].product_id)
    }

    const { data: quotationDocumentData, isLoading: isQuotationDocumentLoading, isError, refetch: documentRefetch } = useQuery(
        ['requistionDocument', quotation.id],
        () => get_all_quotation_documents(quotation.id!),
        {
            refetchOnWindowFocus: false,
            enabled: !!quotation,
        }
    );
    useEffect(() => {
        if (quotationDocumentData) {
            setDocumentList(quotationDocumentData.data as QuotationDocument[]);
        }
    }, [quotationDocumentData]);
    const { data: vendorsData } = useQuery(['vendors', quotation.id], () => get_all_quotation_vendors(quotation.id),
        {
            refetchOnWindowFocus: false,
            enabled: !!quotation.id,
        }
    );
    useEffect(() => {
        if (vendorsData) {
            setDocumentVendorArray(vendorsData.data as Vendor[]);
            setDuplicateDocumentVendorArray(vendorsData.data as Vendor[])
        }
    }, [vendorsData]);
    const addDocumentMutation = useMutation(add_quotation_document, {
        onSuccess: (response) => {
            documentRefetch();
            setDocumentLoading(false);
            toast.success("Document is uploaded successfully", {
                position: 'top-right',
                autoClose: 3000,
            });
            if (formRef.current) {
                formRef.current.reset();
            }
            setDocumentVendor('');
            setDocumentTitleValue('');
            setDocumentDescriptionValue('');
            setFileValue(null)
        },
        onError: (error: any) => {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
                setDocumentLoading(false);
            }
        },
    });
    const handleAddDocument = () => {
        setDocumentLoading(true);
        addDocumentMutation.mutate({
            supplier_id: documentVendor,
            document_title: documentTitleValue,
            document_description: documentDescriptionValue,
            document_path: fileValue,
            quotation_id: quotation.id,
        });
    };
    const handleDeleteDocument = useMutation((id: number) => {
        return delete_quotation_document(id).then((response) => {
            toast.success(response.message, {
                position: "top-right",
                autoClose: 3000,
            });
        })
            .then(() => {
                return get_all_quotation_documents(quotation.id).then((data) => {
                    setDocumentList(data.data);
                });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                }
            });
    });
    //update document
    const updateDocumentMutation = useMutation(update_quotation_document, {
        onSuccess: (response) => {
            documentRefetch();
            setLoading(false);
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
        },
        onError: (error: any) => {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
                setLoading(false)
            }
        },
    });
    const handleUpdateDocument = () => {
        setLoading(true);
        if (selectedDocument) {
            const { id, document_title, document_description, document_path, vendor_id } = selectedDocument;
            updateDocumentMutation.mutate({
                id,
                supplier_id: vendor_id,
                document_title,
                document_description,
                document_path,
                quotation_id: quotation.id,
            });
        };
    };
    const HandleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFileValue(e.target.files[0]);
        };
    };
    const handleFileValidation = () => {
        if (!fileValue) {
            setFileValidationError('File is required.');
            return;
        } else {
            setFileValidationError('');
        }
    };
    // File Icon
    const renderFileIcon = (filePath: string | undefined) => {
        if (!filePath) {
            return null; // Handle the case where the file path is not available
        }
        const fileExtension = filePath.split('.').pop()?.toLowerCase();
        switch (fileExtension) {
            case 'pdf':
                return (
                    <img
                        src={imagePdf}
                        style={{ width: '60px', height: "auto" }}
                        alt='document-image'
                        className='document-image'
                    />
                );
            case 'png':
                return (
                    <img
                        src={imagePng}
                        style={{ width: '60px', height: "auto" }}
                        alt='document-image'
                    />
                );
            case 'jpeg':
            case 'jpg':
                return (
                    <img
                        src={imageJpg}
                        style={{ width: '60px', height: "auto" }}
                        alt='document-image'
                        className='document-image'
                    />
                );
            default:
                return (
                    <i className='bi bi-file fs-1'></i>
                ); // Default icon for unknown file types
        }
    };
    const getProductNameById = (productId) => {
        const product = products.find((p) => p.id === productId);
        return product ? product.name : 'Product not found';
    };
    const getAssigneeNameById = (assigneeId) => {
        const assignee = users.find((p) => p.id === assigneeId);
        return assignee ? assignee.full_name : 'Assignee not found';
    };
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Edit Quotation</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/quotations/quotations-list')}
                    >
                        List of Quotations
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {selectedQuotation ? (
                        <>
                            {/* ... Edit Form ... */}
                            <Form>
                                <div className='mb-2 row mb-6'>
                                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Quotation Title</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            id='quotationTitle'
                                            aria-describedby='quotationTitle'
                                            placeholder='Enter New Title'
                                            value={selectedQuotation ? selectedQuotation.title : ''}
                                            onChange={(e) => handleQuotationTitleChange(e)}
                                        />
                                        {titleValidationError && (
                                            <div className='text-danger'>{titleValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='mb-2 row mb-6'>
                                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Description</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            id='UpdateProductShortDes'
                                            aria-describedby='UpdateProductShortDes'
                                            placeholder='Enter Short Description'
                                            value={selectedQuotation ? selectedQuotation.description : ''}
                                            onChange={(e) => handleQuotationDesChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className='mb-2 row mb-6'>
                                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Valid Until</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Flatpickr
                                            onChange={(selectedDates) => handleQuotationDateChange(selectedDates)}
                                            className='form-control form-control-solid'
                                            placeholder={formatDateWithoutTime(selectedQuotation.due_date)}
                                            options={{
                                                dateFormat: 'd/m/Y',
                                            }}
                                        />
                                        {dateValidationError && (
                                            <div className='text-danger'>{dateValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='mb-2 row mb-6'>
                                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Quotation Assignee</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Select
                                            isMulti
                                            placeholder="Select "
                                            options={users.map((user) => ({
                                                value: user.id,
                                                label: user.full_name,
                                            }))}
                                            className="basic-multi-select form-select-lg p-2 bg-light text-black"
                                            classNamePrefix="select border-light fw-bold"
                                            value={selectedQuotation.quotation_assignees.map((assignee) => ({
                                                value: assignee.user_id,
                                                label: getAssigneeNameById(assignee.user_id),
                                            }))}
                                            onChange={(selectedOptions) => {
                                                // Assuming handleAssigneeChange receives an array of selected options
                                                handleAssigneeChange(selectedOptions);
                                            }}
                                        />
                                        {assigneeValidationError && (
                                            <div className='text-danger'>{assigneeValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='mb-2 row mb-6'>
                                    <Form.Label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Requisition Title </Form.Label>
                                    <div className='col-lg-8 fv-row'>
                                        <Form.Group controlId='requisitionTitle'>
                                            <Form.Select
                                                className='form-control form-control-lg form-control-solid'
                                                name='requisitionTitle'
                                                value={selectedQuotation ? selectedQuotation.requisition_id : ''}
                                            >
                                                <option value={selectedQuotation ? selectedQuotation.requisition_id : ""}>{selectedQuotation.requisition_title}</option>
                                            </Form.Select>
                                        </Form.Group>
                                        {requisitionValidationError && (
                                            <div className='text-danger'>{requisitionValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                {selectedQuotation && selectedQuotation.quotation_products && (
                                    <div className='row'>
                                        <div className='col-lg-4 '></div>
                                        <div className='col-lg-8'>
                                            <div className='mt-3'>
                                                <button data-toggle="tooltip" data-placement="bottom" title="Add Product" type='button' className='btn btn-primary mt-3' onClick={handleAddProduct}>
                                                    Add Product
                                                </button>
                                            </div>
                                            {selectedQuotation.quotation_products.map((quotationProduct, productIndex) => (
                                                <div key={productIndex} className='border border-secondary rounded p-3 mt-3'>
                                                    {selectedQuotation.quotation_products[productIndex].product_id ? (
                                                        <>
                                                        {handleQuotationProductsList(productIndex)}
                                                            <div className='row mb-6'>
                                                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                                    Product Name
                                                                </label>
                                                                <div className='col-lg-8 fv-row'>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control form-control-lg form-control-solid'
                                                                        placeholder='Enter New Title'
                                                                        value={getProductNameById(selectedQuotation ? selectedQuotation.quotation_products[productIndex].product_id : '')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className='row mb-6'>
                                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                                Product Name
                                                            </label>
                                                            <div className='col-lg-8 fv-row'>
                                                                <select
                                                                    value={quotationProduct.product_id}
                                                                    onChange={(e) => {
                                                                        handleProductChange(e, productIndex)
                                                                        handleProductListChange(e)
                                                                    }}
                                                                    className='form-select form-select-lg form-select-solid'
                                                                >
                                                                    <option value=''>Select a Product...</option>
                                                                    {products.map((product) => (
                                                                        productValue.includes(product) || allQuotationProducts.includes(product.id) ? (
                                                                            <option key={product.id} value={product.id} disabled className="bg-primary text-white">
                                                                                {product.name}
                                                                            </option>
                                                                        ) : (
                                                                            <option key={product.id} value={product.id}>
                                                                                {product.name}
                                                                            </option>
                                                                        )
                                                                    ))}

                                                                </select>
                                                                {productValidationError[productIndex] && (
                                                                    <div className='text-danger'>{productValidationError[productIndex]}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {quotationProduct.quantity ? (
                                                        <div className='row mb-6'>
                                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                                Quantity
                                                            </label>
                                                            <div className='col-lg-8 fv-row'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    placeholder='Enter Quantity'
                                                                    value={selectedQuotation ? selectedQuotation.quotation_products[productIndex].quantity : ''}

                                                                />

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='row mb-6'>
                                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Quantity</label>
                                                            <div className='col-lg-8 fv-row'>
                                                                <input
                                                                    type='number'
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    placeholder='Enter quantity'
                                                                    value={quotationProduct.quantity}
                                                                    onChange={(e) => handleQuantityChange(e, productIndex)}
                                                                />
                                                                {quantityValidationError[productIndex] && (
                                                                    <div className='text-danger'>{quantityValidationError[productIndex]}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <button
                                                        type='button'
                                                        className='btn btn-success mt-2'
                                                        onClick={() => handleAddVendor(productIndex)}
                                                    >
                                                        Add Vendor
                                                    </button>
                                                    {selectedQuotation.quotation_products[productIndex].quotation_detail_price?.map((single_product, index) => (
                                                        <div key={index}>
                                                            {showNewFields && (
                                                                <div className='row mb-6 mx-6'>
                                                                    <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                                                                        Vendor
                                                                    </label>
                                                                    <div className='col-lg-4 fv-row'>
                                                                        <select
                                                                            value={selectedQuotation ? selectedQuotation.quotation_products[productIndex].quotation_detail_price[index].supplier_id : ''}
                                                                            onChange={(e) => {
                                                                                handleVendorChange(e, productIndex, index)
                                                                                handleVendorListChange(e, productIndex)
                                                                            }}

                                                                            className='form-select form-select-lg form-select-solid'
                                                                        >
                                                                            <option value=''>Select a Vendor...</option>
                                                                            {Array.isArray(vendor) && vendor.length > 0 ? (
                                                                                vendor.map((vendor) => (
                                                                                    <>
                                                                                        {duplicateDocumentVendorArray.some(docVendor => docVendor.id === vendor.id) ? (
                                                                                            <option key={vendor.id} value={vendor.id} disabled className="bg-primary text-white">
                                                                                                {vendor.supplier_name}
                                                                                            </option>
                                                                                        ) : (
                                                                                            <option key={vendor.id} value={vendor.id}>
                                                                                                {vendor.supplier_name}
                                                                                            </option>
                                                                                        )}
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <option value='' disabled>No Vendors Available</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                    <div className='col-lg-3 fv-row'>
                                                                        <input
                                                                            type='number'
                                                                            className='form-control form-control-lg form-control-solid'
                                                                            placeholder='Price'
                                                                            value={selectedQuotation? selectedQuotation.quotation_products[productIndex].quotation_detail_price[index].price : ''}
                                                                            onChange={(e) => handlePriceChange(e, productIndex, index)}
                                                                        />
                                                                    </div>
                                                                    <div className="form-check form-check-custom form-check-solid col-lg-3 fv-row">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id={`flexCheckDefault${index}`}
                                                                            value={selectedQuotation ? selectedQuotation.quotation_products[productIndex].quotation_detail_price[index].approved : ''}
                                                                            onChange={() => handleApproveVendor(productIndex, index)}
                                                                            checked={selectedQuotation.quotation_products[productIndex].quotation_detail_price[index].approved === 1}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`flexCheckDefault${index}`}>
                                                                            Recommended
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-lg-2 fv-row'>
                                                                        <button
                                                                            data-toggle="tooltip" data-placement="bottom" title="Remove Vendor"
                                                                            className="btn btn-icon btn-light-dark btn-active-color-danger btn-sm"
                                                                            onClick={() => handleRemoveVendor(productIndex, index)}
                                                                        >
                                                                            <KTIcon iconName="trash" className="fs-3" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <button
                                                            data-toggle="tooltip" data-placement="bottom" title="Remove Product"
                                                            type='button'
                                                            className='btn btn-danger mt-3'
                                                            onClick={() => handleRemoveProduct(productIndex)}
                                                        >
                                                            Remove Product
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                )}
                                <div className='row'>
                                    <div className='col-lg-4'></div>
                                    <div className=' col-lg-8'>
                                        {documentList.length > 0 && (
                                            documentList?.map((document, index) => (
                                                <div className='card mb-5 mb-xl-10 mt-3 border-danger' key={index}>
                                                    <div className='card-header'>
                                                        <div className='card-title m-0'>
                                                            <h3 className='fw-bolder m-0'>Documents</h3>
                                                        </div>
                                                    </div>
                                                    <Form className='card-body row'>
                                                        <div className=' col-lg-7 mt-2 mx-4' >
                                                            <div className='form-group'>
                                                                <div className='row mb-6'>
                                                                    <div className='col-lg-10 fv-row'>
                                                                        <select
                                                                            className='form-control form-control-lg form-control-solid'
                                                                            placeholder='Title'
                                                                            value={document.vendor_id}
                                                                            disabled
                                                                        >
                                                                            <option value=''>No Vendor Selected...</option>
                                                                            {Array.isArray(documentVendorArray) && documentVendorArray.length > 0 ? (
                                                                                documentVendorArray.map((vendor) => (
                                                                                    <option key={vendor.id} value={vendor.id} disabled className="bg-primary text-white">
                                                                                        {vendor.supplier_name ? vendor.supplier_name : vendor.name}
                                                                                    </option>
                                                                                ))
                                                                            ) : (
                                                                                <option value='' disabled>No Vendors Available</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='row mb-6'>
                                                                    <div className='col-lg-10 fv-row'>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control form-control-lg form-control-solid'
                                                                            placeholder='Title'
                                                                            value={document.document_title}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='row mb-6'>
                                                                    <div className='col-lg-10 fv-row'>
                                                                        <input
                                                                            type='textarea'
                                                                            className='form-control form-control-lg form-control-solid'
                                                                            placeholder='Description'
                                                                            value={document.document_description}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-3 mx-6 mt-4'>
                                                            <div className="symbol symbol-125px me-10 mb-10 ">
                                                                <div className="symbol-label fs-6 fw-bold text-success">
                                                                    {renderFileIcon(document.document_path)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                                        <button
                                                            data-toggle="tooltip" data-placement="bottom" title="Remove this product"
                                                            type='button'
                                                            className='btn btn-primary mx-2'
                                                            onClick={() => setSelectedDocument(document)}
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#kt_modal_3'
                                                        >
                                                            Update Document
                                                        </button>
                                                        <button
                                                            data-toggle="tooltip" data-placement="bottom" title="Remove this product"
                                                            type='button'
                                                            className='btn btn-danger '
                                                            onClick={() => setSelectedDocument(document)}
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#kt_modal_1'
                                                        >
                                                            Remove Document
                                                        </button>
                                                    </div>
                                                </div>
                                            )))}
                                    </div>
                                </div>
                            </Form>
                            <div className='row'>
                                <div className='col-lg-4'></div>
                                <div className=' col-lg-8'>
                                    <div className='card mb-5 mb-xl-10 mt-3 border-primary'>
                                        <div className='card-header'>
                                            <div className='card-title m-0'>
                                                <h3 className='fw-bolder m-0'>Add Document</h3>
                                            </div>
                                        </div>
                                        <Form ref={formRef} className='card-body'>
                                            <div >
                                                <div className='form-group'>
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                                                            <span>Vendor</span>
                                                        </label>
                                                        <div className='col-lg-8 fv-row'>
                                                            <select
                                                                className='form-select form-select-lg form-select-solid'
                                                                placeholder='Title'
                                                                value={documentVendor}
                                                                onChange={(e) => {
                                                                    setDocumentVendor(e.target.value)
                                                                    if (!e.target.value) {
                                                                        setDocumentVendorValidation("Vendor is required")
                                                                    } else {
                                                                        setDocumentVendorValidation("")
                                                                    }
                                                                }}
                                                            >
                                                                <option value=''>Select a Vendor...</option>
                                                                {Array.isArray(documentVendorArray) && documentVendorArray.length > 0 ? (
                                                                    documentVendorArray.map((vendor) => (
                                                                        <option key={vendor.id} value={vendor.id}>
                                                                            {vendor.supplier_name ? vendor.supplier_name : vendor.name}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value='' disabled>No Vendors Available</option>
                                                                )}
                                                            </select>
                                                            <>
                                                                {documentVendorValidation && (
                                                                    <div className='text-danger'>{documentVendorValidation}</div>
                                                                )}
                                                            </>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                            <span className='required'>Title</span>
                                                        </label>
                                                        <div className='col-lg-8 fv-row'>
                                                            <input
                                                                type='text'
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Title'
                                                                value={documentTitleValue}
                                                                onChange={(e) => {
                                                                    setDocumentTitleValue(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                            Description
                                                        </label>
                                                        <div className='col-lg-8 fv-row'>
                                                            <input
                                                                type='text'
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Description'
                                                                value={documentDescriptionValue}
                                                                onChange={(e) => {
                                                                    setDocumentDescriptionValue(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                            File
                                                        </label>
                                                        <div className='col-lg-8 fv-row'>
                                                            <input
                                                                type='file'
                                                                className='form-control form-control-lg form-control-solid'
                                                                onChange={(e) => {
                                                                    HandleImageChange(e)
                                                                    handleFileValidation()
                                                                }}
                                                                name='picture'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                            {!fileValidationError || !documentVendor ? (
                                                <button data-toggle="tooltip" data-placement="bottom" title="Add Product" type='button' className='btn btn-primary disabled'>
                                                    Add Document
                                                </button>
                                            ) : (
                                                <button
                                                    data-toggle="tooltip" data-placement="bottom" title="Add Document"
                                                    type='button'
                                                    className='btn btn-primary'
                                                    onClick={handleAddDocument}
                                                    disabled={documentLoading}
                                                >
                                                    {!documentLoading ? 'Add Document' : 'Please wait...'}
                                                    {documentLoading && (
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {requisitionValidationError || titleValidationError || dateValidationError || productValidationError.some(error => error) || quantityValidationError.some(error => error) || vendorValidationError.some(error => error) || priceValidationError.some(error => error) ? (
                        <button type='button' className='btn btn-primary disabled'>
                            Update Quotation
                        </button>
                    ) : (
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Update Quotation"
                            type='button'
                            className='btn btn-primary'
                            onClick={handleUpdateQuotation}
                            disabled={loading}
                        >
                            {!loading && 'Update Quotation'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    )}
                </div>
            </div>
            {/* delete modal */}
            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Document Confirmation</h5>

                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            {selectedDocument ? (
                                <>
                                    <p className='text-danger'>Note: The document will be deleted permanently!</p>
                                    <p className="mb-2">
                                        <span className="fw-bold">Document Title: </span> {selectedDocument.document_title}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold">Desciption </span> {selectedDocument.document_description}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className="modal-footer">
                            <button data-toggle="tooltip" data-placement="bottom" title="close" type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Document"
                                type="button"
                                onClick={() => {
                                    if (selectedDocument && selectedDocument.id) {
                                        handleDeleteDocument.mutate(selectedDocument.id);
                                    }
                                }}
                                className="btn btn-danger"
                                data-bs-dismiss="modal"
                            >
                                Delete Document
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* edit modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Document</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedDocument ? (
                                <>
                                    <div className='mb-2 row mb-6'>
                                        <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>
                                            Vendor
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <select
                                                className='form-select form-select-lg form-select-solid'
                                                placeholder='Title'
                                                value={selectedDocument.vendor_id}
                                                onChange={(e) => {
                                                    setSelectedDocument({
                                                        ...selectedDocument,
                                                        vendor_id: Number(e.target.value),
                                                    })
                                                }}>
                                                <option value=''>Select a Vendor...</option>
                                                {Array.isArray(documentVendorArray) && documentVendorArray.length > 0 ? (
                                                    documentVendorArray.map((vendor) => (
                                                        <option key={vendor.id} value={vendor.id}>
                                                            {vendor.supplier_name}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value='' disabled>No Vendors Available</option>
                                                )}
                                            </select>
                                            <>
                                                {documentVendorValidation && (
                                                    <div className='text-danger'>{documentVendorValidation}</div>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                    <div className='mb-2 row mb-6'>
                                        <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>
                                            Title
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                value={selectedDocument.document_title}
                                                className='form-control form-control-lg form-control-solid'
                                                onChange={(e) => {
                                                    setSelectedDocument({
                                                        ...selectedDocument,
                                                        document_title: e.target.value,
                                                    })
                                                }}
                                                type='text'
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-2 row mb-6'>
                                        <label className='col-lg-4 ml-4 col-form-label fw-bold fs-6'>
                                            Description
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                value={selectedDocument.document_description}
                                                className='form-control form-control-lg form-control-solid'
                                                onChange={(e) => {
                                                    setSelectedDocument({
                                                        ...selectedDocument,
                                                        document_description: e.target.value,
                                                    })
                                                }}
                                                type='text'
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Update this Docuument"
                                type="button"
                                onClick={handleUpdateDocument}
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                            >
                                Update Document
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EditQuotation
