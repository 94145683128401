import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon } from '../../../../_metronic/helpers';
import { KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { change_active_status_product, delete_product, get_all_filters_status_products, get_all_products, get_filtered_products } from '../api/productsApi';
import { mediaPath } from '../../../helper-functions/ImageStoragePath';
import { formatDateWithTime } from '../../../helper-functions/DateFunction';
import { Form } from 'react-bootstrap';
import { get_all_categories } from '../../categories/api/categoriesApi';
import { get_all_subcategories } from '../../subcategories/api/subcategoriesApi';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';
const imagePath = `${mediaPath()}/rejected.png`;
type Product = {
  id: number;
  name: string | undefined;
  price: number | undefined;
  categories_id: number | undefined;
  category_title: string | undefined;
  sub_categories_id: number | undefined;
  sub_category_title: string | undefined;
  short_description: string | undefined;
  long_description: string | undefined;
  picture: string | undefined;
  remaining_stock_quantity: number | undefined;
  status: number | undefined;
  updated_by_name: string | undefined;
  updated_at: string;
};
interface Category {
  id: number;
  title: string;
  categories_id: number;
  category_title: string;
};
interface Subcategory {
  id: number;
  title: string;
  categories_id: number;
  categories_title: string;
  sub_categories_id: number;
  sub_category_title: string;
};

var product_add, product_edit, product_view, product_logs, product_delete
function ProductsList() {
  const [productList, setProductList] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [filteredSubcategory, setFilteredSubcategory] = useState<number | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>();
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleData, setVisibleData] = useState<Product[]>([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  product_add = permissionCheck('product-create')
  product_edit = permissionCheck('product-update')
  product_view = permissionCheck('product-view')
  product_logs = permissionCheck('product-activity-log')
  product_delete = permissionCheck('product-delete')
  const {
    data: productsData,
    isLoading: isProductsLoading,
    isError: isProductsError,
  } = useQuery('products', get_all_products, {refetchOnWindowFocus: false});
  const navigate = useNavigate();
  useEffect(() => {
    if (productsData) {
      setProductList(productsData.data)
    }
  }, [productsData]);
  const { data: filterData, refetch: filterRefetch } = useQuery(
    'filterData',
    () => get_filtered_products(selectedCategoryId, filteredSubcategory, selectedStatus),
    {
      refetchOnWindowFocus: false,
      onSuccess: (filteredData) => {
        setProductList(filteredData.data);
      },
      onError: (error: any) => {
        // Optionally, you can display a toast or any other error handling mechanism
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        };
      },
      enabled: false,
    }
  );
  const handleApplyFilters = () => {
    // Manually refetch data when applying filters
    filterRefetch();
  };
  const handleClearFilters = () => {
    setSelectedCategoryId(null);
    setFilteredSubcategory(null);
    setSelectedStatus("");
    if (productsData) {
      setProductList(productsData.data);
    }
  }
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems = productList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    };
  };
  useEffect(() => {
    if (productList.length > 0) {
      let sortedData = [...productList];
      if (sortBy) {
        sortedData?.sort((a, b) => {
          const key = sortBy.key || ''; // Fallback value for sortBy.key
          // Adjust sorting logic based on the data type of the column
          if (sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else if (key === 'price') {
            const aValue = a[key] || 0;
            const bValue = b[key] || 0;
            return sortBy.ascending ? aValue - bValue : bValue - aValue;
          }
            else if (key === 'remaining_stock_quantity') {
              const aValue = a[key] || 0;
              const bValue = b[key] || 0;
              return sortBy.ascending ? aValue - bValue : bValue - aValue;
            
          } else {
            const aValue = (a[key] || '').toString().toLowerCase();
            const bValue = (b[key] || '').toString().toLowerCase();
            return sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage,
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [productList, currentPage, selectedItemsPerPage, sortBy]);
  const ref = useRef<HTMLInputElement>(null);
  const { data: categoryData } = useQuery('categories', get_all_categories, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (categoryData) {
      setCategories(categoryData.data as Category[]);
    };
  }, [categoryData]);
  const { data: SubcategoryData } = useQuery('Subcategories', get_all_subcategories, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (SubcategoryData && SubcategoryData.data && Array.isArray(SubcategoryData.data)) {
      const filteredSubs = SubcategoryData.data.filter(subcategory => subcategory.categories_id === selectedCategoryId);
      setSubcategories(filteredSubs);
    }
  }, [SubcategoryData, selectedCategoryId]);
  const handleDeleteProduct = useMutation((id: number) => {
    return delete_product(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
      })
      .then(() => {
        return get_all_products().then((data) => {
          setProductList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value;
    setSearchInput(searchTerm);
    const filteredItems = productsData.data.filter((product) => {
      return (
        product.name?.toLowerCase().includes(searchTerm) ||
        product.category_title?.toLowerCase().includes(searchTerm) ||
        product.sub_category_title?.toLowerCase().includes(searchTerm) ||
        product.price?.toString().includes(searchTerm)
      )
    });
    setProductList(filteredItems);
  };
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_product(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        return get_all_products().then((data) => {
          setProductList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleSort = (key: string) => {
    if (key === 'status') {
      // Sort status column in ascending order first when clicked
      setSortBy({ key, ascending: !sortBy?.ascending });
    }  else if (!sortBy || sortBy.key !== key || sortBy.ascending) {
           setSortBy({ key, ascending: false }); // Sort in descending order initially
         }
    else if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    } else if (!sortBy || sortBy.key !== key && key === "price") {
      setSortBy({ key, ascending: true });
    } else if (!sortBy || sortBy.key !== key && key === "status") {
      setSortBy({ key, ascending: true });
    }
    else if (key === "price" || key === "remaining_stock_quantity") {
      setSortBy({ key, ascending: !sortBy.ascending });
    } else {
      setSortBy(null);
    }
  };  
  const handleProductLogs = (product: any) => {
    navigate("/products/product-logs", { state: { id: product.id } });
  };
  const handleEditProduct = (product: any) => {
    navigate("/products/edit-product", { state: { product: product } });
  };
  const handleViewProduct = (product: any) => {
    navigate("/products/view-product", { state: { id: product.id } });
  };
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>List of Products</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {product_add && (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Navigate to Add Product"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/products/add-product')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add Product
            </button>
          )}
        </div>
      </div>
      {/*Search And Filter*/}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            data-toggle="tooltip" data-placement="bottom" title="Search a Product"
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search Product'
            value={searchInput}
            onChange={handleInputChange}
          />
        </h3>
        <div
          className='card-toolbar'
        >
          <h5 className='p-3'>Filter by:</h5>
          <div className='mx-3'>
            <Form.Group controlId='category'>
              <Form.Select
                value={selectedCategoryId || ''}
                className='form-select form-select-solid form-select-lg fw-bold'
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setSelectedCategoryId(Number(e.target.value));
                  setFilteredSubcategory(null);
                }}
              >
                <option value=''>Select a Category...</option>
                {Array.isArray(categories) && categories.length > 0 ? (
                  categories.map((CategoryType) => (
                    <option key={CategoryType.id} value={CategoryType.id}>
                      {CategoryType.title}
                    </option>
                  ))
                ) : (
                  <option value='' disabled>No categories available</option>
                )}
              </Form.Select>
            </Form.Group>
          </div>
          <div className='mx-3'>
            <Form.Select
              value={filteredSubcategory || ''}
              className={`form-select form-select-solid form-select-lg fw-bold`}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {setFilteredSubcategory(Number(e.target.value))}}
            >
              <option value=''>Select a SubCategory...</option>
              {Array.isArray(subcategories) && subcategories.length > 0 ? (
                subcategories?.map((subcategoryType) => (
                  <option key={subcategoryType.id} value={subcategoryType.id}>
                    {subcategoryType.title}
                  </option>
                ))
              ) : (
                <option value='' disabled>No subcategories available</option>
              )}
            </Form.Select>
          </div>
          <div className='mx-3'>
            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value=''>Filter by Status...</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
        <div>

          <button
            className='btn btn-primary me-3'
            onClick={handleApplyFilters}
          >
            Apply Filters
          </button>
          <button
            className='btn btn-light-danger'
            onClick={handleClearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* Headings of columns in table  */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-170px'>Product Picture</th>
                <th className='min-w-160px'>Name <span onClick={() => handleSort('name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Price ({getSettingValue('currency_symbol')}) <span onClick={() => handleSort('price')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Category <span onClick={() => handleSort('category_title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Sub Category <span onClick={() => handleSort('sub_category_title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-200px'>Remaining Quantity <span onClick={() => handleSort('remaining_stock_quantity')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Status <span onClick={() => handleSort('status')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Updated By <span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-200px'>Updated At <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                {product_delete || product_edit || product_logs || product_view ? (
                  <th className='min-w-100px text-center'>Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {isProductsError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isProductsLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : productList.length > 0 ? (
                visibleData.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'
                            onClick={() => handleViewProduct(product)}>
                            {product.picture ? (
                              <img
                                src={product.picture}
                                width={70}
                                alt='Product'
                                className='symbol'
                              />
                            ) : (
                              <img
                                src={imagePath}
                                width={70}
                                height={70}
                                alt='No Picture'
                                className='symbol'
                              />
                            )}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'
                            onClick={() => handleViewProduct(product)}>
                            {product.name}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {product.price}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {product.category_title}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {product.sub_category_title}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {product.remaining_stock_quantity}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>{product.status === 1 ? (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                            Active
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>
                            Inactive
                          </h3>
                        </div>
                      </div>
                    )}
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {product.updated_by_name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start cursor-pointer'>
                          {formatDateWithTime(product.updated_at)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {product_edit && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Edit Product"
                            onClick={() => handleEditProduct(product)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        )}
                        {product_view && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="See Product Details"
                            onClick={() => handleViewProduct(product)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </button>
                        )}
                        {product_add || product_edit ? (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Switch Status"
                            onClick={() => handleStatusUpdate.mutate(product.id)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='update-folder' className='fs-3' />
                          </button>
                        ) : null}
                        {product_logs && (
                          <button
                            onClick={() => {
                              handleProductLogs(product);
                            }}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-custom-class='tooltip-dark'
                            title='Product Logs'
                          >
                            <i className='bi bi-journal-text'></i>
                          </button>
                        )}
                        {product_delete && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Delete Product"
                            onClick={() => setSelectedProduct(product)}
                            className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Products available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Delete modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete Product Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedProduct ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold'>Product Name: </span> {selectedProduct.name}
                  </p>
                  <p className='mb-2'>
                    <span className='fw-bold'>Price: </span> {selectedProduct.price}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Delete this Product"
                type='button'
                onClick={() => {
                  if (selectedProduct && selectedProduct.id) {
                    handleDeleteProduct.mutate(selectedProduct.id)
                  }
                }}
                className='btn btn-danger'
                data-bs-dismiss='modal'
              >
                Delete Product
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
        <div className='align-items-center row'>
          <div className='d-flex'>
            <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
            <select
              style={{ width: '60px' }}
              className='form-select form-select-md px-2 col-md-1'
              value={selectedItemsPerPage}
              onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
            >
              {itemsPerPageOptions.map(option => (
                <option key={option} value={option} className='p-3 '>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {currentPage === 1 ? (
            <button
              className='btn btn-light'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          ) : (
            <button
              className='btn btn-primary'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          <button
            className='btn btn-primary ms-2'
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
export default ProductsList;
