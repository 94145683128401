import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { add_requisitoin_document, delete_requisition_document, get_all_requisition_documents, update_requisition_document } from '../api/requisitionsApi';
import { formatDateWithTime } from '../../../../helper-functions/DateFunction';
import { KTIcon, KTSVG } from '../../../../../_metronic/helpers';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { mediaPath } from '../../../../helper-functions/ImageStoragePath';
const imagePng = `${mediaPath()}/png.png`;
const imagePdf = `${mediaPath()}/pdf.png`;
const imageJpg = `${mediaPath()}/jpg-file.png`;
type RequisitionDocument = {
  id: number;
  document_title: string | undefined;
  document_description: string | undefined;
  document_path: string | undefined;
  updated_by_name: string | undefined;
  updated_at: string;
};
function RequisitionDocuments() {
  const [documentList, setDocumentList] = useState<RequisitionDocument[]>([]);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [fileValue, setFileValue] = useState<File | null>(null);
  const [fileValidationError, setFileValidationError] = useState('');
  const [selectedDocument, setSelectedDocument] = useState<RequisitionDocument | null>();
  const location = useLocation();
  const formRef = useRef<HTMLFormElement>(null);
  const id = (location.state as { id: number }).id;
  const { data: requisitionDocumentId } = useQuery(['RequisitionDocumentId', id], () =>
    setSelectedId(id), {refetchOnWindowFocus: false}
  );
  const { data: requisitionDocumentData, isLoading: isRequisitionDocumentLoading, isError, refetch } = useQuery(
    ['requistionDocument', selectedId],
    () => get_all_requisition_documents(selectedId!),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedId,
    }
  );
  useEffect(() => {
    if (requisitionDocumentData) {
      setDocumentList(requisitionDocumentData.data as RequisitionDocument[]);
    }
  }, [requisitionDocumentData]);
  const addDocumentMutation = useMutation(add_requisitoin_document, {
    onSuccess: (response) => {
      refetch();
      setLoading(false);
      if (formRef.current) {
        formRef.current.reset();
      }
      setTitleValue('');
      setDescriptionValue('');
      setFileValue(null);
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    },
    onError: (error: any) => {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
    },
  });
  const handleAddDocument = useCallback(() => {
    setLoading(true);
    addDocumentMutation.mutate({
      document_title: titleValue,
      document_description: descriptionValue,
      document_path: fileValue,
      requisition_id: selectedId,
    });
  }, [titleValue, descriptionValue, fileValue, selectedId, addDocumentMutation]);
  const updateDocumentMutation = useMutation(update_requisition_document, {
    onSuccess: (response) => {
      refetch();
      setLoading(false);
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    },
    onError: (error: any) => {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
    },
  });
  const handleUpdateDocument = useCallback(() => {
    setLoading(true);
    if (selectedDocument) {
      const { id, document_title, document_description, document_path } = selectedDocument;
      updateDocumentMutation.mutate({
        id,
        document_title,
        document_description,
        document_path,
        requisition_id: selectedId,
      });
    };
  }, [selectedDocument, selectedId, updateDocumentMutation]);
  const handleDeleteDocument = useMutation((id: number) => {
    return delete_requisition_document(id).then((response) => {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 3000,
      });
    })
      .then(() => {
        return get_all_requisition_documents(selectedId).then((data) => {
          setDocumentList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const HandleImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFileValue(e.target.files[0]);
    };
  }, []);
  const handleFileValidation = useCallback(() => {
    if (!fileValue) {
      setFileValidationError('File is required.');
    } else {
      setFileValidationError('');
    }
  }, [fileValue]);

  const renderFileIcon = useCallback((filePath: string | undefined) => {
    if (!filePath) {
      return null;
    }
    const fileExtension = filePath.split('.').pop()?.toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return (
          <img
            src={imagePdf}
            style={{ width: '60px', height: "auto" }}
            alt='document-image'
            className='document-image'
          />
        );
      case 'png':
        return (
          <img
            src={imagePng}
            style={{ width: '60px', height: "auto" }}
            alt='document-image'
          />
        );
      case 'jpeg':
      case 'jpg':
        return (
          <img
          src={imageJpg}
          style={{ width: '60px', height: "auto" }}
          alt='document-image'
          className='document-image'
        />
        );
      default:
        return (
          <i className='bi bi-file fs-1'></i>
        );
    }
  }, []);
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
        >
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Requisition Documents</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <button
              data-toggle="tooltip" data-placement="bottom" title="Add Document"
              className='btn btn-sm btn-light-dark fs-5'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_2'
              onClick={()=> {
                setDescriptionValue("")
                setTitleValue("")
                if (formRef.current) {
                  formRef.current.reset();
                }
              }}
            >
              Add Document
            </button>
          </div>
        </div>
        <div>
          <div className='card-body border-top p-9'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>File</th>
                    <th className='min-w-150px'>Document Title</th>
                    <th className='min-w-150px'>Description</th>
                    <th className='min-w-140px'>Updated By</th>
                    <th className='min-w-120px'>Updated At</th>
                    <th className='min-w-100px text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isError ? (
                    <tr>
                      <td>Error loading data. Please try again later.</td>
                    </tr>
                  ) : isRequisitionDocumentLoading ? (
                    <tr>
                      <td colSpan={6}>
                        <div className='text-center'>
                          <div className='spinner-border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : documentList.length > 0 ? (
                    documentList.map((document) => (
                      <tr key={document.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <h3 className='text-dark fw-bold cursor-pointer text-hover-success'>
                                <a
                                  href={document.document_path}
                                  download={document.document_title}
                                >
                                  {renderFileIcon(document.document_path)}
                                </a>
                              </h3>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                                {document.document_title}
                              </h3>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                                {document.document_description}
                              </h3>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2 cursor-pointer'>
                              {document.updated_by_name}
                            </div>
                          </div>
                        </td>
                        <td className='text-end'>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2 cursor-pointer'>
                              {formatDateWithTime(document.updated_at)}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                              data-toggle="tooltip" data-placement="bottom" title="Download Document"
                              href={document.document_path}
                              download={document.document_title}
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            >
                              <KTIcon iconName='file-down' className='fs-1' />
                            </a>
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Edit Document"
                              onClick={() => setSelectedDocument(document)}
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_3'
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </button>
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Delete Document"
                              onClick={() => setSelectedDocument(document)}
                              className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_1'
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No Documents available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* add document modal  */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Document</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <Form className='card-body' ref={formRef}>
                <div >
                  <div className='form-group'>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>Title</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Title'
                          value={titleValue}
                          onChange={(e) => {
                            setTitleValue(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        Description
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Description'
                          value={descriptionValue}
                          onChange={(e) => {
                            setDescriptionValue(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        File
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='file'
                          className='form-control form-control-lg form-control-solid'
                          onChange={(e) => {
                            HandleImageChange(e)
                            handleFileValidation()
                          }}
                          name='picture'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <div className='modal-footer'>
        <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
          Close
        </button>
        <button
          data-toggle="tooltip" data-placement="bottom" title="Add Document"
          data-bs-dismiss='modal'
          type='button'
          className='btn btn-primary'
          onClick={handleAddDocument}
          disabled={!fileValue||!titleValue}
        >
          {!loading ? 'Add Document' : 'Please wait...'}
          {loading && (
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          )}
        </button>
      </div>
          </div>
        </div>
      </div>
      {/* delete modal */}
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Document Confirmation</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              {selectedDocument ? (
                <>
                  <p className="mb-2">
                    <span className="fw-bold">Document Title: </span> {selectedDocument.document_title}
                  </p>
                  <p className="mb-2">
                    <span className="fw-bold">Desciption </span> {selectedDocument.document_description}
                  </p>
                </>
              ) : null}
            </div>
            <div className="modal-footer">
              <button data-toggle="tooltip" data-placement="bottom" title="close" type="button" className="btn btn-light" data-bs-dismiss="modal">
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Delete this Document"
                type="button"
                onClick={() => {
                  if (selectedDocument && selectedDocument.id) {
                    handleDeleteDocument.mutate(selectedDocument.id);
                  }
                }}
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Delete Document
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* edit modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Document</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedDocument ? (
                <>
                  <div className='mb-2 row mb-6'>
                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>
                      Category Title
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        value={selectedDocument.document_title}
                        className='form-control form-control-lg form-control-solid'
                        onChange={(e) => {
                          setSelectedDocument({
                            ...selectedDocument,
                            document_title: e.target.value,
                          })
                        }}
                        type='text'
                      />
                    </div>
                  </div>
                  <div className='mb-2 row mb-6'>
                    <label className='col-lg-4 ml-4 col-form-label fw-bold fs-6'>
                      Description
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        value={selectedDocument.document_description}
                        className='form-control form-control-lg form-control-solid'
                        onChange={(e) => {
                          setSelectedDocument({
                            ...selectedDocument,
                            document_description: e.target.value,
                          })
                        }}
                        type='text'
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Update this Docuument"
                type="button"
                onClick={handleUpdateDocument}
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Update Document
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default RequisitionDocuments;