import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon, KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { formatDateWithTime } from '../../../helper-functions/DateFunction';
import { addDepartmentList } from '../../../modules/accounts/components/settings/SettingsModel';
import { change_active_status_department, delete_department, get_all_department, get_all_filters_status_departments, update_department } from '../api/departmentsApi';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';

var department_add, department_edit, department_logs, department_delete
function DepartmentsList() {
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleData, setVisibleData] = useState<addDepartmentList[]>([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [departmentList, setDepartmentList] = useState<addDepartmentList[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<addDepartmentList | null>(null);
  const [nameValidationError, setNameValidationError] = useState<string | null>(null);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const navigate = useNavigate();
  department_add = permissionCheck('department-create')
  department_edit = permissionCheck('department-update')
  department_logs = permissionCheck('department-activity-log')
  department_delete = permissionCheck('department-delete')
  const {
    data: allDepartments,
    isLoading: isAllDepartments,
    isError: isAllDepartmentsError,
  } = useQuery('departments', get_all_department, { refetchOnWindowFocus: false });
  useEffect(() => {
    if (allDepartments) {
      setDepartmentList(allDepartments.data)
    }
  }, [allDepartments])
  useEffect(() => {
    const fetchData = async () => {
      try {
        let filteredData;
        if (selectedStatus) {
          filteredData = await get_all_filters_status_departments(selectedStatus);
          setDepartmentList(filteredData.data);
        }
        else {
          setDepartmentList(allDepartments.data);
        }
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      }
    };
    fetchData();
  }, [selectedStatus]);
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems = departmentList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  useEffect(() => {
    if (departmentList.length > 0) {
      let sortedData = [...departmentList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          if (sortBy && sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else {
            const aValue = (a[sortBy?.key] || '') as string;
            const bValue = (b[sortBy?.key] || '') as string;
            return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage, // Update slice based on selected items per page
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [departmentList, currentPage, selectedItemsPerPage, sortBy]);
  // Search Department
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value;
    setSearchInput(searchTerm);
    const filteredItems = allDepartments.data.filter((department) => {
      return (
        department.name?.toLowerCase().includes(searchTerm) ||
        department.description?.toLowerCase().includes(searchTerm) ||
        department.created_at?.toLowerCase().includes(searchTerm) ||
        department.updated_at?.toString().includes(searchTerm)
      )
    });
    setDepartmentList(filteredItems);
  };
  // Delete Department
  const handleDeleteDepartment = useMutation((id: number) => {
    return delete_department(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
      })
      .then(() => {
        return get_all_department().then((data) => {
          setDepartmentList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  // Update Department
  const handleUpdateDepartment = useMutation((updatedDepartment: { id: number; name: string, description: string, status: number }) => {
    if (updatedDepartment.name.trim() === '') {
      return Promise.reject(new Error('Department Name is Required.'))
    }
    return update_department(updatedDepartment.id, updatedDepartment.name, updatedDepartment.description, updatedDepartment.status)
      .then((data) => {
        toast.success(data.message, {
          position: 'top-right',
          autoClose: 3000,
        })
        get_all_department()
          .then((data) => {
            setDepartmentList(data.data)
          })
          .catch((error) => {
            if (error.response) {
              const errorMessage = error.response.data.message;
              toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 2000,
              })
            }
          })
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      })
  })
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_department(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        return get_all_department().then((data) => {
          setDepartmentList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    } else if (key === "updated_at" || key === "status") {
      // Toggle between ascending and descending order
      setSortBy({ key, ascending: !sortBy.ascending });
    } else if (key === "updated_by_name") {
      setSortBy({ key, ascending: true }); // Sort updated_by_name in ascending order
    } else {
      setSortBy(null); // Revert to unsorted state
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleDepartmentLogs = (department: any) => {
    navigate("/departments/department-logs", { state: { id: department.id } });
  };
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>List of Departments</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {department_add && (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Add Department"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/departments/add-department')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add Department
            </button>

          )}
        </div>
      </div>
      <div className='card-header border-0 pt-5 '>
        {/* begin::Search */}
        <h3 className='card-title align-items-start flex-column'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            data-toggle="tooltip" data-placement="bottom" title="Search a Department"
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search Department'
            value={searchInput}
            onChange={handleInputChange}
          />
        </h3>
        {/* end::Search */}

        {/* Filter */}
        <div className='card-toolbar'>
            <h5 className='p-3'>Filter by:</h5>
          <div>
            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value=''>Filter by Status...</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* Headings of columns in table  */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Department Name <span onClick={() => handleSort('name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-140px'>Description</th>
                <th className='min-w-140px'>No. of Users</th>
                <th className='min-w-140px'>Status <span onClick={() => handleSort('status')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Updated By <span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-100px'>Updated At <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                {department_delete || department_edit || department_logs ? (
                  <th className='min-w-100px text-center'>Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {isAllDepartmentsError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isAllDepartments ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : departmentList.length > 0 ? (
                visibleData.map((department) => (
                  <tr key={department.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {department.name}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {department.description ? department.description : "No Description"}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {department.user_count}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>{department.status === 1 ? (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                            Active
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>Inactive</h3>
                        </div>
                      </div>
                    )}
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start cursor-pointer'>
                          {department.updated_by_name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start cursor-pointer'>
                          {formatDateWithTime(department.updated_at)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {department_edit && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Edit Department"
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_2'
                            onClick={() => {
                              setSelectedDepartment(department)
                              setNameValidationError("")
                            }}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        )}
                        {department_add || department_edit ? (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Switch Status"
                            onClick={() => handleStatusUpdate.mutate(department.id)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='update-folder' className='fs-3' />
                          </button>
                        ) : null}
                        {department_logs && (
                          <button
                            onClick={() => {
                              handleDepartmentLogs(department);
                            }}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-custom-class='tooltip-dark'
                            title='Department Log'
                          >
                            <i className='bi bi-journal-text'></i>
                          </button>
                        )}
                        {department_delete && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Delete Department"
                            onClick={() => setSelectedDepartment(department)}
                            className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Departments Available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Department</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedDepartment ? (
                <>
                  <div className='mb-2 row mb-6'>
                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>
                      Department Name
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        value={selectedDepartment.name}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Department Name'
                        onChange={(e) => {
                          if (!e.target.value) {
                            setNameValidationError('Department Name is Required.')
                          } else {
                            setNameValidationError('')
                          }
                          setSelectedDepartment({
                            ...selectedDepartment,
                            name: e.target.value,
                          })
                        }}
                        type='text'
                      />
                      {nameValidationError && (
                        <div className='text-danger'>{nameValidationError}</div>
                      )}
                    </div>
                  </div>
                  <div className='mb-2 row mb-6'>
                    <label className='col-lg-4 ml-4 col-form-label fw-bold fs-6'>
                      Description
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <textarea
                        value={selectedDepartment.description}
                        placeholder='Description'
                        className='form-control form-control-lg form-control-solid'
                        onChange={(e) => {
                          setSelectedDepartment({
                            ...selectedDepartment,
                            description: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className='mb-2 row mb-6'>
                    <label className='col-lg-4 ml-4 col-form-label fw-bold fs-6'>
                      Status
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        value={selectedDepartment.status}
                        placeholder='Status'
                        className='form-control form-control-lg form-control-solid'
                        onChange={(e) => {
                          setSelectedDepartment({
                            ...selectedDepartment,
                            status: Number(e.target.value),
                          })
                        }}
                      >
                        <option value=''>Select a Status...</option>
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                      </select>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              {selectedDepartment?.name === '' ? (
                <>
                  <button type='button' className='btn btn-light-dark' disabled={true}>
                    Update Department
                  </button>
                </>
              ) : (
                <>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => {
                      if (selectedDepartment && selectedDepartment.name) {
                        handleUpdateDepartment.mutateAsync({
                          id: selectedDepartment.id,
                          name: selectedDepartment.name,
                          description: selectedDepartment.description,
                          status: selectedDepartment.status,
                        })
                      }
                    }}
                    data-bs-dismiss='modal'
                  >
                    Update Department
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Delete modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete Department Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedDepartment ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold'>Department Name: </span> {selectedDepartment.name}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Delete this Department"
                type='button'
                onClick={() => {
                  if (selectedDepartment && selectedDepartment.id) {
                    handleDeleteDepartment.mutate(selectedDepartment.id)
                  }
                }}
                className='btn btn-danger'
                data-bs-dismiss='modal'
              >
                Delete Department
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
        <div className='align-items-center row'>
          <div className='d-flex'>
            <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
            <select
              style={{ width: '60px' }}
              className='form-select form-select-md px-2 col-md-1'
              value={selectedItemsPerPage}
              onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
            >
              {itemsPerPageOptions.map(option => (
                <option key={option} value={option} className='p-3 '>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {currentPage === 1 ? (
            <button
              className='btn btn-light'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          ) : (
            <button
              className='btn btn-primary'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          <button
            className='btn btn-primary ms-2'
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
export default DepartmentsList;