import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { get_single_stock } from '../api/stocksApi';
import { useQuery } from 'react-query';
import { get_all_products } from '../../products/api/productsApi';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';

interface Product {
  id: number;
  name: string;
};
function ViewStockIn() {
  const [singleStock, setSingleStock] = useState<any | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const location = useLocation();
  const id = (location.state as { id: number }).id;
  const { data, isLoading, error } = useQuery(['ViewStockIn', id], () =>
    get_single_stock(id), {refetchOnWindowFocus: false}
  );
  useEffect(() => {
    if (data) {
      setSingleStock(data.data);
    }
  }, [data]);
  const { data: productData } = useQuery('products', get_all_products, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (productData) {
      setProducts(productData.data as Product[]);
    }
  }, [productData]);
  if (isLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className='text-center'>
        <h2>Error in Get All Stocks</h2>
      </div>
    );
  }
  const getProductNameById = (productId) => {
    const product = products.find((p) => p.id == productId);
    return product ? product.name : 'Product not found';
  };
  // Calculate the grand total based on final_price of each product
  const grandTotal = singleStock && singleStock.stock_products
    ? singleStock.stock_products.reduce((total, product) => {
        return total + parseFloat(product.final_price);
      }, 0)
    : 0;
  return (
    <div className='card card-custom shadow'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Stock in View</h3>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <div className="card-body">
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Date</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <input
              readOnly
                value={singleStock?.date_time}
                className='form-control form-control-solid'
              />
            </div>
          </div>
          <div className='row mb-4'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Description</label>
            <div className='col-lg-8 fv-row'>
              <textarea
                className='form-control form-control-lg form-control-solid'
                readOnly
                value={singleStock?.description}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Vendor(Optional)</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <input
                 readOnly
                value={singleStock?.vendor_name}
                className='form-control form-control-solid'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-body py-3'>
        {singleStock && singleStock.stock_products && singleStock.stock_products.length > 0 && (
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-100px required'>Product</th>
                  <th className='min-w-100px required'>Quantity</th>
                  <th className='min-w-140px'>Rate ({getSettingValue('currency_symbol')})</th>
                  <th className='min-w-120px'>Price ({getSettingValue('currency_symbol')})</th>
                  <th className='min-w-150px'>Final Price ({getSettingValue('currency_symbol')})</th>
                </tr>
              </thead>
              <tbody>
                {singleStock.stock_products.map((product: any) => (
                  <tr key={product.id}>
                    <td>
                      <input className='card' value={getProductNameById(product.product_id)} readOnly />
                    </td>
                    <td>
                      <input className='card' value={product.quantity} readOnly />
                    </td>
                    <td>
                      <input className='card' value={product.rate} readOnly />
                    </td>
                    <td>
                      <input className='card' value={product.price} readOnly />
                    </td>
                    <td>
                      <input className='card' value={product.final_price} readOnly />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className='fw-bold text-muted'>
                  <td colSpan={4}></td>
                  <td colSpan={2}>Grand Total ({getSettingValue('currency_symbol')}):
                    <input
                      className='card'
                      type="number"
                      readOnly
                      value={grandTotal.toFixed(2)}
                    />
                  </td>
                  <td className='min-w-140px'></td>
                  <td colSpan={2}></td>
                  <td colSpan={1}></td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}

      </div>
    </div>
    
  )
}

export default ViewStockIn;