import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { KTIcon, KTSVG } from '../../../../_metronic/helpers'
import { change_active_status_category, delete_category, get_all_categories, get_all_filters_status_categories, update_category } from '../api/categoriesApi'
import { Alert, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { formatDateWithTime } from '../../../helper-functions/DateFunction'
import { permissionCheck } from '../../../helper-functions/PermissionCheck'
type Category = {
  id: number;
  title: string | undefined;
  status: number;
  updated_by_name: string | undefined;
  updated_at: string;
};
var category_add, category_edit, category_logs, category_delete
function CategoriesList() {
  const [categoryList, setCategoryList] = useState<Category[]>([])
  const [nameValidationError, setNameValidationError] = useState<string | null>(null)
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const [show, setShow] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const [visibleData, setVisibleData] = useState<Category[]>([]);
  category_add = permissionCheck('category-create')
  category_edit = permissionCheck('category-update')
  category_logs = permissionCheck('category-activity-log')
  category_delete = permissionCheck('category-delete')
  const { data, isLoading, isError } = useQuery('categories', get_all_categories, {refetchOnWindowFocus: false})
  useEffect(() => {
    if(data){
      setCategoryList(data.data)
    }
  }, [data])
  useEffect(() => {
    const fetchData = async () => {
      try {
        let filteredData;
        if (selectedStatus) {
          filteredData = await get_all_filters_status_categories(selectedStatus);
          setCategoryList(filteredData.data);
        }
        else {          
        setCategoryList(data.data);
        }
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      }
    };
    fetchData();
  }, [selectedStatus]);
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems = categoryList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    };
  };
  useEffect(() => {
    if (categoryList.length > 0) {
      let sortedData = [...categoryList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          if (sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else {
            const aValue = (a[sortBy.key] || '') as string;
            const bValue = (b[sortBy.key] || '') as string;
            return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage,
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [categoryList, currentPage, selectedItemsPerPage, sortBy]);
  const resetValidationErrors = () => {
    setNameValidationError('');
  }
  const handleDeleteCategory = useMutation((id: number) => {
    return delete_category(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        })
        get_all_categories()
          .then((data) => {
            setCategoryList(data.data)
          })
          .catch((error) => {
            toast.error('List ', {
              position: 'top-right',
              autoClose: 3000,
            })
          })
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.message
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      })
  })
  const handleUpdateCategory = useMutation((updatedCategoryData: { id: number; title: string; status: number }) => {
    if (updatedCategoryData.title.trim() === '') {
      return Promise.reject(new Error('Category name is required.'))
    }
    return update_category(updatedCategoryData.id, updatedCategoryData.title, updatedCategoryData.status)
      .then((data) => {
        toast.success(data.message, {
          position: 'top-right',
          autoClose: 3000,
        })
        get_all_categories()
          .then((data) => {
            setCategoryList(data.data)
          })
          .catch((error) => {
            toast.error('List Not Updated!', {
              position: 'top-right',
              autoClose: 3000,
            })
          })
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 3000,
        })
      })
  })
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value
    setSearchInput(searchTerm)
    const filteredItems = data.data.filter((category) => {
      return category.title?.toLowerCase().includes(searchTerm.toLowerCase())
    })

    setCategoryList(filteredItems)
  }
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_category(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        return get_all_categories().then((data) => {
          setCategoryList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleCategoryLogs = (category: any) => {
    navigate("/categories/category-logs", { state: { id: category.id } });
  };
  const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    } else {
      // Toggle between ascending and descending order
      setSortBy({ key, ascending: !sortBy.ascending });
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  if (isLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }
  return (
    <>
      {errorMessage && show && (
        <Alert variant='danger' onClose={() => setShow(false)} dismissible>
          {errorMessage}
        </Alert>
      )}
      <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>List of Categories</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            {category_add && (
              <button
                data-toggle="tooltip" data-placement="bottom" title="Navigate to Add New Category"
                className='btn btn-sm btn-light-dark'
                onClick={() => navigate('/categories/add-category')}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add Category
              </button>
            )}
          </div>
        </div>
        {/*Search And Filter*/}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              data-toggle="tooltip" data-placement="bottom" title="Search a Category"
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Category'
              value={searchInput}
              onChange={handleInputChange}
            />
          </h3>
          <div
            className='card-toolbar'
          >
            <h5 className='p-3'>Filter by:</h5>
            <div>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value=''>Filter by Status...</option>
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </select>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Category Title<span onClick={() => handleSort('title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  <th className='min-w-150px'>Status <span onClick={() => handleSort('status')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  <th className='min-w-140px'>Updated By <span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  <th className='min-w-120px'>Updated At <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                  {category_delete || category_logs || category_edit ? (
                    <th className='min-w-100px text-center'>Actions</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {isError ? (
                  <tr>
                    <td>Error loading data. Please try again later.</td>
                  </tr>
                ) : isLoading ? (
                  <tr>
                    <td colSpan={6}>
                      <div className='text-center'>
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : categoryList && categoryList.length > 0 ? (
                  visibleData.map((category) => (
                    <tr key={category.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                              {category.title}
                            </h3>
                          </div>
                        </div>
                      </td>
                      <td>{category.status === 1 ? (
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                              Active
                            </h3>
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>
                              Inactive
                            </h3>
                          </div>
                        </div>
                      )}
                      </td>
                      <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2 cursor-pointer'>
                            {category.updated_by_name}
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2 cursor-pointer'>
                            {formatDateWithTime(category.updated_at)}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          {category_edit && (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Edit Category"
                              onClick={() => {
                                setSelectedCategory({ ...category });
                                resetValidationErrors();
                              }}
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_2'
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </button>
                          )}
                          {category_add || category_edit ? (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Switch Status"
                              onClick={() => handleStatusUpdate.mutate(category.id)}
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            >
                              <KTIcon iconName='update-folder' className='fs-3' />
                            </button>
                          ) : null}
                          {category_logs && (
                            <button
                              onClick={() => {
                                handleCategoryLogs(category)
                              }}
                              className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-custom-class='tooltip-dark'
                              title='Category Logs'
                            >
                              <i className='bi bi-journal-text'></i>
                            </button>
                          )}
                          {category_delete && (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Delete Category"
                              onClick={() => setSelectedCategory({ ...category })}
                              className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_1'
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No Categories available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-between py-4'>
          <div className='align-items-center row'>
            <div className='d-flex'>
              <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
              <select
                style={{ width: '60px' }}
                className='form-select form-select-md px-2 col-md-1'
                value={selectedItemsPerPage}
                onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
              >
                {itemsPerPageOptions.map(option => (
                  <option key={option} value={option} className='p-3 '>{option}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            {currentPage === 1 ? (
              <button
                className='btn btn-light'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            ) : (
              <button
                className='btn btn-primary'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            )}
            <button
              className='btn btn-primary ms-2'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete Category Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedCategory ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold'>Category Name:</span> {selectedCategory.title}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  if (selectedCategory) {
                    handleDeleteCategory.mutate(selectedCategory.id)
                  }
                }}
                data-bs-dismiss='modal'
              >
                Delete Category
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Category</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedCategory ? (
                <>
                  <div className='mb-2 row mb-6'>
                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>
                      Category Title
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        value={selectedCategory.title}
                        className='form-control form-control-lg form-control-solid'
                        onChange={(e) => {
                          if (!e.target.value) {
                            setNameValidationError('Category name is required.')
                          } else {
                            setNameValidationError('')
                          }
                          setSelectedCategory({
                            ...selectedCategory,
                            title: e.target.value,
                          })
                        }}
                        type='text'
                      />
                      {nameValidationError && (
                        <div className='text-danger'>{nameValidationError}</div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span>Status:</span>
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <Form.Select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        name='type'
                        id='type'
                        value={selectedCategory.status}
                        onChange={(e) => {
                          setSelectedCategory({
                            ...selectedCategory,
                            status: Number(e.target.value),
                          })
                        }}
                      >
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                      </Form.Select>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              {selectedCategory?.title === '' ? (
                <>
                  <button type='button' className='btn btn-light-dark' disabled={true}>
                    Update Category
                  </button>
                </>
              ) : (
                <>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => {
                      if (selectedCategory && selectedCategory.title) {
                        handleUpdateCategory.mutateAsync({
                          id: selectedCategory.id,
                          title: selectedCategory.title,
                          status: selectedCategory.status,
                        })
                      }
                    }}
                    data-bs-dismiss='modal'
                  >
                    Update Category
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CategoriesList
