/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { Requisition } from '../../../../app/pages/requisitions/requisitions/components/RequisitionsList'
import { useQuery } from 'react-query'
import { get_users_requisitions } from '../../../../app/pages/dashboard/api/DashboardApis'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({ className }) => {
  const [requisitionsList, setRequisitionsList] = useState<Requisition[]>([])
  const navigate = useNavigate()
  const { data: requisitionsData } = useQuery('requisitions', get_users_requisitions, { refetchOnWindowFocus: false });
  useEffect(() => {
    if (requisitionsData) {
      setRequisitionsList(requisitionsData.data);
    };
  }, [requisitionsData]);
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>My Requisitions</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{requisitionsList?.length} requisitions</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <Button
            className='btn btn-sm btn-light-primary'
            onClick={() => navigate('/requisitions/add-requisition')}
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Requistion
          </Button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-200px'></th>
                    <th className='p-0 min-w-160px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {requisitionsList?.length > 0 ? (
                    <>
                      {requisitionsList?.map((requisition, index) => (

                        <tr key={index}>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {requisition.title}
                            </a>
                            <span className='text-muted fw-semibold d-block'>{requisition.due_date}</span>
                          </td>
                          <td className='text-end text-muted fw-semibold'>{requisition.requisition_type_title}</td>
                          <td className='text-end'>
                            <span className='badge badge-light-success'>{requisition.status}</span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTIcon iconName='arrow-right' className='fs-2' />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </>

                  ) : (
                    <div>No Requisitions were made</div>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export { TablesWidget5 }
