import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

// Get ALL Sub-Category List Api
export const get_all_subcategories = async () => {
    let response = await axios.get(baseURL + "/api/sub-category");
    return response.data;  
}
//Active SubCategories
export const get_all_active_subcategories=async () => {
  const response = await axios.get(baseURL + '/api/sub-category/'+1);
    return response.data;
};
// Add New subcategory
export const add_subcategory = async (values:object) => {
    let response = await axios.post(baseURL + "/api/sub-catagory", values);
    return response.data;  
}
// Delete SubCategory
export const delete_subcategory= async (id:number) => {
    let response = await axios.delete(baseURL + "/api/sub-catagory/"+id);
    return response.data;
}
// Update the subcategory function to accept an object as a parameter
export const update_subcategory = async (updatedSubcategory) => {
    const {
      id,
      title,
      categories_id,
      status,
    } = updatedSubcategory;
  
    try {
      const response = await axios.patch(baseURL + "/api/sub-catagory/" + id, {
        title,
        categories_id,
        status
      });
  
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  // Get Single Subcategory Logs by ID
export const get_subcategory_logs = async (id: number) => {
  let response = await axios.get(baseURL + "/api/sub-category-activity-log/" + id);
  return response.data;
};
  // Get All Subcategory Logs by ID
  export const get_all_subcategories_logs = async () => {
    let response = await axios.get(baseURL + "/api/sub-category-activity-logs");
    return response.data;
  };
  // Filtered Categories And Status
  export const get_all_filters_subcategories =async (category,status) => {
    const response = await axios.post(baseURL + '/api/get-sub-categories', {
        category_id:category,
        status:status,
      });
      return response.data;
  };
  // change status 
  export const change_active_status_subcategory = async (id:number) => {
    let response = await axios.post(baseURL + "/api/update-status/" + id, {
      model_name:"subCatagory",
    });
    return response.data;  
  }