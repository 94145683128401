import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { update_purchase_order, get_all_purchase_orders } from '../api/purchasesApi';
import { KTIcon, KTSVG } from '../../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Flatpickr from 'react-flatpickr';
import AddNewProduct from './AddNewProduct';
import { get_all_active_products} from '../../products/api/productsApi';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';
import { formatDateFlatpickr, formatDateWithoutTime } from '../../../helper-functions/DateFunction';
import Select from 'react-select';
const initialValues: any = {
    total_price: 0,
    discount: 0,
    grand_total: 0,
    PO_date: '',
};

const ApprovedProductSchema = Yup.object().shape({});

interface ProductType {
    id: number;
    product_id: number;
    name: string;
    product_name: string;
    product_type: string;
    PO_date: string;
    supplier_name: string | undefined;
    discount: number;
    vendor_id: number;
    vendor_name: string;
    price: number;
    supplier_id: number;
    quantity: number;
    rate: number;
    created_at: string;
    updated_at: string;
    purchase_order_products: any[];
}

type VendorType = {
    id: number;
    supplier_name: string | undefined;
};

function EditPurchase() {
    const [selectedPurchase, setSelectedPurchase] = useState<ProductType[]>([]);
    const [userChangedDate, setUserChangedDate] = useState(false);
    const [discountedPriceProduct, setDiscountedPriceProduct] = useState<any>(0);
    const [priceProduct, setPriceProduct] = useState(1);
    const [quantity, setQuantity] = useState(1);
    const [product_type, setProduct_type] = useState('');
    const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
    const [allProduct, setAllProduct] = useState<ProductType[]>([]);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const location = useLocation();
    const navigate = useNavigate();
    const purchase = (location.state as { purchase: ProductType }).purchase;
    const { data: allProducts } = useQuery('allProductSystem', get_all_active_products, {refetchOnWindowFocus: false});
    const { refetch } = useQuery('products', get_all_purchase_orders, {refetchOnWindowFocus: false});

    useEffect(() => {
        setSelectedPurchase(purchase ? [purchase] : []);
    }, [purchase]);
    useEffect(() => {
        if (allProducts && Array.isArray(allProducts.data)) {
            setAllProduct(allProducts.data as ProductType[]);
        } else {
            setAllProduct([]);
        }
    }, [allProducts]);

    useEffect(() => {
        // Initialize filteredProducts with all products
        setFilteredProducts(Array.isArray(allProduct) ? allProduct : []);
    }, [allProduct]);
    let grandTotal = 0;
    let totalPrice = 0;

    const handleProceedToPurchase = async () => {
        try {
            await formik.validateForm();

            let totalPrice = 0;
            let grandTotal = 0;

            // Use purchaseOrderProducts in your code            
            const finalPODate = selectedDate? selectedDate : selectedPurchase[0].PO_date;
            const vendorId = selectedPurchase[0].vendor_id; // Assuming vendor_id is available
            const vendorName = selectedPurchase[0].vendor_name; // Assuming vendor_name is available
            const purchase_order_products = selectedPurchase.flatMap((product: any) =>
                product.purchase_order_products.map((purchaseProd) => {
                    const price = purchaseProd.rate * purchaseProd.quantity;
                    const finalPrice = purchaseProd.discount ? price - purchaseProd.discount : price;

                    totalPrice += finalPrice;
                    grandTotal = discountTotal ? totalPrice - discountTotal : totalPrice;

                    return {
                        id: purchaseProd.id, // Define or obtain the appropriate IDs
                        purchase_order_id: purchaseProd.purchase_order_id,
                        product_id: purchaseProd.product_id,
                        product_name: purchaseProd.product_name, // Define or obtain the appropriate product names
                        quantity: purchaseProd.quantity, // Define or obtain the appropriate quantities
                        rate: purchaseProd.rate,
                        price: purchaseProd.price,
                        discount: purchaseProd.discount || 0,
                        final_price: finalPrice,
                        created_at: purchaseProd.created_at,
                        updated_at: purchaseProd.updated_at,
                        // ...other properties as needed
                    };
                })
            );

            const purchaseData: any = {
                supplier_id: vendorId, // Include the vendor_id
                vendor_name: vendorName, // Include the vendor_name
                PO_date: finalPODate,
                discount: discountTotal,
                grand_total: grandTotal,
                status: "PO-Generated",
                purchase_order_products,
                // ...other formik values if needed
            };

            const response = await update_purchase_order(purchase.id, purchaseData);
            navigate('/purchase-order/purchase-order-list');
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
        } catch (error: any) {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                });
            }
        } finally {
            setLoading(false);
        }
    };


    const formik = useFormik<any>({
        initialValues,
        validationSchema: ApprovedProductSchema,
        onSubmit: handleProceedToPurchase,
    });
    const handleDiscountTotalChange = (e) => {
        const value = parseFloat(e.target.value);
        // Round the parsed value to two decimal places
        const roundedValue = isNaN(value) ? 0 : parseFloat(value.toFixed(2));
        setDiscountTotal(roundedValue);
    };    
    const handleQuantityChange = (productId, purchaseProductId, value) => {
        setSelectedPurchase((prevProducts) =>
            prevProducts.map((prevProduct) => {
                if (prevProduct.id === productId) {
                    const updatedPurchaseOrderProducts = prevProduct.purchase_order_products.map((purchaseProd) =>
                        purchaseProd.id === purchaseProductId ? { ...purchaseProd, quantity: parseInt(value) } : purchaseProd
                    );

                    return {
                        ...prevProduct,
                        purchase_order_products: updatedPurchaseOrderProducts,
                    };
                }
                return prevProduct;
            })
        );
    };
    const handleDiscountChange = (productId, purchaseProductId, value) => {
      // Enforce the restriction here or handle accordingly
      const isValidInput = /^(\d+(\.\d{1,2})?)?$/.test(value);
      if (isValidInput) {
          setSelectedPurchase((prevProducts) =>
              prevProducts.map((prevProduct) => {
                  if (prevProduct.id === productId) {
                      const updatedPurchaseOrderProducts = prevProduct.purchase_order_products.map((purchaseProd) =>
                          purchaseProd.id === purchaseProductId ? { ...purchaseProd, discount: parseFloat(value) } : purchaseProd
                      );
                      return {
                          ...prevProduct,
                          purchase_order_products: updatedPurchaseOrderProducts,
                      };
                  }
                  return prevProduct;
              })
          );
      }
      // Handle invalid input if needed
    };
    const handleRateChange = (productId, purchaseProductId, value) => {
        // Enforce the restriction here or handle accordingly
        const isValidInput = /^(\d+(\.\d{1,2})?)?$/.test(value);
        if (isValidInput) {
            setSelectedPurchase((prevProducts) =>
                prevProducts.map((prevProduct) => {
                    if (prevProduct.id === productId) {
                        const updatedPurchaseOrderProducts = prevProduct.purchase_order_products.map((purchaseProd) =>
                            purchaseProd.id === purchaseProductId ? { ...purchaseProd, rate: parseFloat(value) } : purchaseProd
                        );

                        return {
                            ...prevProduct,
                            purchase_order_products: updatedPurchaseOrderProducts,
                        };
                    }
                    return prevProduct;
                })
            );
        }
        // Handle invalid input if needed
    };
    const handleRemoveProduct = (productId, purchaseProductId) => {
        setSelectedPurchase((prevProducts: any) => {
            if (!Array.isArray(prevProducts)) return [];

            // Iterate through the products
            return prevProducts.map((product) => {
                // If the current product's ID matches the one to be removed
                if (product.id === productId) {
                    // Remove only the specific product from the purchase order
                    const updatedPurchaseOrderProducts = product.purchase_order_products.filter(
                        (purchaseProd) => purchaseProd.id !== purchaseProductId
                    );

                    // If the purchase order still has products, update it; otherwise, remove the entire purchase order
                    return updatedPurchaseOrderProducts.length > 0
                        ? {
                            ...product,
                            purchase_order_products: updatedPurchaseOrderProducts,
                        }
                        : null;
                }
                return product;
            }).filter(Boolean); // Remove null values (purchase orders with only one product)
        });
    };

    // Add an event listener for keydown events
    // Modify handleKeyDown to use only productId
    const handleKeyDown = (event, productId, purchaseProductId) => {
        // Check if the pressed key is Enter (key code 13)
        if (event.keyCode === 13) {
            const targetTagName = event.target.tagName.toLowerCase();

            // Check if the event target is a button
            if (targetTagName === 'button') {
                event.preventDefault(); // Prevent the default form submission behavior

                // Call handleRemoveProduct with both productId and purchaseProductId
                handleRemoveProduct(productId, purchaseProductId);
            }
        }
    };

    // {Add Product Code}
    // Handle Product in Modal
    const handleProductChange = (e) => {
        const selectedProductId = e.value;
        setSelectedProduct(selectedProductId);
        // Find the selected product details
        const selectedProductDetails = allProduct.find((product) => product.id === parseInt(selectedProductId));
        // Update the state with selected product details
        if (selectedProductDetails) {
            setPriceProduct(selectedProductDetails.price || 1);
            setProduct_type(selectedProductDetails.product_type || '');
            setDiscountedPriceProduct(selectedProductDetails.discount || 0);
            setQuantity(selectedProductDetails.quantity || 1);
            // You may set other related fields here if needed
        } else {
            // // Handle the case when the selected product details are not found
            setSelectedProduct(null);
            setPriceProduct(1);
            setProduct_type('');
            setDiscountedPriceProduct(0);
            // Reset other related fields if needed
        }
    };
    const handleAddProduct = () => {
        if (selectedProduct) {
            const selectedProductDetails = allProduct.find((product) => product.id === parseInt(selectedProduct));
    
            const firstPurchaseOrderId = selectedPurchase.length > 0
                ? selectedPurchase[0].purchase_order_products && selectedPurchase[0].purchase_order_products.length > 0
                    ? selectedPurchase[0].purchase_order_products[0].purchase_order_id
                    : null
                : null;
    
            if (selectedProductDetails) {
                const newProduct = {
                    id: selectedProductDetails.id,
                    product_id: selectedProductDetails.id,
                    purchase_order_id: firstPurchaseOrderId,
                    product_name: selectedProductDetails.name || '',
                    quantity: quantity || 1,
                    rate: selectedProductDetails.price || 1,
                    price: selectedProductDetails.price * (quantity || 1),
                    discount: discountedPriceProduct || 0,
                };
    
                const isProductExists = selectedPurchase.some((purchase) =>
                    purchase.purchase_order_products.some((product) => product.product_id === newProduct.product_id)
                );
    
                if (isProductExists) {
                    toast.warning('Product Already Exists in the Table.', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                } else {
                    setSelectedPurchase((prevProducts) => {
                        const updatedProducts = Array.isArray(prevProducts)
                            ? prevProducts.map((prevProduct) => ({
                                ...prevProduct,
                                purchase_order_products: [...prevProduct.purchase_order_products, newProduct],
                            }))
                            : [];
                        return updatedProducts;
                    });
    
                    // Show success toast only when the product is added
                    toast.success('Product Added Successfully.', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                }
            }
        }
    };
    const handlePurchaseDateChange = (selectedDates) => {
        if (selectedPurchase) {
          const newtockOutDate = selectedDates[0];
          newtockOutDate?.setHours(0, 0, 0, 0);
          const formattedDate = formatDateFlatpickr(newtockOutDate);
         setSelectedDate(formattedDate);
        }
      };
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Edit Purchase Order</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <button
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title='Navigate to List Products'
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/purchase-order/purchase-order-list')}
                    >
                        List of Purchases
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div
                    className='card-header border-0 cursor-pointer'
                >
                    <h3 className='card-title align-items-start flex-column'>
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Add New Product"
                            className='btn btn-sm btn-light-dark'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_2'
                        >
                            <KTIcon iconName='plus' className='fs-3' />
                            Add New Product
                        </button>
                    </h3>
                    <div
                        className='card-toolbar'
                    >
                        <button
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Add Product"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_1"
                            onClick={() => {
                                // Close the modal after adding the product
                                const modalElement = document.getElementById("kt_modal_1");
                                if (modalElement) {
                                    modalElement.style.display = "none";
                                    setSelectedProduct("");
                                    setDiscountedPriceProduct(0);
                                    setQuantity(1);
                                }
                            }}

                        >
                            Add Product
                        </button>
                    </div>
                </div>
                <Form>
                    <div className='card-header border-0 pt-5'>

                        <div className='card-body'>
                            <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                    <span className='required'>PO Date</span>
                                </label>
                                <div className='col-lg-4 fv-row'>
                                    <Flatpickr
                                        onChange={(selectedDates) => handlePurchaseDateChange(selectedDates)}
                                        className='form-control form-control-solid'
                                        placeholder={formatDateWithoutTime(selectedPurchase[0]?.PO_date)}
                                        options={{
                                            dateFormat: 'd/m/Y',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                    <span className='required'>Vendor</span>
                                </label>
                                <div className='col-lg-4 fv-row'>
                                    <label className='form-control form-control-lg form-control-solid'>
                                        {selectedPurchase.length > 0 ? selectedPurchase[0].vendor_name : "No Vendor Selected"}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th className='min-w-100px required'>Product</th>
                                        <th className='min-w-100px required'>Quantity</th>
                                        <th className='min-w-140px required'>Rate ({getSettingValue('currency_symbol')})</th>
                                        <th className='min-w-120px required'>Price ({getSettingValue('currency_symbol')})</th>
                                        <th className='min-w-150px'>Discount ({getSettingValue('currency_symbol')})</th>
                                        <th className='min-w-150px required'>Final Price ({getSettingValue('currency_symbol')})</th>
                                        <th className='min-w-100px text-end'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedPurchase.length > 0 &&
                                        selectedPurchase.map((product) => {
                                            return product.purchase_order_products.map((purchaseProduct, index) => {
                                                const price = purchaseProduct.rate * purchaseProduct.quantity;
                                                let finalPrice;
                                                if (purchaseProduct.discount) {
                                                    const discountedAmount = price - purchaseProduct.discount;
                                                    finalPrice = discountedAmount;
                                                } else {
                                                    finalPrice = price;
                                                }

                                                totalPrice += finalPrice;
                                                if (discountTotal) {
                                                    const discountAmount = totalPrice - discountTotal;
                                                    grandTotal = discountAmount;
                                                } else {
                                                    grandTotal = totalPrice;
                                                }
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <input
                                                                className='card'
                                                                type='text'
                                                                readOnly
                                                                value={purchaseProduct.product_name}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='card'
                                                                type='number'
                                                                placeholder='Quantity'
                                                                value={purchaseProduct ? purchaseProduct.quantity : ''}
                                                                onChange={(e) => handleQuantityChange(product.id, purchaseProduct.id, e.target.value)}
                                                            />

                                                        </td>

                                                        <td>
                                                            <input
                                                                className='card'
                                                                type='number'
                                                                value={purchaseProduct.rate}
                                                                placeholder='Rate'
                                                                onChange={(e) => handleRateChange(product.id, purchaseProduct.id, e.target.value)}
                                                            />
                                                        </td>

                                                        <td>
                                                            <input
                                                                className='card'
                                                                type='number'
                                                                readOnly
                                                                value={price.toFixed(2)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='card'
                                                                type='number'
                                                                placeholder='Discount'
                                                                onChange={(e) => handleDiscountChange(product.id, purchaseProduct.id, e.target.value)}
                                                                value={purchaseProduct.discount}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='card'
                                                                type='number'
                                                                readOnly
                                                                value={finalPrice.toFixed(2)}
                                                            />
                                                        </td>
                                                        <td className='text-end'>
                                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                                {/* Add a Remove button with an onClick event handler */}
                                                                <button
                                                                    type='button'
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title="Delete Product"
                                                                    className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                                                                    onClick={() => handleRemoveProduct(product.id, purchaseProduct.id)}
                                                                    onKeyDown={(e) => handleKeyDown(e, product.id, purchaseProduct.id)}
                                                                    tabIndex={0}
                                                                >
                                                                    <KTIcon iconName='trash' className='fs-3' />
                                                                </button>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            });
                                        })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                       Total Price ({getSettingValue('currency_symbol')}):
                                                <input
                                                    className='card mx-3 p-auto'
                                                    type="number"
                                                    {...formik.getFieldProps('total_price')}
                                                    readOnly
                                                    value={totalPrice.toFixed(2)}
                                                />
                                    </td>
                                    <td colSpan={2}>
                                       Total Discount 
                                       ({getSettingValue('currency_symbol')}):
                                                <input
                                                    className='card mx-3 p-auto'
                                                    type="number"
                                                    placeholder='Total Discount'
                                                    {...formik.getFieldProps('discount')}
                                                    onChange={handleDiscountTotalChange}
                                                    value={discountTotal.toFixed(2)}
                                                />
                                    </td>
                                    <td colSpan={2}>
                                      Grand Total ({getSettingValue('currency_symbol')}):  
                                                <input
                                                   className='card mx-3 p-auto'
                                                    type="number"
                                                    {...formik.getFieldProps('grand_total')}
                                                    readOnly
                                                    value={grandTotal.toFixed(2)}
                                                />
                                    </td>
                                    <td className='min-w-140px'></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={1}></td>
                                    </tr>
                            </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button
                            data-toggle='tooltip'
                            data-placement='bottom'
                            title='Proceed To Purchase'
                            type='button'
                            className='btn btn-primary'
                            disabled={loading}
                            onClick={handleProceedToPurchase}
                        >
                            {!loading && 'Update Purchase'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </Form>
            </div>
            {/* Add Product */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Add Product</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <Form>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Product</label>
                                    <div className='col-lg-8 fv-row'>
                                    <Select
                                            placeholder="Select "
                                            options={filteredProducts.map((product) => ({
                                                value: product.id,
                                                label: product.name,
                                            }))}
                                            className="basic-multi-select form-select-lg p-2 bg-light text-black"
                                            classNamePrefix="select border-light fw-bold"
                                            onChange={handleProductChange}
                                        />
                                    </div>
                                </div>
                                {selectedProduct && (
                                    <div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Product Type</label>
                                            <div className='col-lg-8 fv-row'>
                                                <select
                                                    className='form-control form-control-lg form-control-solid'
                                                    value={product_type}
                                                    onChange={(e) => setProduct_type(e.target.value)}
                                                >
                                                    <option value='asset'>Asset</option>
                                                    <option value='consumable'>Consumable</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Quantity</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Quantity'
                                                    value={quantity}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Rate ({getSettingValue('currency_symbol')})</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Rate'
                                                    value={priceProduct}
                                                    onChange={(e: any) => setPriceProduct(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Discount ({getSettingValue('currency_symbol')})</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='number'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Discount'
                                                    value={discountedPriceProduct}
                                                    onChange={(e: any) => setDiscountedPriceProduct(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>)}
                            </Form>
                        </div>
                        <div className='modal-footer'>
                            <button
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Close"
                                type='button'
                                className='btn btn-light'
                                data-bs-dismiss='modal'
                            >
                                Close
                            </button>
                            <button
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Add Product"
                                className='btn btn-sm btn-light-dark'
                                onClick={() => {
                                    handleAddProduct();
                                }}
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_1'
                            >
                                <KTIcon iconName='plus' className='fs-3' />
                                Add Product
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            {/* New Product Modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Add New Product</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <AddNewProduct />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditPurchase;
