import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDateWithoutTime } from '../../../helper-functions/DateFunction'
import { useQuery } from 'react-query';
import { get_single_stock } from '../api/stocksApi';
import { get_all_products } from '../../products/api/productsApi';
interface Product {
  id: number;
  name: string;
};
function ViewStockOut() {
  const [selectedStock, setSelectedStock] = useState<any | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const location = useLocation();
  const id = (location.state as { id: number }).id;
  const { data, isLoading, error } = useQuery(['ViewStockIn', id], () =>
    get_single_stock(id), {refetchOnWindowFocus: false}
  );
  useEffect(() => {
    if (data) {
      setSelectedStock(data.data);
    }
  }, [data]);
  const navigate = useNavigate();
  const { data: productData } = useQuery('products', get_all_products, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (productData) {
      setProducts(productData.data as Product[]);
    }
  }, [productData]);
  const getProductNameById = (productId) => {
    const product = products.find((p) => p.id == productId);
    return product ? product.name : 'Product not found';
  };
  if (isLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className='text-center'>
        <h2>Error in Get All Stocks</h2>
      </div>
    );
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>View Stock out</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/stocks/stock-out-list')}
          >
            List of Stock Out
          </button>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {selectedStock ? (
            <>
              <div className='card-body border-top p-9'>
                <div className='mb-2 row mb-6'>
                  <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Due Date</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-solid'
                      value={formatDateWithoutTime(selectedStock.date_time)}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Description</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      value={selectedStock.description?selectedStock.description:"No Description"}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-6'>
                  {/* department */}
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Department</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      value={selectedStock.department_name}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-6'>
                  {/* User */}
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>User</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      value={selectedStock.user_name}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row card mb-5 mb-xl-10'>
                  <div
                    className='card-header border-0 cursor-pointer row'
                  >
                    <div className='card-title m-3 col-lg-7'>
                      <h3 className='fw-bolder m-0'>Products for Stock Out</h3>
                    </div>
                  </div>
                  <div>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mx-6 '>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-100px required '>Product</th>
                          <th className='min-w-100px required'>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedStock.stock_products.map((product, index) => (
                          <tr key={index}>
                            <td
                            >
                              <input className='form-control form-control-lg form-control-solid' value={getProductNameById(product.product_id)} readOnly />
                            </td>
                            <td>
                              <input
                                className='form-control form-control-lg form-control-solid'
                                value={product.quantity}
                                readOnly
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ViewStockOut