import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { KTIcon } from '../../../../_metronic/helpers';
import { KTSVG } from '../../../../_metronic/helpers';
import {
  change_active_status_subcategory,
  delete_subcategory,
  get_all_filters_subcategories,
  get_all_subcategories,
  update_subcategory,
} from '../api/subcategoriesApi';
import { Form } from 'react-bootstrap';
import { get_all_active_categories } from '../../categories/api/categoriesApi';
import { formatDateWithTime } from '../../../helper-functions/DateFunction';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
type Subcategories = {
  id: number;
  title: string | undefined;
  categories_id: number | undefined;
  categories_title: string | undefined;
  status: number;
  updated_by_name: string | undefined;
  updated_at: string;
};
interface Category {
  id: number;
  categories_id: number;
  title: string | undefined;
};
var subcategory_add, subcategory_edit, subcategory_logs, subcategory_delete
function SubcategoriesList() {
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [filteredCategory, setFilteredCatregory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState('');
  const [subcategoryList, setSubcategoryList] = useState<Subcategories[]>([]);
  const [categoryTitle, setCategoryTitle] = useState<Category[]>([]);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const [selectedSubcategory, setSelectedSubcategory] = useState<Subcategories | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryTitleValidationError, setCategoryTitleValidationError] = useState('');
  const [titleValidationError, setTitleValidationError] = useState('');
  const navigate = useNavigate();
  const [visibleData, setVisibleData] = useState<Subcategories[]>([]);
  subcategory_add = permissionCheck('subcategory-create')
  subcategory_edit = permissionCheck('subcategory-update')
  subcategory_logs = permissionCheck('subcategory-activity-log')
  subcategory_delete = permissionCheck('subcategory-delete')
  const { data: SubCategoryList, isLoading, isError } = useQuery('subcategory', get_all_subcategories, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (SubCategoryList) {
      setSubcategoryList(SubCategoryList.data);
    }
  }, [SubCategoryList]);
  const { data: filterData, refetch: filterRefetchSubCategory } = useQuery(
    'filterData',
    () => get_all_filters_subcategories(filteredCategory, selectedStatus),
    {
      refetchOnWindowFocus: false,
      onSuccess: (filteredData) => {
        setSubcategoryList(filteredData.data);
      },
      onError: (error: any) => {
        // Error handling
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      },
      enabled: false, // Set initial enable state to false
    }
  );

  const handleApplyFilters = () => {
    filterRefetchSubCategory({ // Enable the query before refetch
      throwOnError: true,
    });
  };
  const handleClearFilters = () => {
    setFilteredCatregory('');
    setSelectedStatus('');
    // Reset to the original subcategory list
    if (SubCategoryList) {
      setSubcategoryList(SubCategoryList.data);
    }
  };
  const categoriesQuery = useQuery('categories', get_all_active_categories, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (categoriesQuery.data) {
      setCategoryTitle(categoriesQuery.data.data as Category[])
    };
  }, [categoriesQuery]);
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems = subcategoryList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    };
  };
  useEffect(() => {
    if (subcategoryList.length > 0) {
      let sortedData = [...subcategoryList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          const aValue = String(a[sortBy?.key]) || ''; // Convert to string and handle null/undefined
          const bValue = String(b[sortBy?.key]) || ''; // Convert to string and handle null/undefined
      
          if (sortBy?.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
              return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else {
              return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
      });    
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage,
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [subcategoryList, currentPage, selectedItemsPerPage, sortBy]);
  const resetValidationErrors = () => {
    setTitleValidationError("");
    setCategoryTitleValidationError("");
  };
  const handleCategoryTitleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (selectedSubcategory) {
      const newCategoryTitle = e.target.value;
      if (!newCategoryTitle) {
        setCategoryTitleValidationError('Category Title is required.');
      } else {
        setCategoryTitleValidationError('');
      };
      const selectedCategory = categoryTitle.find((category) => category.title === newCategoryTitle);
      setSelectedSubcategory({
        ...selectedSubcategory,
        categories_title: newCategoryTitle,
        categories_id: selectedCategory ? selectedCategory.id : undefined,
      })
    };
  };
  const handleSubcategoryTitleChange = (e) => {
    if (selectedSubcategory) {
      const newSubcategoryTitle = e.target.value;
      if (!newSubcategoryTitle) {
        setTitleValidationError('Subcategory  is required.');
      } else {
        setTitleValidationError('');
      };

      setSelectedSubcategory({
        ...selectedSubcategory,
        title: newSubcategoryTitle,
      })
    };
  };
  const handleUpdateSubcategory = () => {
    setLoading(true);
    if (selectedSubcategory) {
      const { id, title, categories_id, categories_title, status } = selectedSubcategory;

      if (!categories_id) {
        const selectedCategory = categoryTitle.find(
          (category) => category.title === categories_title
        );

        if (selectedCategory) {
          updateSubcategoryMutation.mutate({
            id,
            title,
            categories_id: selectedCategory.id,
            categories_title,
            status,
          })
        } else {
          setLoading(false)
        }
      } else {
        updateSubcategoryMutation.mutate({
          id,
          title,
          categories_id,
          categories_title,
          status,
        })
      };
    }
  };
  const updateSubcategoryMutation = useMutation(update_subcategory, {
    onSuccess: (response) => {
      // Display a success toast message
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 2000,
      })
      setLoading(false)

      // Update the subcategory list
      get_all_subcategories()
        .then((data) => {
          setSubcategoryList(data.data);
        })
        .catch((error: any) => {
          if (error.response) {
            const errorMessage = error.response.data.message;
            toast.error(errorMessage, {
              position: 'top-right',
              autoClose: 2000,
            })
          }
        })

      // Refresh the categories data
      categoriesQuery.refetch();
    },
    onError: (error: any) => {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
      setLoading(false);
    },
  });
  const handleDeleteSubcategory = useMutation((id: number) => {
    return delete_subcategory(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        })
      })
      .then(() => {
        return get_all_subcategories().then((data) => {
          setSubcategoryList(data.data);
        })
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value;
    setSearchInput(searchTerm);
    const filteredItems = SubCategoryList.data.filter((subcategory) => {
      return (
        subcategory.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        subcategory.categories_title?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })

    setSubcategoryList(filteredItems);
  }
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_subcategory(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        return get_all_subcategories().then((data) => {
          setSubcategoryList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    } else if (key === "updated_by_name"||key==="categories_title") {
      setSortBy({ key, ascending: true });
    } else if (key === "updated_at") {
      // Toggle between ascending and descending order
      setSortBy({ key, ascending: !sortBy.ascending });
     }
     else if (key === "status") {
      setSortBy({ key, ascending: true });
    }
     else {
      setSortBy(null); // Revert to unsorted state
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleSubcategoryLogs = (Subcategory: any) => {
    navigate("/subcategories/subcategory-logs", { state: { id: Subcategory.id } });
  };
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>List of Subcategories</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {subcategory_add && (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Navigate to Add Subcategory"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/categories/subcategories/add-subcategory')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add Subcategory
            </button>
          )}
        </div>
      </div>
      <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            data-toggle="tooltip" data-placement="bottom" title="Search a Subcategory"
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search Subcategory'
            value={searchInput}
            onChange={handleInputChange}
          />
        </h3>
        <div className='card-toolbar d-flex align-items-center'>
          <div className='mx-4'>
            <Form.Group controlId='type'>
              <Form.Select
                name='type'
                className='form-select form-select-solid form-select-lg fw-bold'
                onChange={(e) => setFilteredCatregory(e.target.value)}
                value={filteredCategory}>
                <option value=''>All Categories</option>
                {Array.isArray(categoryTitle) && categoryTitle.length > 0 ? (
                  categoryTitle.map((CategoryType) => (
                    <option key={CategoryType.id} value={CategoryType.id}>
                      {CategoryType.title}
                    </option>
                  ))
                ) : (
                  <option value='' disabled>No Categories Available</option>
                )}
              </Form.Select>
            </Form.Group>
          </div>
          <div className='mx-4'>
            <Form.Group controlId='type'>
              <Form.Select
                name='type'
                className='form-select form-select-solid form-select-lg fw-bold'
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value=''>Filter by Status...</option>
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
        <div>
          <button
            className='btn btn-primary me-3'
            onClick={handleApplyFilters}
          >
            Apply Filters
          </button>
          <button
            className='btn btn-light-danger'
            onClick={handleClearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>
      <div className='card-header border-0 pt-5 row'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Subcategory Title<span onClick={() => handleSort('title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Category Title<span onClick={() => handleSort('categories_title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Status<span onClick={() => handleSort('status')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-140px'>Updated By<span onClick={() => handleSort('updated_by')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-120px'>Updated At<span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                {subcategory_delete || subcategory_edit || subcategory_logs ? (
                  <th className='min-w-100px text-center'>Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {isError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : subcategoryList.length > 0 ? (
                visibleData.map((Subcategory) => (
                  <tr key={Subcategory.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {Subcategory.title}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {Subcategory.categories_title}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>{Subcategory.status === 1 ? (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                            Active
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>
                            Inactive
                          </h3>
                        </div>
                      </div>
                    )}
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {Subcategory.updated_by_name}
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {formatDateWithTime(Subcategory.updated_at)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {subcategory_edit && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Edit Subcategory"
                            onClick={() => {
                              setSelectedSubcategory(Subcategory);
                              resetValidationErrors();
                            }}
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_2'
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        )}
                        {subcategory_add || subcategory_edit ? (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Switch Status"
                            onClick={() => handleStatusUpdate.mutate(Subcategory.id)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='update-folder' className='fs-3' />
                          </button>
                        ) : null}
                        {subcategory_logs && (
                          <button
                            onClick={() => {
                              handleSubcategoryLogs(Subcategory);
                            }}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-custom-class='tooltip-dark'
                            title='Subcategory Logs'
                          >
                            <i className='bi bi-journal-text'></i>
                          </button>
                        )}
                        {subcategory_delete && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Delete Subcategory"
                            onClick={() => setSelectedSubcategory(Subcategory)}
                            className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Subcategories available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Edit Modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Subcategory</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedSubcategory ? (
                <>
                  {/* ... Edit Form ... */}
                  <Form>
                    <div className='form-group'>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          <span className='required'>Category</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                          <Form.Select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            name='type'
                            value={selectedSubcategory ? selectedSubcategory.categories_title : ''}
                            onChange={(e) => handleCategoryTitleChange(e)}
                          >
                            <option value=''>Select a Category...</option>
                            {Array.isArray(categoryTitle) && categoryTitle.length > 0 ? (
                              categoryTitle.map((CategoryType) => (
                                <option key={CategoryType.id} value={CategoryType.categories_id}>
                                  {CategoryType.title}
                                </option>
                              ))
                            ) : (
                              <option value='' disabled>No categories available</option>
                            )}

                          </Form.Select>
                          {categoryTitleValidationError && (
                            <div className='text-danger'>{categoryTitleValidationError}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                          Subcategory Title
                        </label>
                        <div className='col-lg-8 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            id='UpdateSupplierName'
                            aria-describedby='UpdateSupplierName'
                            placeholder='Enter New Name'
                            value={selectedSubcategory ? selectedSubcategory.title : ''}
                            onChange={(e) => handleSubcategoryTitleChange(e)}
                          />
                          {titleValidationError && (
                            <div className='text-danger'>{titleValidationError}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span>Status:</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <Form.Select
                          className='form-select form-select-solid form-select-lg fw-bold'
                          name='type'
                          id='type'
                          value={selectedSubcategory.status}
                          onChange={(e) => {
                            setSelectedSubcategory({
                              ...selectedSubcategory,
                              status: Number(e.target.value),
                            })
                          }}
                        >
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                        </Form.Select>
                      </div>
                    </div>
                  </Form>
                </>
              ) : null}
            </div>

            <div className='modal-footer'>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                {categoryTitleValidationError || titleValidationError ? (
                  <button data-toggle="tooltip" data-placement="bottom" title="Update Subcategory" type='button' className='btn btn-primary disabled'>
                    Update Subcategory
                  </button>
                ) : (
                  <button
                    data-toggle="tooltip" data-placement="bottom" title="Update Subcategory"
                    type='button'
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                    onClick={handleUpdateSubcategory}
                    disabled={loading}
                  >
                    {!loading && 'Update Subcategory'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete Subcategory Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedSubcategory ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold'>Subcategory Name: </span> {selectedSubcategory.title}
                  </p>
                  <p className='mb-2'>
                    <span className='fw-bold'>Category Name: </span>{' '}
                    {selectedSubcategory.categories_title}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button data-toggle="tooltip" data-placement="bottom" title="close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Delete this Subcategory"
                type='button'
                onClick={() => {
                  if (selectedSubcategory && selectedSubcategory.id) {
                    handleDeleteSubcategory.mutate(selectedSubcategory.id)
                  }
                }}
                className='btn btn-danger'
                data-bs-dismiss='modal'
              >
                Delete Subcategory
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
        <div className='align-items-center row'>
          <div className='d-flex'>
            <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
            <select
              style={{ width: '60px' }}
              className='form-select form-select-md px-2 col-md-1'
              value={selectedItemsPerPage}
              onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
            >
              {itemsPerPageOptions.map(option => (
                <option key={option} value={option} className='p-3 '>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {currentPage === 1 ? (
            <button
              className='btn btn-light'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          ) : (
            <button
              className='btn btn-primary'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          <button
            className='btn btn-primary ms-2'
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default SubcategoriesList;