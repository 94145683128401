
import { AddRoles } from "./RolesModel";
import { Permission } from "./RolesModel";
import { EditRoleType } from "./RolesModel";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { edit_role, get_all_permissions } from "../api/rolesApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
const EditRole: React.FC = () => {
  const [roleName, setRoleName] = useState<any | null>("");
  const [status, setStatus] = useState<any | null>("");
  const location = useLocation();
  const role = (location.state as { role: EditRoleType }).role;
  const statePage = (location.state as { fromPage: any }).fromPage;
  const [getPermission, setGetPermission] = useState<Permission[]>([]);
  // useNavigate
  const navigate = useNavigate();
  // Define types for roleCheckboxes and globalCheckboxes
  type RoleCheckboxes = Record<string, Record<string, boolean>>;
  type GlobalCheckboxes = Record<string, boolean>;
  // Initialize roleCheckboxes and globalCheckboxes based on your data
  const initialRoleCheckboxes: RoleCheckboxes =
    JSON.parse(localStorage.getItem("roleCheckboxes") || "{}") || {};
  const initialGlobalCheckboxes: GlobalCheckboxes =
    JSON.parse(localStorage.getItem("globalCheckboxes") || "{}") || {};
  const [roleCheckboxes, setRoleCheckboxes] = useState<RoleCheckboxes>(
    initialRoleCheckboxes
  );
  const [globalCheckboxes, setGlobalCheckboxes] = useState<GlobalCheckboxes>(
    initialGlobalCheckboxes
  );
  const [loading, setLoading] = useState(false);
  const [preCheck, setPreCheck] = useState(false);
  const [viewStatePage, setViewStatePage] = useState<boolean>(false);
  useEffect(() => {
    if (statePage === "view") {
      setViewStatePage(true);
    }
  }, [viewStatePage]);
  // Use Effect
  const { data } = useQuery("permissions", get_all_permissions, {refetchOnWindowFocus: false});
  let userPermissions: { entity: string; action: string }[] = [];
  // Use Effect
  useEffect(() => {
    if (data) {
      setGetPermission(data.data);
      setRoleName(role.name);
      setStatus(role.status);
      // Extracting entity and action from each permission in the user"s permissions array
      // eslint-disable-next-line react-hooks/exhaustive-deps
      userPermissions = role.permissions.map((permission: string) => {
        const [entity, action] = permission.split("-");
        // If the action is "activity", change it to "activity-log"
      const fixedAction = action === "activity" ? "activity-log" : action;
      return { entity, action: fixedAction };
      });
      // Initialize roleCheckboxes and globalCheckboxes based on user"s permissions
      const initialRoleCheckboxes: RoleCheckboxes = {};
      userPermissions.forEach(({ entity, action }) => {
        initialRoleCheckboxes[entity] = {
          ...initialRoleCheckboxes[entity],
          [action]: true,
        };
        const fakeEvent = { target: { checked: true } } as React.ChangeEvent<HTMLInputElement>;
        handleRoleCheckboxChange(fakeEvent, entity, action)
        setPreCheck(true)
      });
      setRoleCheckboxes(initialRoleCheckboxes);
    }
  }, [data, preCheck]);
  function areAllRelatedCheckboxesChecked(
    permission: string,
    roleCheckboxes: { [x: string]: { [x: string]: unknown } }
  ) {
    return getPermission
      .filter((value: { [x: string]: any }) => value[permission.toLowerCase()])
      .every(
        (value: { permission_key: string | number }) =>
          roleCheckboxes[value.permission_key]?.[permission]
      );
  };
  // Handle individual role checkbox change
  const handleRoleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    role: string,
    permission: string
  ) => {
    const { checked } = event.target;
    const updatedRoleCheckboxes = { ...roleCheckboxes };
    updatedRoleCheckboxes[role] = {
      ...updatedRoleCheckboxes[role],
      [permission]: checked,
    };
    setRoleCheckboxes(updatedRoleCheckboxes);
    setGlobalCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [permission]: areAllRelatedCheckboxesChecked(
        permission,
        updatedRoleCheckboxes
      ),
    }));
  };
  const handleGlobalCheckboxChange = (permission: string) => {
    const checked = !globalCheckboxes[permission];
    const updatedRoleCheckboxes = { ...roleCheckboxes };
    getPermission.forEach((value) => {
      if (value[permission.toLowerCase()]) {
        updatedRoleCheckboxes[value.permission_key] = {
          ...updatedRoleCheckboxes[value.permission_key],
          [permission]: checked,
        };
      }
    });
    setRoleCheckboxes(updatedRoleCheckboxes);
    getPermission.forEach((value) => {
      if (value[permission.toLowerCase()]) {
        setGlobalCheckboxes((prevCheckboxes) => ({
          ...prevCheckboxes,
          [permission]: checked,
        }));
      }
    });
  };
  const handleUpdateRole = useMutation(
    (updatedRolesData: AddRoles) => {
      return edit_role(
        role.id,
        updatedRolesData.name,
        updatedRolesData.permissions,
        updatedRolesData.status,
      );
    },
    {
      onSuccess: () => {
        toast.success("Roles Updated Successfully", {
          theme: "colored",
        });
        setRoleCheckboxes({});
        setGlobalCheckboxes({});
        setRoleName("");
        setStatus("");
        localStorage.removeItem("roleCheckboxes");
        localStorage.removeItem("globalCheckboxes");
        navigate("/users/roles/roles-list");
      },
      onError: () => {
        toast.error("Failed to Update Roles.", {
          theme: "colored",
        });
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );
  const handleSubmit = () => {
    if (roleName.length === 0) {
      toast.error("The role name field is required.", {
        theme: "colored",
      });
      return;
    }
    const selectedPermissions: string[] = [];
    let checkboxesAreEmpty = true;
    getPermission.forEach((value: { permission_key: string }) => {
      ["view", "create", "update", "delete","activity-log"].forEach((permission) => {
        if (roleCheckboxes[value.permission_key]?.[permission]) {
          selectedPermissions.push(`${value.permission_key}-${permission}`);
          checkboxesAreEmpty = false;
        }
      });
    });
    if (checkboxesAreEmpty) {
      toast.error("At least one checkbox must be selected.", {
        theme: "colored",
      });
      return;
    }
    setLoading(true);
    const roleDatas = {
      name: roleName,
      status: status, 
      permissions: selectedPermissions,
    };
    handleUpdateRole.mutate(roleDatas);
  };
  return (
    <>
      <div className="card card-custom">
      <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Edit Roles</span>
          </h3>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to Return To List of Roles"
          >
            <button
              data-toggle="tooltip" data-placement="bottom" title="Navigate to List Roles"
              className="btn btn-sm btn-light-dark fs-5"
              onClick={() => navigate("/users/roles/roles-list")}
            >
              List of Roles
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <label
              htmlFor="exampleFormControlInput1"
              className="required col-lg-4 col-form-label fw-bold fs-6"
            >
              Role Name
            </label>
            <div className="col-lg-8 fv-row">
              <Form.Control
                className="form-control form-control-solid mx-5"
             width={100}
                type="text"
                onChange={(e) => setRoleName(e.target.value)}
                value={roleName}
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-6">
            <label className="required col-lg-4 col-form-label fw-bold fs-6">
              <span>Status</span>
            </label>
            <div className="col-lg-8 fv-row">
              <Form.Select
                className="form-control form-control-solid mb-5 mx-5"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </Form.Select>
            </div>
          </div>
        </div>
        <div className="card-header">
          {!viewStatePage && (
            <div className="container mt-4">
              <div className="row">
                <div className="col-3">
                  <div className="p-3 mt-10">
                    <h4 className="card-title">Global Role & Permission</h4>
                  </div>
                </div>
                <div className="col-9">
                  <div className="p-3">
                    <table className="table">
                      <tbody>
                        <tr>
                          {["View", "Create", "Update", "Delete", "Logs"].map(
                            (option) => (
                              <td className="text-left fw-bold">
                                <label key={option}>{option}</label>
                              </td>
                            )
                          )}
                        </tr>
                        <tr>
                          {["view", "create", "update", "delete","activity-log"].map(
                            (option) => (
                              <td>
                                <div className="form-check form-check-custom form-check-solid">
                                  <label key={option}>
                                    <input
                                      className="form-check-input me-5"
                                      type="checkbox"
                                      name={option}
                                      checked={globalCheckboxes[option]}
                                      onChange={() =>
                                        handleGlobalCheckboxChange(option)
                                      }
                                    />
                                  </label>
                                </div>
                              </td>
                            )
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card-body">
          <div className="role-permission w-100">
            <div className="container mt-2">
              {getPermission.map((value) => (
                <div className="row" key={value.permission_key}>
                  <div className="col-3">
                    <div className="p-3 ">
                      <h5>{value.title}</h5>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="p-3">
                      <table className="table">
                        <tbody>
                          {!viewStatePage ? (
                            <>
                              <tr>
                                {["view", "create", "update", "delete","activity-log"].map(
                                  (option) => (
                                    <td key={option}>
                                      {value[option] ? (
                                        <div className="form-check form-check-custom form-check-solid">
                                          <label>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name={option}
                                              checked={
                                                roleCheckboxes[
                                                value.permission_key
                                                ]?.[option]
                                              }
                                              onChange={(event) =>
                                                handleRoleCheckboxChange(
                                                  event,
                                                  value.permission_key,
                                                  option
                                                )
                                              }
                                            />
                                          </label>
                                        </div>
                                      ) : (
                                        <div
                                        />
                                      )}
                                    </td>
                                  )
                                )}
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr>
                                {["view", "create", "update", "delete","activity-log"].map(
                                  (option) => (
                                    <td key={option}>
                                      {value[option] ? (
                                        <div className="form-check form-check-custom form-check-solid">
                                          <label>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name={option}
                                              checked={
                                                roleCheckboxes[
                                                value.permission_key
                                                ]?.[option]
                                              }
                                            />
                                          </label>
                                        </div>
                                      ) : (
                                        <div className="w-23 h-0 overflow-hidden" />
                                      )}
                                    </td>
                                  )
                                )}
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {!viewStatePage && (
          <div className="card-footer">
            <div className="container">
              <div className="row">
                <div className="col-12 text-end">
                  <button
                    className="btn btn-light-dark"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {!loading ? (
                      "Update Role"
                    ) : (
                      <span
                        className="indicator-progress d-block"
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default EditRole;
