import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { get_all_users_against_department } from '../../users/api/usersApi';
import { add_stock_out, get_all_products_in_stock, stock_requisitions_products } from '../api/stocksApi';
import { addStockOut, addStockOutInitValues as initialValues } from '../../../modules/accounts/components/settings/SettingsModel';
import { KTSVG } from '../../../../_metronic/helpers';
import { get_all_department } from '../../departments/api/departmentsApi';
import { all_accepted_requisitions } from '../../requisitions/requisitions/api/requisitionsApi';
const stockOutSchema = Yup.object().shape({
  date: Yup.string().required('Date is required'),
  department: Yup.string().required('Department is required'),
  user: Yup.string().required('User is required'),
});
interface Department {
  id: number;
  name: string;
};
interface Product {
  product_id: number;
  product_name: string;
  remaining_stock: number;
};
interface User {
  id: number;
  full_name: string;
};
interface Requisition {
  id: number;
  title: string;
  requisition_products: {
    id: number;
    quantity: number;
    updated_by: string;
    updated_at: string;
    product_id: number;
  }[];
};
interface RequisitionProduct {
  id: number;
  quantity: number;
  updated_by: string;
  updated_at: string;
  product_id: number;
  product_name: number;
};
function AddStockOut() {
  const [department, setDepartment] = useState<Department[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [requisitionArray, setRequisitionArray] = useState<Requisition[]>([]);
  const [loading, setLoading] = useState(false);
  const [productValue, setProductValue] = useState<any>('');
  const [quantityValue, setQuantityValue] = useState<any>('');
  const [ProductValidationError, setProductValidationError] = useState('');
  const [QuantityValidationError, setQuantityValidationError] = useState('');
  const [remainingQuantity, setRemainingQuantity] = useState<any>('');
  const [dateState, setDateState] = useState<any>({
    date1: new Date(),
    date2: null
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: departmentData } = useQuery('department', get_all_department, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (departmentData) {
      setDepartment(departmentData.data as Department[]);
    };
  }, [departmentData]);

  const { data: productsData } = useQuery('products', get_all_products_in_stock, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (productsData) {
      setProducts(productsData.data as Product[]);
    };
  }, [productsData]);
  // Getting API data and storing in states
  const { data: requisitionAllData } = useQuery('requisitionData', all_accepted_requisitions, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (requisitionAllData) {
      setRequisitionArray(requisitionAllData.data as Requisition[]);
    }
  }, [requisitionAllData]);
  const handleDepartmentChange = async (selectedDepartmentId) => {
    try {
      const userData = await get_all_users_against_department(selectedDepartmentId);
      if (Array.isArray(userData.data) && userData.data.length > 0) {
        setUsers(userData.data);
        return userData.data;
      } else {
        toast.error("No users found against this department", {
          position: 'top-right',
          autoClose: 3000,
        });
        setUsers([]);
        formik.setFieldValue('user', []);
        return [];
      }
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
      setUsers([]);
      return [];
    }
  };
  const mutation = useMutation(add_stock_out, {
    onSettled: (data) => {
      toast.success(data.message, {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/stocks/stock-out-list');
      setLoading(false)
      queryClient.invalidateQueries('stockOut');
    },
    onError: (error: any) => {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
        setLoading(false)
      }
    },
  });
  const formik = useFormik<addStockOut>({
    initialValues,
    validationSchema: stockOutSchema,
    onSubmit: (values, action) => {
      const quantityBigger = values.products.some(stockProduct => stockProduct.quantity > stockProduct.remainingQuantity);
      if (quantityBigger) {
        toast.error("Please add right quantity", {
          position: 'top-right',
          autoClose: 2000,
        })
        return;
      }
      setLoading(true)
      mutation.mutate({
        date_time: values.date ? new Date(values.date).toISOString().split('T')[0] : null,
        description: values.description,
        department_id: values.department,
        user_id: values.user,
        requisition_id: values.requisition,
        stockout_products: values.products.map((product) => ({
          product_id: product.product,
          quantity: product.quantity,
        })),
      });
    },
  });
  const handleProductValidation = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newProduct = Number(e.target.value);
    if (!newProduct) {
      setProductValidationError('Product is required.');
    } else {
      const productExists = formik.values.products.some(stockProduct => stockProduct.product == newProduct);
      if (productExists) {
        setProductValidationError("This Product is already selected");
      } else {
        setProductValidationError("");
      }
    }
  };
  const handleQuantityValidation = (e: any) => {
    const newQuantity = e.target.value;
    if (!newQuantity) {
      setQuantityValidationError('Quantity is required.');
    } else if (newQuantity > remainingQuantity) {
      setQuantityValidationError('Quantity must be less than Remaining Quantity');
    } else {
      setQuantityValidationError('');
    };
  };
  // functions to handle quotation products 
  const handleRequisitionChange = async (selectedRequisitionId) => {
    try {
      const requisitionProductsData = await stock_requisitions_products(selectedRequisitionId);
      if (Array.isArray(requisitionProductsData.data) && requisitionProductsData.data.length > 0) {
        // Directly set the product array based on requisition products
        formik.setFieldValue('products', requisitionProductsData.data.map((product) => ({
          product: product.productId,
          quantity: parseInt(product.requisitionProductQuantity, 10),
          remainingQuantity: parseInt(product.remainingStock, 10),
        })));

        return requisitionProductsData;
      } else {
        toast.error("No products found against this requisition", {
          position: 'top-right',
          autoClose: 3000,
        });
        // Set products as an empty array when no products are found
        formik.setFieldValue('products', []);
        return [];
      }
    } catch (error: any) {
      return [];
    };
  };
  const handleAddProduct = () => {
    formik.setFieldValue('products', [
      ...formik.values.products,
      { product: productValue, quantity: quantityValue, remainingQuantity: remainingQuantity },
    ]);
    setProductValue("")
    setQuantityValue("")
    setRemainingQuantity("")
  };

  const handleRemoveProduct = (index: number) => {
    const updatedProducts = [...formik.values.products];
    updatedProducts.splice(index, 1);
    formik.setFieldValue('products', updatedProducts);
  };
  const getProductNameById = (productId: number) => {
    const product = products.find((p) => p.product_id == productId);
    return product ? product.product_name : 'Product not found';
  };
  const getRemainingQuantity = (productId: number) => {
    const product = products.find((p) => p.product_id == productId);
    const remain: any = product ? product.remaining_stock : 'Product not found';
    setRemainingQuantity(remain)
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Stock Out</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Date</label>
              <div className='col-lg-8 fv-row'>
                <Flatpickr
                  value={dateState.date2}
                  onChange={([date2]) => {
                    setDateState({ date2 });
                    formik.setFieldValue('date', date2 ? date2.toISOString() : null);
                    // Check if date2 is null and set an error message
                    if (!date2) {
                      formik.setFieldError('dueDate', 'Due Date is required');
                    } else {
                      formik.setFieldError('dueDate', ''); // Clear the error message if date2 is selected
                    }
                  }}
                  className='form-control form-control-solid'
                  placeholder='Date'
                  options={{
                    dateFormat: 'd/m/Y',
                  }}
                />
                {formik.touched.date && formik.errors.date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.date}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Description</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Short Description'
                  {...formik.getFieldProps('description')}
                />
              </div>
            </div>
            <div className='row mb-6'>
              {/* department */}
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Department</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('department')}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleDepartmentChange(Number(e.target.value));
                  }}
                >
                  <option value=''>Select a Department...</option>
                  {Array.isArray(department) && department.length > 0 ? (
                    department.map((depart) => (
                      <option key={depart.id} value={depart.id}>
                        {depart.name}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>No Department available</option>
                  )}
                </select>
                {formik.touched.department && formik.errors.department && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.department}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              {/* User */}
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>User</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('user')}
                >
                  <option value=''>Select a User...</option>
                  {Array.isArray(users) && users.length > 0 ? (
                    users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.full_name}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>No user available</option>
                  )}
                </select>
                {formik.touched.user && formik.errors.user && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.user}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              {/* Requisition*/}
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Requisition</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('requisition')}
                  value={formik.values.requisition}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleRequisitionChange(Number(e.target.value));
                  }}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Select a Requisition...</option>
                  {requisitionArray.map((req) => (
                    <option key={req.id} value={req.id}>
                      {req.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row card mb-5 mb-xl-10'>
              <div
                className='card-header border-0 cursor-pointer row'
              >
                <div className='card-title m-3 col-lg-7'>
                  <h3 className='fw-bolder m-0'>Products for Stock Out</h3>
                </div>
                <div className='mt-3 col-lg-3'>
                  <Button data-toggle="tooltip" data-placement="bottom" title="Add Product" variant='primary'
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_1" >
                    Add Product
                  </Button>
                </div>
              </div>
              <div>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mx-2'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px required '>Product</th>
                      <th className='min-w-50px required'>Quantity</th>
                      <th className='min-w-100px required'>Remaining Quantity</th>
                      <th className='min-w-90px '>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.products.map((product, index) => (
                      <tr key={index}>
                        <td
                          {...formik.getFieldProps(`products[${index}].product`)}
                        >
                          <label className='form-control form-control-sm form-control-solid'>
                            {getProductNameById(formik.values.products[index].product)}
                          </label>
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-sm form-control-solid'
                            placeholder='Quantity'
                            {...formik.getFieldProps(`products[${index}].quantity`)}
                            onChange={(e) => {
                              formik.handleChange(e); // Use Formik's handleChange to update the form values
                            }}
                          />
                          {formik.values.products[index].remainingQuantity < formik.values.products[index].quantity ? (
                            <div className='text-danger'>The Quantity must be smaller than the Remaining Quantity</div>
                          ) : !formik.values.products[index].quantity ? (
                            <div className='text-danger'>Quantity is required</div>
                          ) : null}
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-sm form-control-solid'
                            placeholder='Quantity'
                            value={formik.values.products[index].remainingQuantity - formik.values.products[index].quantity}
                            readOnly
                          />
                        </td>
                        <td>
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Remove this product"
                            type='button'
                            className='btn btn-sm btn-danger '
                            onClick={() => handleRemoveProduct(index)}
                          >
                            Remove Product
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* Submit Button */}
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button data-toggle="tooltip" data-placement="bottom" title="Stock Out" type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Stock Out'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      {/* Add Product Modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Stock Products</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <>
                {/* ... Edit Form ... */}
                <Form>
                  <div className='form-group'>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>Product</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <Form.Select
                          className='form-select form-select-solid form-select-lg fw-bold'
                          value={productValue}
                          onChange={(e) => {
                            getRemainingQuantity(Number(e.target.value))
                            setProductValue(e.target.value)
                            handleProductValidation(e)
                          }}
                        >
                          <option value=''>Select a Product...</option>
                          {Array.isArray(products) && products.length > 0 ? (
                            products.map((product) => (
                              <option key={product.product_id} value={product.product_id}>
                                {product.product_name}
                              </option>
                            ))
                          ) : (
                            <option value='' disabled>No products available</option>
                          )}
                        </Form.Select>
                        {ProductValidationError && (
                          <div className='text-danger'>{ProductValidationError}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Remaining Qunatity
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Quantity'
                          value={remainingQuantity}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Qunatity
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Quantity'
                          value={quantityValue}
                          onChange={(e) => {
                            setQuantityValue(e.target.value)
                            handleQuantityValidation(e)
                          }}
                        />
                        {QuantityValidationError && (
                          <div className='text-danger'>{QuantityValidationError}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            </div>
            <div className='modal-footer'>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                {ProductValidationError || QuantityValidationError ? (
                  <button data-toggle="tooltip" data-placement="bottom" title="Add Product" type='button' className='btn btn-primary disabled'>
                    Add Product
                  </button>
                ) : (
                  <button
                    data-toggle="tooltip" data-placement="bottom" title="Add Product"
                    type='button'
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                    onClick={handleAddProduct}
                    disabled={loading}
                  >
                    {!loading && 'Add Product'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStockOut;