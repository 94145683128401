import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { mediaPath } from '../../../helper-functions/ImageStoragePath';
import { get_all_products } from '../../products/api/productsApi';
import { KTIcon } from '../../../../_metronic/helpers';
const imagePath = `${mediaPath()}/rejected.png`;
type Product = {
  id: number;
  name: string | undefined;
  product_type: string | undefined;
  price: string | undefined;
  category_title: string | undefined;
  sub_category_title: string | undefined;
  picture: string | undefined;
  remaining_stock_quantity: number | undefined;
  status: number | undefined;
};
function AssetsList() {
  const [productList, setProductList] = useState<Product[]>([]);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleData, setVisibleData] = useState<Product[]>([]);
  const {
    data: productsData,
    isLoading: isProductsLoading,
    isError: isProductsError,
  } = useQuery('products', get_all_products, {refetchOnWindowFocus: false});
  const navigate = useNavigate();
  useEffect(() => {
    if (productsData && productsData.data && Array.isArray(productsData.data)) {
      const assets = productsData.data.filter(product => product.product_type === 'asset');
      setProductList(assets);
    }
  }, [productsData]);
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems = productList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  useEffect(() => {
    if (productList.length > 0) {
      let sortedData = [...productList];
      if (sortBy) {
        sortedData?.sort((a:any, b:any) => {
          const key = sortBy.key || ''; // Fallback value for sortBy.key
          // Adjust sorting logic based on the data type of the column
          if (sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else if (key === 'price') {
            const aValue = a[key] || 0;
            const bValue = b[key] || 0;
            return sortBy.ascending ? aValue - bValue : bValue - aValue;
          }
            else if (key === 'remaining_stock_quantity') {
              const aValue = a[key] || 0;
              const bValue = b[key] || 0;
              return sortBy.ascending ? aValue - bValue : bValue - aValue;
            
          } else {
            const aValue = (a[key] || '').toString().toLowerCase();
            const bValue = (b[key] || '').toString().toLowerCase();
            return sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage,
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [productList, currentPage, selectedItemsPerPage, sortBy]);
  const handleSort = (key: string) => {
    if (key === 'status') {
      // Sort status column in ascending order first when clicked
      setSortBy({ key, ascending: !sortBy?.ascending });
    } else if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    }
    else if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    } else if (!sortBy || sortBy.key !== key && key === "price") {
      setSortBy({ key, ascending: true });
    } else if (key === "category_title") {
      setSortBy({ key, ascending: true });
    } else if (key === "remaining_stock_quantity") {
      setSortBy({ key, ascending: true });
    }
    else if (key === "sub_category_title") {
      setSortBy({ key, ascending: !sortBy.ascending });
    }
    else if (!sortBy || sortBy.key !== key && key === "status") {
      setSortBy({ key, ascending: true });
    }
    else if (key === "price") {
      setSortBy({ key, ascending: !sortBy.ascending });
    } else {
      setSortBy(null);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>List of Assets</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* Headings of columns in table  */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-170px'>Product Picture</th>
                <th className='min-w-180px'>Name <span onClick={() => handleSort('name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-170px'>Price <span onClick={() => handleSort('price')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-170px'>Category <span onClick={() => handleSort('category_title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-170px'>Sub Category <span onClick={() => handleSort('sub_category_title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-180px'>Remaining Quantity <span onClick={() => handleSort('remaining_stock_quantity')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Status <span onClick={() => handleSort('status')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
              </tr>
            </thead>
            <tbody>
              {isProductsError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isProductsLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : productList.length > 0 ? (
                visibleData.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'
                          >
                            {product.picture ? (
                              <img
                                src={product.picture}
                                width={70}
                                alt='Product'
                                className='symbol'
                              />
                            ) : (
                              <img
                                src={imagePath}
                                width={70}
                                height={70}
                                alt='No Picture'
                                className='symbol'
                              />
                            )}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {product.name}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {product.price}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {product.category_title}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {product.sub_category_title}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {product.remaining_stock_quantity}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>{product.status === 1 ? (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                            Active
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>
                            Inactive
                          </h3>
                        </div>
                      </div>
                    )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Products available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
          <div className='align-items-center row'>
            <div className='d-flex'>
              <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
              <select
                style={{ width: '60px' }}
                className='form-select form-select-md px-2 col-md-1'
                value={selectedItemsPerPage}
                onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
              >
                {itemsPerPageOptions.map(option => (
                  <option key={option} value={option} className='p-3 '>{option}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            {currentPage === 1 ? (
              <button
                className='btn btn-light'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            ) : (
              <button
                className='btn btn-primary'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            )}
            <button
              className='btn btn-primary ms-2'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
    </div>
  )
}
export default AssetsList;