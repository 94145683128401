import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  addDepartment,
  addDepartmentInitValues as initialValues,
} from '../../../modules/accounts/components/settings/SettingsModel'
import {useMutation, useQueryClient} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import { add_department } from '../api/departmentsApi'
const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().required("Department Name is required"),
  })

function AddDepartment() {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const mutation = useMutation(add_department, {
      onSettled: (data, error:any) => {
        if (!error) {
          toast.success(data.message, {
            position: 'top-right',
            autoClose: 3000,
          });
          navigate('/departments/departments-list');
          setLoading(false);
          queryClient.invalidateQueries('department');
        } else {
          if (error.response) {
            const errorMessage = error.response.data.message;
            toast.error(errorMessage, {
              position: 'top-right',
              autoClose: 2000,
            });
          } else {
            // Database or server error handling
            toast.error('Database Error: Unable to Process the Request.', {
              position: 'top-right',
              autoClose: 3000,
            });
          }
          setLoading(false);
        }
      },
    });
    
    const [loading, setLoading] = useState(false)
    const formik = useFormik<addDepartment>({
      initialValues,
      validationSchema: profileDetailsSchema,
      onSubmit: (values) => {
        setLoading(true)
        mutation.mutate({
        name: values.name,
        description: values.description,
        status: values.status,
        })
      },
    })
  return (
    <div className='card mb-5 mb-xl-10'>
    <div
      className='card-header border-0 cursor-pointer'
      role='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_account_profile_details'
      aria-expanded='true'
      aria-controls='kt_account_profile_details'
    >
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0'>Add Department</h3>
      </div>
    </div>
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Department Name</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder="Department Name"
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
              </div>
            </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Description</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <textarea
                className='form-control form-control-lg form-control-solid'
                placeholder="Description"
                {...formik.getFieldProps('description')}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Status</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('status')}
              >
                <option value=''>Select a Status...</option>
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </select>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button data-toggle="tooltip" data-placement="bottom" title="Add Department" type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Add Department'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  </div>
  )
}

export default AddDepartment