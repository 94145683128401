import { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { formatDateFlatpickr, formatDateWithoutTime } from '../../../../helper-functions/DateFunction';
import { add_requisitoin_document, all_requsition_assignees, delete_requisition_document, get_all_requisition_documents, get_all_requisitions, get_single_requisition, update_requisition, update_requisition_document } from '../api/requisitionsApi';
import { get_all_requisition_type } from '../../requisitionsType/api/requisitionsTypeApi';
import { get_all_active_products, get_all_products } from '../../../products/api/productsApi';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { mediaPath } from '../../../../helper-functions/ImageStoragePath';
import { KTSVG } from '../../../../../_metronic/helpers';
const imagePng = `${mediaPath()}/png.png`;
const imagePdf = `${mediaPath()}/pdf.png`;
const imageJpg = `${mediaPath()}/jpg-file.png`;
type Requisition = {
    id: number;
    title: string | undefined;
    description: string | undefined;
    due_date: string;
    status: string;
    requisition_type_id: number | undefined;
    requisition_type_title: string | undefined;
    updated_by: string;
    updated_at: string;
    requisition_products: {
        id: number;
        quantity: number;
        updated_by: string;
        updated_at: string;
        product_id: number;
        product_name: string;
        requisition_id: number;
    }[];
    requisition_assignees: {
        id: number;
        user_id: number;
        user_name: string;
        updated_by: string;
        updated_at: string;
        product_id: number;
        requisition_id: number;
    }[];
};
interface SingleRequisition {
    id: number;
    title: string | undefined;
    description: string | undefined;
    due_date: string;
    requisition_type_id: number | undefined;
    requisition_type_title: string | undefined;
    updated_by: string;
    updated_at: string;
    requisition_products: {
        id: number;
        quantity: number;
        updated_by: string;
        updated_at: string;
        product_id: number;
    }[];
    requisition_assignees: {
        id: number;
        full_name: string;
        updated_by: string;
        updated_at: string;
        product_id: number;
    }[];
};
interface RequisitionType {
    id: number
    title: string
};
interface Product {
    id: number;
    name: string;
};
interface User {
    id: number;
    full_name: string;
};
type RequisitionDocument = {
    id: number;
    document_title: string | undefined;
    document_description: string | undefined;
    document_path: string | undefined;
    updated_by: string | undefined;
    updated_at: string;
};
function EditRequisition() {
    const [selectedRequisition, setSelectedRequisition] = useState<Requisition | null>();
    const [selectedId, setSelectedId] = useState(0);
    const [singleRequisition, setSingleRequisition] = useState<SingleRequisition | null>(null);
    const [requisitionType, setRequisitionType] = useState<RequisitionType[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [dateValidationError, setDateValidationError] = useState('');
    const [titleValidationError, setTitleValidationError] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const [updatedProducts, setUpdatedProducts] = useState<{ product_id: number; quantity: number }[]>([]);
    const [assigneeValidationError, setAssigneeValidationError] = useState('');
    const [typeValidationError, setTypeValidationError] = useState('');
    const [productValidationError, setProductValidationError] = useState<string[]>([]);
    const [quantityValidationError, setQuantityValidationError] = useState<any>([]);
    const [documentList, setDocumentList] = useState<RequisitionDocument[]>([]);
    const [documentTitleValue, setDocumentTitleValue] = useState('');
    const [documentDescriptionValue, setDocumentDescriptionValue] = useState('');
    const [fileValue, setFileValue] = useState<File | null>(null);
    const [fileValidationError, setFileValidationError] = useState('');
    const [documentLoading, setDocumentLoading] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<RequisitionDocument | null>();
    const [loading, setLoading] = useState(false);
    const [productValue, setProductValue] = useState<Product[]>([]);
    const allQuotationProducts: any = []
    const location = useLocation();
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);
    const requisition = (location.state as { requisition: Requisition }).requisition;
    const {
        refetch,
    } = useQuery('requisition', get_all_requisitions, {refetchOnWindowFocus: false});
    useEffect(() => {
        setSelectedRequisition(requisition);
    }, [requisition]);
    const { data: requisitionTypeData } = useQuery('requisitionType', get_all_requisition_type, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (requisitionTypeData) {
            setRequisitionType(requisitionTypeData.data as RequisitionType[]);
        };
    }, [requisitionTypeData]);
    const { data: productData } = useQuery('products', get_all_active_products, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (productData) {
            setProducts(productData.data as Product[]);
        };
    }, [productData]);

    const { data: userData } = useQuery('users', all_requsition_assignees, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (userData) {
            setUsers(userData.data as User[]);
        }
    }, [userData]);
    const { data: singleRequisitionData, isLoading: isSingleRequisitionsLoading } = useQuery(['singleRequisition', selectedId], () => get_single_requisition(selectedId), {
        refetchOnWindowFocus: false,
        enabled: !!selectedId,
    });
    useEffect(() => {
        if (singleRequisitionData) {
            setSingleRequisition(singleRequisitionData.data as SingleRequisition);
        };
    }, [singleRequisitionData]);
    const { data: requisitionDocumentData, isLoading: isRequisitionDocumentLoading, isError, refetch: documentRefetch } = useQuery(
        ['requistionDocument', requisition.id],
        () => get_all_requisition_documents(requisition.id!),
        {
            refetchOnWindowFocus: false,
            enabled: !!requisition,
        }
    );
    useEffect(() => {
        if (requisitionDocumentData) {
            setDocumentList(requisitionDocumentData.data as RequisitionDocument[]);
        }
    }, [requisitionDocumentData]);
    const addDocumentMutation = useMutation(add_requisitoin_document, {
        onSuccess: (response) => {
            documentRefetch();
            setDocumentLoading(false);
            toast.success("Document is uploaded successfully", {
                position: 'top-right',
                autoClose: 3000,
            });
            if (formRef.current) {
                formRef.current.reset();
            }
            setDocumentTitleValue('');
            setDocumentDescriptionValue('');
            setFileValue(null)
        },
        onError: (error: any) => {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
                setDocumentLoading(false);
            }
        },
    });
    const handleAddDocument = () => {
        setDocumentLoading(true);
        addDocumentMutation.mutate({
            document_title: documentTitleValue,
            document_description: documentDescriptionValue,
            document_path: fileValue,
            requisition_id: requisition.id,
        });
    };
    const handleDeleteDocument = useMutation((id: number) => {
        return delete_requisition_document(id).then((response) => {
            toast.success(response.message, {
                position: "top-right",
                autoClose: 3000,
            });
        })
            .then(() => {
                return get_all_requisition_documents(requisition.id).then((data) => {
                    setDocumentList(data.data);
                });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                }
            });
    });
    //update document
    const updateDocumentMutation = useMutation(update_requisition_document, {
        onSuccess: (response) => {
            documentRefetch();
            setLoading(false);
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
        },
        onError: (error: any) => {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
                setLoading(false)
            }
        },
    });
    const handleUpdateDocument = () => {
        setLoading(true);
        if (selectedDocument) {
            const { id, document_title, document_description, document_path } = selectedDocument;
            updateDocumentMutation.mutate({
                id,
                document_title,
                document_description,
                document_path,
                requisition_id: requisition.id,
            });
        };
    };
    const HandleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFileValue(e.target.files[0]);
        };
    };
    const handleFileValidation = () => {
        if (!fileValue) {
            setFileValidationError('File is required.');
            return;
        } else {
            setFileValidationError('');
        }
    };
    // File Icon
    const renderFileIcon = (filePath: string | undefined) => {
        if (!filePath) {
            return null; // Handle the case where the file path is not available
        }
        const fileExtension = filePath.split('.').pop()?.toLowerCase();
        switch (fileExtension) {
            case 'pdf':
                return (
                    <img
                        src={imagePdf}
                        style={{ width: '60px', height: "auto" }}
                        alt='document-image'
                        className='document-image'
                    />
                );
            case 'png':
                return (
                    <img
                        src={imagePng}
                        style={{ width: '60px', height: "auto" }}
                        alt='document-image'
                    />
                );
            case 'jpeg':
            case 'jpg':
                return (
                    <img
                        src={imageJpg}
                        style={{ width: '60px', height: "auto" }}
                        alt='document-image'
                        className='document-image'
                    />
                );
            default:
                return (
                    <i className='bi bi-file fs-1'></i>
                ); // Default icon for unknown file types
        }
    };
    const handleRequisitionTitleChange = (e) => {
        if (selectedRequisition) {
            const newRequisitionTitle = e.target.value;
            if (!newRequisitionTitle) {
                setTitleValidationError('Requisition Title is required.');
            } else {
                setTitleValidationError('');
            };

            setSelectedRequisition({
                ...selectedRequisition,
                title: newRequisitionTitle,
            });
        };
    };
    const handleRequisitonDesChange = (e) => {
        if (selectedRequisition) {
            const newRequisitionDes = e.target.value;

            setSelectedRequisition({
                ...selectedRequisition,
                description: newRequisitionDes,
            });
        };
    };
    const handleRequisitionDateChange = (selectedDates) => {
        if (selectedRequisition) {
            const newRequisitionDate = selectedDates[0];
            if (!newRequisitionDate) {
                setDateValidationError('Due Date is required.');
            } else {
                setDateValidationError('');
            }
            newRequisitionDate?.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(newRequisitionDate);

            setSelectedRequisition({
                ...selectedRequisition,
                due_date: formattedDate,
            });
        }
    };

    const handleRequisitionTypeChange = (e) => {
        if (selectedRequisition) {
            const newRequisitionType = e.target.value;
            if (!newRequisitionType) {
                setTypeValidationError('Requisition Type is required.');
            } else {
                setTypeValidationError('');
            };
            const initialProducts = selectedRequisition.requisition_products.map((product) => ({
                product_id: product.product_id,
                quantity: product.quantity,
            }));
            setSelectedRequisition({
                ...selectedRequisition,
                requisition_type_id: newRequisitionType,
            });
            setUpdatedProducts(initialProducts);
        };
    };
    const updateRequisitionMutation = useMutation(update_requisition, {
        onSuccess: (response) => {
            refetch();
            setLoading(false);
            navigate('/requisitions/requisitions-list');
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
        },
        onError: (error: any) => {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
            }
        },
    });
    const handleUpdateRequisition = () => {
        setLoading(true);
        if (selectedRequisition) {
            const { id, title, description, due_date, requisition_type_id, requisition_products, requisition_assignees } = selectedRequisition;
            // Check if any product name is empty
            const isProductNameEmpty = requisition_products.some(product => !product.product_id);
            if (isProductNameEmpty) {
                toast.error('Product name is required for all products.', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                setLoading(false);
                return;
            }
            const updatedProducts = requisition_products.map((product) => ({
                id: product.id,
                quantity: product.quantity,
                product_id: product.product_id,
            }));
            const updatedAssignees = requisition_assignees.map((assignee) => ({
                id: assignee.id,
                user_id: assignee.user_id,
            }));
            updateRequisitionMutation.mutate({
                id,
                title,
                description,
                due_date,
                requisition_type_id,
                products: updatedProducts,
                assignees: updatedAssignees,
            });
        };
    };
    const handleAssigneeChange = (selectedOptions) => {
        if (!selectedOptions || selectedOptions.length === 0) {
            setAssigneeValidationError('Assignee is required.');
        } else {
            setAssigneeValidationError('');
        }
        if (selectedRequisition) {
            const updatedAssignees = selectedOptions.map((selectedOption, index) => ({
                id: index + 1,
                user_id: parseInt(selectedOption.value, 10),
                user_name: selectedOption.label,
            }));
            setSelectedRequisition({
                ...selectedRequisition,
                requisition_assignees: updatedAssignees,
            });
        }
    };
    const handleProductChange = (e, index) => {
        const { value } = e.target;
        if (!value) {
            setProductValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = 'Product is required.';
                return updatedErrors;
            });
        } else {
            setProductValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = '';
                return updatedErrors;
            });
        }
        if (selectedRequisition) {
            const updatedProducts = [...selectedRequisition.requisition_products];
            updatedProducts[index].product_id = parseInt(value, 10);
            setSelectedRequisition({
                ...selectedRequisition,
                requisition_products: updatedProducts,
            });
        }
    };
    const handleProductListChange = (e) => {
        const newProduct = e.target.value
        const newProductValue = products.find((product) => product.id == newProduct);
        if (newProductValue) {
            //setting an array to check if the vendor is already selected
            const currentArray = [...productValue];
            currentArray.push(newProductValue);
            setProductValue(currentArray);
        }
    };
    
    const handleQuotationProductsList = (productIndex) => {
        allQuotationProducts.push(selectedRequisition?.requisition_products[productIndex].product_id)
    }

    const handleQuantityChange = (e, index) => {
        const { value } = e.target;
        if (value === '') {
            setQuantityValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = 'Quantity is required.';
                return updatedErrors;
            });
        } else {
            setQuantityValidationError((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = ''; // Clear the error for the specific index
                return updatedErrors;
            });
        }
        if (selectedRequisition) {
            const updatedProducts = [...selectedRequisition.requisition_products];
            updatedProducts[index].quantity = value !== '' ? parseInt(value, 10) : 0;

            setSelectedRequisition({
                ...selectedRequisition,
                requisition_products: updatedProducts,
            });
        }
    };

    const handleAddProduct = () => {
        // Check if the products list is not empty
        if (products.length === 0) {
            // If the products list is empty, show an error
            toast.error('No products available. Add products before adding to the requisition.', {
                position: 'top-right',
                autoClose: 3000,
            });
            return;
        }
        // Check if the last product in the list is not empty
        if (
            selectedRequisition &&
            selectedRequisition.requisition_products.length > 0 &&
            !selectedRequisition.requisition_products[selectedRequisition.requisition_products.length - 1].product_id
        ) {
            // If the last product is empty, show an error
            toast.error('Product is required before adding a new one.', {
                position: 'top-right',
                autoClose: 3000,
            });
            return;
        }
        setSelectedRequisition((prev: any) => ({
            ...prev,
            requisition_products: [
                ...prev.requisition_products,
                { id: null, quantity: 0, product_id: null, updated_by: '', updated_at: '' },
            ],
        }));
    };

    const handleRemoveProduct = (index) => {
        setSelectedRequisition((prev: any) => ({
            ...prev,
            requisition_products: [
                ...prev.requisition_products.slice(0, index),
                ...prev.requisition_products.slice(index + 1),
            ],
        }));
    };
    const getAssigneeNameById = (assigneeId) => {
        const assignee = users.find((p) => p.id === assigneeId);
        return assignee ? assignee.full_name : 'Assignee not found';
    };
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Edit Requisition</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/requisitions/requisitions-list')}
                    >
                        List of Requisition
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {selectedRequisition ? (
                        <>
                            {/* ... Edit Form ... */}
                            <Form>
                                <div className='mb-2 row mb-6'>
                                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Requisition Title</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            id='requisitionTitle'
                                            aria-describedby='requisitionTitle'
                                            placeholder='Enter New Title'
                                            value={selectedRequisition ? selectedRequisition.title : ''}
                                            onChange={(e) => handleRequisitionTitleChange(e)}
                                        />
                                        {titleValidationError && (
                                            <div className='text-danger'>{titleValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='mb-2 row mb-6'>
                                    <label className='col-lg-4 ml-4 col-form-label fw-bold fs-6'>Description</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            id='UpdateProductShortDes'
                                            aria-describedby='UpdateProductShortDes'
                                            placeholder='Enter Short Description'
                                            value={selectedRequisition ? selectedRequisition.description : ''}
                                            onChange={(e) => handleRequisitonDesChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className='mb-2 row mb-6'>
                                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Due Date</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Flatpickr
                                            onChange={(selectedDates) => handleRequisitionDateChange(selectedDates)}
                                            className='form-control form-control-solid'
                                            placeholder={formatDateWithoutTime(selectedRequisition.due_date)}
                                            options={{
                                                dateFormat: 'd/m/Y',
                                            }}
                                        />
                                        {dateValidationError && (
                                            <div className='text-danger'>{dateValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='mb-2 row mb-6'>
                                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Requisition Assignee</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Select
                                            isMulti
                                            placeholder="Select "
                                            options={users.map((user) => ({
                                                value: user.id,
                                                label: user.full_name,
                                            }))}
                                            className="basic-multi-select form-select-lg p-2 bg-light text-black"
                                            classNamePrefix="select border-light fw-bold"
                                            value={selectedRequisition.requisition_assignees.map((assignee) => ({
                                                value: assignee.user_id,
                                                label: getAssigneeNameById(assignee.user_id),
                                            }))}
                                            onChange={(selectedOptions) => {
                                                // Assuming handleAssigneeChange receives an array of selected options
                                                handleAssigneeChange(selectedOptions);
                                            }}
                                        />
                                        {assigneeValidationError && (
                                            <div className='text-danger'>{assigneeValidationError}</div>
                                        )}
                                    </div>
                                </div>

                                <div className='mb-2 row mb-6'>
                                    <Form.Label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Requisition Type </Form.Label>
                                    <div className='col-lg-8 fv-row'>
                                        <Form.Group controlId='requisitionType'>
                                            <Form.Select
                                                className='form-control form-control-lg form-control-solid'
                                                name='requisitionType'
                                                value={selectedRequisition ? selectedRequisition.requisition_type_id : ''}
                                                onChange={(e) => handleRequisitionTypeChange(e)}
                                            >
                                                <option value=''>Select a Requisition Type...</option>
                                                {requisitionType.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.title}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        {typeValidationError && (
                                            <div className='text-danger'>{typeValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                {selectedRequisition && selectedRequisition.requisition_products && (
                                    <div className='row '>
                                        <div className='col-lg-4'></div>
                                        <div className='col-lg-8'>
                                            <div className='mt-3'>
                                                <button data-toggle="tooltip" data-placement="bottom" title="Add Product" type='button' className='btn btn-primary mt-3' onClick={handleAddProduct}>
                                                    Add Product
                                                </button>
                                            </div>
                                            {selectedRequisition.requisition_products.map((product, index) => (
                                                <>
                                                {handleQuotationProductsList(index)}
                                                <div key={index} className='border border-secondary rounded p-3 mt-3'>
                                                    {/* Product Name */}
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Product Name</label>
                                                        <div className='col-lg-8 fv-row'>
                                                            <select
                                                                className='form-select form-select-lg form-select-solid'
                                                                value={product.product_id}
                                                                onChange={(e) => {handleProductChange(e, index)
                                                                    handleProductListChange(e)}}
                                                            >
                                                                <option value="">Select a Product</option>
                                                                {products.map((productOption) => (
                                                                        productValue.includes(productOption) || allQuotationProducts.includes(productOption.id) ? (
                                                                            <option key={productOption.id} value={productOption.id} disabled className="bg-primary text-white">
                                                                                {productOption.name}
                                                                            </option>
                                                                        ) : (
                                                                            <option key={productOption.id} value={productOption.id}>
                                                                                {productOption.name}
                                                                            </option>
                                                                        )
                                                                ))}
                                                            </select>
                                                            {productValidationError[index] && (
                                                                <div className='text-danger'>{productValidationError[index]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Add quantity input */}
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Quantity</label>
                                                        <div className='col-lg-8 fv-row'>
                                                            <input
                                                                type='number'
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Enter quantity'
                                                                value={product.quantity}
                                                                onChange={(e) => handleQuantityChange(e, index)}
                                                            />
                                                            {quantityValidationError[index] && (
                                                                <div className='text-danger'>{quantityValidationError[index]}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="Remove Product"
                                                        type='button'
                                                        className='btn btn-danger mt-3'
                                                        onClick={() => handleRemoveProduct(index)}
                                                    >
                                                        Remove Product
                                                    </button>
                                                </div>
                                                </>
                                            ))}

                                        </div>
                                    </div>
                                )}

                                <div className='row'>
                                    <div className='col-lg-4'></div>
                                    <div className=' col-lg-8'>
                                        {documentList.length > 0 && (
                                            documentList?.map((document, index) => (
                                                <div className='card mb-5 mb-xl-10 mt-3 border-danger' key={index}>
                                                    <div className='card-header'>
                                                        <div className='card-title m-0'>
                                                            <h3 className='fw-bolder m-0'>Documents</h3>
                                                        </div>
                                                    </div>
                                                    <Form className='card-body row'>
                                                        <div className=' col-lg-7 mt-2 mx-4' >
                                                            <div className='form-group'>
                                                                <div className='row mb-6'>
                                                                    <div className='col-lg-10 fv-row'>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control form-control-lg form-control-solid'
                                                                            placeholder='Title'
                                                                            value={document.document_title}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='row mb-6'>
                                                                    <div className='col-lg-10 fv-row'>
                                                                        <input
                                                                            type='textarea'
                                                                            className='form-control form-control-lg form-control-solid'
                                                                            placeholder='Description'
                                                                            value={document.document_description}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-3 mx-6 mt-4'>
                                                            <div className="symbol symbol-125px me-10 mb-10 ">
                                                                <div className="symbol-label fs-6 fw-bold text-success">
                                                                    {renderFileIcon(document.document_path)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                                        <button
                                                            data-toggle="tooltip" data-placement="bottom" title="Remove this product"
                                                            type='button'
                                                            className='btn btn-primary mx-2'
                                                            onClick={() => setSelectedDocument(document)}
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#kt_modal_3'
                                                        >
                                                            Update Document
                                                        </button>
                                                        <button
                                                            data-toggle="tooltip" data-placement="bottom" title="Remove this product"
                                                            type='button'
                                                            className='btn btn-danger '
                                                            onClick={() => setSelectedDocument(document)}
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#kt_modal_1'
                                                        >
                                                            Remove Document
                                                        </button>
                                                    </div>
                                                </div>
                                            )))}
                                    </div>
                                </div>
                            </Form>

                            <div className='row'>
                                <div className='col-lg-4'></div>
                                <div className=' col-lg-8'>
                                    <div className='card mb-5 mb-xl-10 mt-3 border-primary'>
                                        <div className='card-header'>
                                            <div className='card-title m-0'>
                                                <h3 className='fw-bolder m-0'>Add Document</h3>
                                            </div>
                                        </div>
                                        <Form ref={formRef} className='card-body'>
                                            <div >
                                                <div className='form-group'>
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                            <span className='required'>Title</span>
                                                        </label>
                                                        <div className='col-lg-8 fv-row'>
                                                            <input
                                                                type='text'
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Title'
                                                                value={documentTitleValue}
                                                                onChange={(e) => {
                                                                    setDocumentTitleValue(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                            Description
                                                        </label>
                                                        <div className='col-lg-8 fv-row'>
                                                            <input
                                                                type='text'
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Description'
                                                                value={documentDescriptionValue}
                                                                onChange={(e) => {
                                                                    setDocumentDescriptionValue(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                            File
                                                        </label>
                                                        <div className='col-lg-8 fv-row'>
                                                            <input
                                                                type='file'
                                                                className='form-control form-control-lg form-control-solid'
                                                                onChange={(e) => {
                                                                    HandleFileChange(e)
                                                                    handleFileValidation()
                                                                }}
                                                                name='picture'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                            {!fileValidationError ? (
                                                <button data-toggle="tooltip" data-placement="bottom" title="Add Product" type='button' className='btn btn-primary disabled'>
                                                    Add Document
                                                </button>
                                            ) : (
                                                <button
                                                    data-toggle="tooltip" data-placement="bottom" title="Add Document"
                                                    type='button'
                                                    className='btn btn-primary'
                                                    onClick={handleAddDocument}
                                                    disabled={documentLoading}
                                                >
                                                    {!documentLoading ? 'Add Document' : 'Please wait...'}
                                                    {documentLoading && (
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {typeValidationError || dateValidationError || productValidationError.some(error => error) || quantityValidationError.some(error => error) || assigneeValidationError ? (
                        <button type='button' className='btn btn-primary disabled'>
                            Update Requisition
                        </button>
                    ) : (
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Update Requisition"
                            type='button'
                            className='btn btn-primary'
                            onClick={handleUpdateRequisition}
                            disabled={loading}
                        >
                            {!loading && 'Update Requisition'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    )}
                </div>
            </div>
            {/* delete modal */}
            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Document Confirmation</h5>

                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            {selectedDocument ? (
                                <>
                                    <p className='text-danger'>Note: The document will be deleted permanently!</p>
                                    <p className="mb-2">
                                        <span className="fw-bold">Document Title: </span> {selectedDocument.document_title}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold">Desciption </span> {selectedDocument.document_description}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className="modal-footer">
                            <button data-toggle="tooltip" data-placement="bottom" title="close" type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Document"
                                type="button"
                                onClick={() => {
                                    if (selectedDocument && selectedDocument.id) {
                                        handleDeleteDocument.mutate(selectedDocument.id);
                                    }
                                }}
                                className="btn btn-danger"
                                data-bs-dismiss="modal"
                            >
                                Delete Document
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* edit modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Document</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedDocument ? (
                                <>
                                    <div className='mb-2 row mb-6'>
                                        <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>
                                            Title
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                value={selectedDocument.document_title}
                                                className='form-control form-control-lg form-control-solid'
                                                onChange={(e) => {
                                                    setSelectedDocument({
                                                        ...selectedDocument,
                                                        document_title: e.target.value,
                                                    })
                                                }}
                                                type='text'
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-2 row mb-6'>
                                        <label className='col-lg-4 ml-4 col-form-label fw-bold fs-6'>
                                            Description
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                value={selectedDocument.document_description}
                                                className='form-control form-control-lg form-control-solid'
                                                onChange={(e) => {
                                                    setSelectedDocument({
                                                        ...selectedDocument,
                                                        document_description: e.target.value,
                                                    })
                                                }}
                                                type='text'
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Update this Docuument"
                                type="button"
                                onClick={handleUpdateDocument}
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                            >
                                Update Document
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditRequisition