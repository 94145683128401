import { useState, useEffect, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { formatDateWithoutTime } from '../../../../helper-functions/DateFunction';
import { all_requsition_assignees, get_requisitions_status, get_single_requisition } from '../api/requisitionsApi';
import { get_all_products } from '../../../products/api/productsApi';
import { toast } from 'react-toastify';

interface SingleRequisition {
  id: number | undefined;
  title: string | undefined;
  description: string | undefined;
  due_date: string;
  requisition_type_id: number | undefined;
  requisition_type_title: string | undefined;
  status: string | undefined;
  updated_by: string;
  updated_at: string;
  requisition_products: {
    id: number;
    quantity: number;
    updated_by: string;
    updated_at: string;
    product_id: number;
  }[];
  requisition_assignees: {
    id: number;
    user_id: number;
    user_name: string;
    updated_by: string;
    updated_at: string;
    product_id: number;
  }[];
}

interface Product {
  id: number;
  name: string;
}
interface User {
  id: number;
  full_name: string;
};

function ViewRequisition() {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [singleRequisition, setSingleRequisition] = useState<SingleRequisition | null>(null);
  const [statusFlag, setStatusFlag] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const id = (location.state as { id: number }).id;
  const { data: singleRequisitionDataId, isLoading, error } = useQuery(['SingleRequisitionId', id], () =>
    setSelectedId(id), {refetchOnWindowFocus: false}
  );
  const { data: productData } = useQuery('products', get_all_products, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (productData) {
      setProducts(productData.data as Product[]);
    }
  }, [productData]);
  const { data: singleRequisitionData, isLoading: isSingleRequisitionsLoading, refetch } = useQuery(
    ['singleRequisition', selectedId],
    () => get_single_requisition(selectedId!),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedId,
    }
  );

  useEffect(() => {
    if (singleRequisitionData) {
      setSingleRequisition(singleRequisitionData.data as SingleRequisition);
    }
  }, [singleRequisitionData]);
  const { data: userData } = useQuery('users', all_requsition_assignees, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (userData) {
      setUsers(userData.data as User[]);
    }
  }, [userData]);
  const { data: statusData } = useQuery(['RequisitionStatus', selectedId, statusFlag], () => get_requisitions_status(selectedId, statusFlag), {
    refetchOnWindowFocus: false,
    enabled: !!selectedId,
    onSuccess: () => {
      refetch()
    }
  });
  const handleStatusAccepted = (id: any) => {
    setSelectedId(id);
    setStatusFlag('Accepted');
    toast.success('Status updated to Accepted', {
      position: 'top-right',
      autoClose: 3000,
    });
  };
  const handleStatusRejected = (id: any) => {
    setSelectedId(id);
    setStatusFlag('Rejected')
    toast.success('Status updated to Rejected', {
      position: 'top-right',
      autoClose: 3000,
    });
  };
  const getProductNameById = (productId: number) => {
    const product = products.find((p) => p.id === productId);
    return product ? product.name : 'Product not found';
  };
  const getAssigneeNameById = (assigneeId) => {
    const assignee = users.find((p) => p.id === assigneeId);
    return assignee ? assignee.full_name : 'Assignee not found';
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>View Requisition</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/requisitions/requisitions-list')}
          >
            List of Requisition
          </button>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {isSingleRequisitionsLoading ? (
            <tr>
              <td colSpan={6}>
                <div className='text-center'>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          ) : singleRequisition ? (
            <>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                    <span>Requisition Title</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singleRequisition.title}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Description</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singleRequisition.description}
                    </label>
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                    <span>Due Date</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {formatDateWithoutTime(singleRequisition.due_date)}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                    <span>Requisition Assignee</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singleRequisition.requisition_assignees.map((assignee, index) => (
                        <span key={index}>
                          <div>{getAssigneeNameById(assignee.user_id)}</div>
                        </span>
                      ))}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                    <span>Requisition Type</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singleRequisition.requisition_type_title}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <h3 >
                  Requisition Products:
                </h3>
                <div className='row mb-6'>
                  {/* <div className='form-group col-lg-8 fv-row'> */}
                  {singleRequisition.requisition_products.map((product, index) => (
                    <>
                      <div className='col-lg-4 ml-4 fw-bold fs-6'></div>
                      <div key={index} className='form-group col-lg-8 fv-row pb-3 mb-2 border border-secondary rounded'>
                        <div className='row'>
                          {/* Product Name */}
                          <label className='col-lg-4 col-form-label fw-bold fs-6 mt-5 required'> <span>Product Name</span>  </label>
                          <div className='col-lg-8 fv-row  mt-5'>
                            <label className='form-control form-control-lg form-control-solid'>
                              {getProductNameById(product.product_id)}
                            </label>
                          </div>
                          <label className='col-lg-4 col-form-label fw-bold fs-6 mt-5 required'> <span>Quantity</span>  </label>
                          <div className='col-lg-8 fv-row mt-5'>
                            <label className='form-control form-control-lg form-control-solid'>
                              {product.quantity}
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className='card-footer border d-flex justify-content-end py-6 px-9'>
        {singleRequisition?.status === 'Accepted' ? (
          <button data-toggle="tooltip" data-placement="bottom" title="Add New Requisition" type='submit' className='btn btn-secondary mx-2' >
            Approved
          </button>
        ) : (
          <button data-toggle="tooltip" data-placement="bottom" title="Add New Requisition" type='submit' className='btn btn-success '
            onClick={() => {
              handleStatusAccepted(singleRequisition?.id);
            }}>
            Approve
          </button>
        )}
        {singleRequisition?.status === 'Rejected' ? (
          <button data-toggle="tooltip" data-placement="bottom" title="Add New Requisition" type='submit' className='btn btn-secondary mx-2' >
            Rejected
          </button>
        ) : (
          <button data-toggle="tooltip" data-placement="bottom" title="Add New Requisition" type='submit' className='btn btn-danger'
            onClick={() => {
              handleStatusRejected(singleRequisition?.id);
            }}>
            Reject 
          </button>
        )}
      </div>
    </div>
  )
}
export default ViewRequisition