import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { KTIcon, KTSVG } from '../../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { formatDateFlatpickr, formatDateWithTime, formatDateWithoutTime } from '../../../../helper-functions/DateFunction';
import { delete_quotation, get_all_quotations, get_filtered_quotations } from '../api/quotationsApi';
import { permissionCheck } from '../../../../helper-functions/PermissionCheck';
import Flatpickr from 'react-flatpickr';
import { get_all_requisitions } from '../../../requisitions/requisitions/api/requisitionsApi';

export type Quotation = {
    id: number | undefined;
    title: string | undefined;
    description: string | undefined;
    due_date: string;
    requisition_id: number | undefined;
    requisition_title: string | undefined;
    updated_by_name: string;
    updated_at: string;
    quotation_products: {
        id: number;
        product_id: number;
        supplier_id: number;
        price: number;
        quantity: number;
        updated_by: string;
        updated_at: string;
    }[];
};
type Requisition = {
    id: number;
    title: string | undefined;
}
var quotation_add, quotation_edit, quotation_view, quotation_logs, quotation_delete
function QuotationsList() {
    const [quotationList, setQuotationList] = useState<Quotation[]>([]);
    const [selectedQuotation, setSelectedQuotation] = useState<Quotation | null>();
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleData, setVisibleData] = useState<Quotation[]>([]);
    const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null);
    const fromDateRef = useRef<Flatpickr>(null); // Ref for the from date Flatpickr instance
    const toDateRef = useRef<Flatpickr>(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [requisitionList, setRequisitionList] = useState<Requisition[]>([]);
    const [requisitionId, setRequisitionId] = useState('');
    const navigate = useNavigate();
    quotation_add = permissionCheck('quotation-create')
    quotation_edit = permissionCheck('quotation-update')
    quotation_view = permissionCheck('quotation-view')
    quotation_logs = permissionCheck('quotation-activity-log')
    quotation_delete = permissionCheck('quotation-delete')
    const {
        data: quotationsData,
        isLoading: isQuotationsLoading,
        isError: isQuotationsError,
        refetch, }
        = useQuery('quotations', get_all_quotations, { refetchOnWindowFocus: false });

    useEffect(() => {
        if (quotationsData) {
            setQuotationList(quotationsData.data);
        }
    }, [quotationsData]);

    const { data: requisitionsData } = useQuery('requisitions', get_all_requisitions, { refetchOnWindowFocus: false });
    useEffect(() => {
        if (requisitionsData) {
            setRequisitionList(requisitionsData.data);
        };
    }, [requisitionsData]);

    const itemsPerPage = [10, 20, 30, 50, 100];
    const totalItems = quotationList.length;
    const totalPages = Math.ceil(totalItems / selectedItemsPerPage)

    useEffect(() => {
        if (quotationList.length > 0) {
            let sortedData = [...quotationList];
            if (sortBy) {
                sortedData.sort((a, b) => {
                    const aValue = (a[sortBy?.key] || '') as string;
                    const bValue = (b[sortBy?.key] || '') as string;
                    return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                });
            }
            setVisibleData(
                sortedData.slice(
                    (currentPage - 1) * selectedItemsPerPage, // Update slice based on selected items per page
                    currentPage * selectedItemsPerPage
                )
            );
        }
    }, [quotationList, currentPage, selectedItemsPerPage, sortBy]);
    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const searchTerm = e.currentTarget.value;
        setSearchInput(searchTerm);

        const filteredItems = quotationsData.data.filter((quotation) => {
            return (
                quotation.title?.toLowerCase().includes(searchTerm) ||
                quotation.requisition_title?.toLowerCase().includes(searchTerm)
            );
        });
        setQuotationList(filteredItems);
    };
    const handleDeleteQuotation = useMutation((id: number) => {
        return delete_quotation(id).then((response) => {
            toast.success(response.message, {
                position: "top-right",
                autoClose: 3000,
            });
        })
            .then(() => {
                return get_all_quotations().then((data) => {
                    setQuotationList(data.data);
                })
            })
            .catch((error) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    })
                };
            });
    });
    const handleDateFromChange = (date) => {
        if (date && date[0]) {
            const selectedDate = new Date(date[0]);
            selectedDate.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(selectedDate);
            setFromDate(formattedDate);

            // Update the "To Date" Flatpickr options
            if (toDateRef.current) {
                toDateRef.current.flatpickr.set('minDate', selectedDate);
            }
        } else {
            setFromDate('');
            // Reset the "To Date" picker minDate when "From Date" is cleared
            if (toDateRef.current) {
                toDateRef.current.flatpickr.set('minDate', null);
            }
        }
    };
    const handleDateToChange = (date) => {
        if (date && date[0]) {
            const selectedDate = new Date(date[0]);
            selectedDate.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(selectedDate);
            setToDate(formattedDate);
        } else {
            setToDate('');
        }
    };
    const { data: filterData, refetch: filterRefetchQuotation } = useQuery(
        'filterData',
        () => get_filtered_quotations(requisitionId, fromDate, toDate),
        {
            refetchOnWindowFocus: false,
            onSuccess: (filteredData) => {
                setQuotationList(filteredData.data);
            },
            onError: (error: any) => {
                // Error handling
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    });
                }
            },
            enabled: false, // Set initial enable state to false
        }
    );
    const handleApplyFilters = () => {
        filterRefetchQuotation({ // Enable the query before refetch
            throwOnError: true,
        });
    };
    const handleClearFilters = () => {
        if (fromDateRef.current) {
            fromDateRef.current.flatpickr.clear();
        }
        if (toDateRef.current) {
            toDateRef.current.flatpickr.clear();
        }
        setFromDate('');
        setToDate('');
        setRequisitionId('')
        if (quotationsData) {
            setQuotationList(quotationsData.data);
        }
    };
    const handleSort = (key: string) => {
        if (sortBy && sortBy.key === key) {
            setSortBy({ key, ascending: !sortBy.ascending });
        } else if (!sortBy || sortBy.key !== key || sortBy.ascending) {
            setSortBy({ key, ascending: true });
        } else {
            setSortBy(null);
        }
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleQuotationLogs = (quotation: any) => {
        navigate("/quotations/quotation-logs", { state: { id: quotation.id } });
    };
    const handleEditQuotation = (quotation: any) => {
        navigate("/quotations/edit-quotation", { state: { quotation: quotation } });
    };
    const handleViewQuotation = (quotation: any) => {
        navigate("/quotations/view-quotation", { state: { id: quotation.id } });
    };
    const handleManageDocuments = (quotation: any) => {
        navigate("/quotations/quotation-document", { state: { id: quotation.id } });
    }
    return (
        <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Quotations List</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a Quotation'
                >
                    {quotation_add && (
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Navigate to Add Quotation"
                            className='btn btn-sm btn-light-dark'
                            onClick={() => navigate('/quotations/add-quotation')}
                        >
                            <KTIcon iconName='plus' className='fs-3' />
                            Add Quotations
                        </button>
                    )}
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            data-toggle="tooltip" data-placement="bottom" title="Search a Quotation"
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Quotation'
                            value={searchInput}
                            onChange={handleInputChange}
                        />
                    </div>
                    {/* end::Search */}
                    <div className='card-header border-0 pt-5 mx-2 d-flex flex-column align-items-end'>
                        <div className='card-toolbar d-flex align-items-center'>
                            <div>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    value={requisitionId}
                                    onChange={(e) => setRequisitionId(e.target.value)}
                                >
                                    <option value=''>Select a Requisition...</option>
                                    {Array.isArray(requisitionList) && requisitionList.length > 0 ? (
                                        requisitionList.map((requisition) => (
                                            <option key={requisition.id} value={requisition.id}>
                                                {requisition.title}
                                            </option>
                                        ))
                                    ) : (
                                        <option value='' disabled>No Requisition available</option>
                                    )}
                                </select>
                            </div>
                            <div className='mx-3'>
                                <Flatpickr
                                    ref={fromDateRef}
                                    onChange={handleDateFromChange}
                                    className='form-control form-control-solid'
                                    placeholder='From Date'
                                    options={{
                                        dateFormat: 'd/m/Y',
                                    }}
                                />
                            </div>
                            <div>
                                <Flatpickr
                                    ref={toDateRef}
                                    onChange={handleDateToChange}
                                    className='form-control form-control-solid'
                                    placeholder='To Date'
                                    options={{
                                        dateFormat: 'd/m/Y',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
                        <div>
                            <button
                                className='btn btn-primary me-3'
                                onClick={handleApplyFilters}
                            >
                                Apply Filters
                            </button>
                            <button
                                className='btn btn-light-danger'
                                onClick={handleClearFilters}
                            >
                                Clear Filters
                            </button>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-150px'>Title  <span onClick={() => handleSort('title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px'>Requisition Title  <span onClick={() => handleSort('requisition_title ')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px'>Valid Until  <span onClick={() => handleSort('due_date')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-150px'>Documents </th>
                                <th className='min-w-140px'>Updated By  <span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                <th className='min-w-120px'>Updated At  <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                                {quotation_delete || quotation_edit || quotation_logs || quotation_view ? (
                                    <th className='min-w-100px text-center'>Actions</th>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            {isQuotationsError ? (
                                <tr>
                                    <td>Error loading data. Please try again later.</td>
                                </tr>
                            ) : isQuotationsLoading ? (
                                <tr>
                                    <td colSpan={6}>
                                        <div className='text-center'>
                                            <div className='spinner-border' role='status'>
                                                <span className='visually-hidden'>Loading...</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : quotationList.length > 0 ? (
                                visibleData.map((quotation) => (
                                    <tr key={quotation.id}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-start flex-column">
                                                    <h3 className="text-dark fw-bold cursor-pointer text-hover-success fs-6"
                                                        onClick={() => handleViewQuotation(quotation)}
                                                    >
                                                        {quotation.title}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-start flex-column">
                                                    <h3 className="text-dark fw-bold cursor-pointer fs-6">
                                                        {quotation.requisition_title}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-start flex-column">
                                                    <h3 className="text-dark fw-bold cursor-pointer fs-6">
                                                        {formatDateWithoutTime(quotation.due_date)}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-start flex-column">
                                                    <h3 className="text-dark fw-bold cursor-pointer fs-6">
                                                        <button
                                                            data-toggle="tooltip" data-placement="bottom" title="Edit Requisition"
                                                            onClick={() => handleManageDocuments(quotation)}
                                                            className='btn btn-primary btn-light-dark btn-active-color-warning btn-sm me-1'
                                                        >
                                                            Manage Documents
                                                        </button>
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-column w-100 me-2">
                                                <div className="d-flex flex-stack mb-2 cursor-pointer">
                                                    {quotation.updated_by_name}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-end">
                                            <div className="d-flex flex-column w-100 me-2">
                                                <div className="d-flex flex-stack mb-2 cursor-pointer">
                                                    {formatDateWithTime(quotation.updated_at)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-end flex-shrink-0">
                                                {quotation_edit && (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="Edit Quotation"
                                                        onClick={() => handleEditQuotation(quotation)}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                    >
                                                        <KTIcon iconName="pencil" className="fs-3" />
                                                    </button>
                                                )}
                                                {quotation_view && (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="See Quotation Detail"
                                                        onClick={() => handleViewQuotation(quotation)}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='eye' className='fs-3' />
                                                    </button>
                                                )}
                                                {quotation_logs && (
                                                    <button
                                                        onClick={() => {
                                                            handleQuotationLogs(quotation);
                                                        }}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-placement='top'
                                                        data-bs-custom-class='tooltip-dark'
                                                        title='Quotation Logs'
                                                    >
                                                        <i className='bi bi-journal-text'></i>
                                                    </button>
                                                )}
                                                {quotation_delete && (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="Delete Quotation"
                                                        onClick={() => setSelectedQuotation(quotation)}
                                                        className="btn btn-icon btn-light-dark btn-active-color-danger btn-sm"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_1"
                                                    >
                                                        <KTIcon iconName="trash" className="fs-3" />
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>No Quotations available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='card-footer d-flex justify-content-between py-4'>
                <div className='align-items-center row'>
                    <div className='d-flex'>
                        <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
                        <select
                            style={{ width: '60px' }}
                            className='form-select form-select-md px-2 col-md-1'
                            value={selectedItemsPerPage}
                            onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
                        >
                            {itemsPerPage.map(option => (
                                <option key={option} value={option} className='p-3 '>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    {currentPage === 1 ? (
                        <button
                            className='btn btn-light'
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    ) : (
                        <button
                            className='btn btn-primary'
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    )}
                    <button
                        className='btn btn-primary ms-2'
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
            {/* delete modal */}
            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Quotation Confirmation</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            {selectedQuotation ? (
                                <>
                                    <p className="mb-2">
                                        <span className="fw-bold">Quotation Title: </span> {selectedQuotation.title}
                                    </p>
                                    <p className="mb-2">
                                        <span className="fw-bold">Due Date: </span> {formatDateWithoutTime(selectedQuotation.due_date)}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className="modal-footer">
                            <button data-toggle="tooltip" data-placement="bottom" title="Close" type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Quotation"
                                type="button"
                                onClick={() => {
                                    if (selectedQuotation && selectedQuotation.id) {
                                        handleDeleteQuotation.mutate(selectedQuotation.id);
                                    }
                                }}
                                className="btn btn-danger"
                                data-bs-dismiss="modal"
                            >
                                Delete Quotation
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuotationsList
