import { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { get_all_products_logs } from '../api/productsApi';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateWithoutTime } from '../../../helper-functions/DateFunction';
import { Form } from 'react-bootstrap';
import { useTable } from 'react-table';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';
import { toast } from 'react-toastify';

function AllProductsLogs() {
  const [productsLogs, setproductsLogs] = useState<object[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(50);
  const [currentLogs, setCurrentLogs] = useState<object[]>([]);
  const { data, isLoading, error } = useQuery(['allProductsLogs'], () =>
    get_all_products_logs(), {refetchOnWindowFocus: false}
  );
  useEffect(() => {
    if (data) {
      setproductsLogs(data.data);
    }
  }, [data]);
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const navigate = useNavigate();
  useEffect(() => {
    if (productsLogs.length > 0) {
      setCurrentLogs(
        productsLogs.slice(indexOfFirstLog, indexOfLastLog)
      )
    }
  },[productsLogs,indexOfFirstLog,indexOfLastLog])
  if (isLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className='text-center'>
        <h2>Error in Get All Products Logs</h2>
      </div>
    );
  }

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(productsLogs.length / logsPerPage);
  const handleShowToast = ((name) => {
    toast.error('The product '+ name + ' does not exist anymore', {
      position: 'top-right',
      autoClose: 3000,
    })
  })

  const getPageNumbers = (): Array<number | string> => {
    const pageNumbers: Array<number | string> = [];
    const totalPageDisplay = 5;
    let startPage = Math.max(1, currentPage - Math.floor(totalPageDisplay / 2));
    let endPage = startPage + totalPageDisplay - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - totalPageDisplay + 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    if (startPage > 1) {
      pageNumbers.unshift('...');
    }
    if (endPage < totalPages) {
      pageNumbers.push('...');
    }
    return pageNumbers;
  };

  const columns = [
    {
      Header: "#",
      accessor: "logsDetails.id",
      width: 50,
    },
    {
      Header: "Description",
      accessor: "logsDetails.description",
      width: 300,
      Cell: ({ row }) => (
        <>
          {Object.entries(row.original.properties.new).length > 0 ||
          Object.entries(row.original.properties.old).length > 0 ? (
            <>
               {row.original.logsDetails.description.split("-").pop() === "updated" ? (
              <strong>{row.original.full_name}</strong>
            ) : row.original.logsDetails.description.split("-").pop() === "created" ? (
              <strong>
             <span
                  style={{ cursor: 'pointer', color: '#00bfff', fontWeight: 'bold' }}
                    onClick={() => handleProductView(row.original.dataModel_id)}
                  >
                  {row.original.full_name}
                </span>
              </strong>
            ) : row.original.logsDetails.description.split("-").pop() === "deleted" ? (
              <strong>{row.original.full_name}</strong>
            ) : null}
              {row.original.logsDetails.description.split("-").pop() === "updated" ? (
                <>
                  &nbsp;{row.original.userName} Made the following changes in&nbsp;
                  <strong>{row.original.logsDetails.subject_type}</strong>{" "}
                  of&nbsp;
                  <span
                  style={{ cursor: 'pointer', color: '#00bfff', fontWeight: 'bold' }}
                    onClick={() => handleProductView(row.original.dataModel_id)}
                  >
                  {row.original.dataModel_name}{" "}
                </span>
                  at{" "}
                  {row.original.logsDetails.created_at}
                  
                </>
              ) : row.original.logsDetails.description.split("-").pop() === "created" ? (
                <>
                  &nbsp;{row.original.userName} Added the following information in&nbsp;
                  <strong>{row.original.logsDetails.subject_type}</strong>{" "}
                  of&nbsp;
                  <span
                  style={{ cursor: 'pointer', color: '#00bfff', fontWeight: 'bold' }}
                    onClick={() => handleProductView(row.original.dataModel_id)}
                  >
                    <strong>{row.original.dataModel_name}</strong>&nbsp;
                  </span>
                  at{" "}
                  {row.original.logsDetails.created_at}
                </>
              ) : row.original.logsDetails.description.split("-").pop() === "deleted" ? (
                <>
                  &nbsp;{row.original.userName} Deleted the following information in&nbsp;
                  <strong>{row.original.logsDetails.subject_type}</strong>{" "}
                  of&nbsp;
                  <span
                  style={{ cursor: 'pointer', color: '#00bfff', fontWeight: 'bold' }}
                  onClick={() => handleShowToast(row.original.dataModel_name)}
                  >
                    <strong>{row.original.dataModel_name}</strong>&nbsp;
                  </span>
                  at{" "}
                  {row.original.logsDetails.created_at}
                </>
              ) : null}
            </>
          ) : (
            <>{row.original.logsDetails.description}</>
          )}
        </>
      ),
    },
    {
      Header: "Changes",
      accessor: "properties",
      Cell: ({ row }) => (
        <>
          <div className="table w-55">
            <table className="table table-rounded table-striped border gy-7 gs-7 align-middle">
              <thead className="border border-1 border-white bg-dark text-white">
                <tr>
                  <th scope="col" className=" ps-5 fw-bold">
                    Column
                  </th>
                  <th scope="col" className=" ps-5 fw-bold">
                    Old
                  </th>
                  <th scope="col" className=" ps-5 fw-bold">
                    New
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(row.original.properties.new).map((key: string, index: number) => {
                  const oldValue = row.original.properties.old[key];
                  const newValue = row.original.properties.new[key];
                  if (oldValue !== newValue && (oldValue || newValue)) {
                    return (
                      <tr className="fs-6 text-gray-800 border border-gray-200" key={key}>
                        {key === "Price" ?
                          <td>{key} ({getSettingValue('currency_symbol')})</td>
                          :
                          <td>{key}</td>
                        }
                        <td>{oldValue !== undefined ? oldValue : ""}</td>
                        <td>{newValue !== undefined ? newValue : ""}</td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        </>
      ),
    },
  ];
  const handleProductView = (row:any) => {
    navigate("/products/view-product", { state: { id:row } });
  };
  return (
    <>
      <Table columns={columns} data={currentLogs} />
      <div>
        <p className="text-muted">
          Showing {indexOfFirstLog + 1} to{' '}
          {indexOfLastLog > productsLogs.length ? productsLogs.length : indexOfLastLog}{' '}
          of {productsLogs.length} entries
        </p>
      </div>
      <div className="mb-3 d-flex justify-content-around align-items-center">
        <div className="mb-3 d-flex justify-content-between align-items-center w-25">
          <label htmlFor="logsPerPage" className="form-label">
            Results Per Page
          </label>
          <Form.Control
            as="select"
            id="logsPerPage"
            className="form-select form-select-sm w-50"
            value={logsPerPage}
            onChange={(e) => {
              setLogsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
          </Form.Control>
        </div>
        <ul className="pagination my-5">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
          </li>
          {getPageNumbers().map((pageNumber, index) => (
            <li
              key={index}
              className={`page-item ${currentPage === pageNumber ? 'active' : ''
                }`}
            >
              <button
                className="page-link"
                onClick={() => {
                  if (typeof pageNumber === 'number') {
                    handlePageChange(pageNumber);
                  }
                }}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${currentPage === totalPages ? 'disabled' : ''
              }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <table
      {...getTableProps()}
      className="table table-rounded table-bordered border-gray-200 align-middle"
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            className="fs-6 text-gray-800 border border-gray-200"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{ width: column.width }}
                className="fw-bold"
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AllProductsLogs
