import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, signout, get_user_permissions} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import secureLocalStorage from 'react-secure-storage'
type AuthContextProps = {
  auth: AuthModel | undefined
  permission: any
  savePermission: (permission) => void
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: (access_token?:string) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  permission: authHelper.getPermission(),
  savePermission: () => {},
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [permission, setPermission] = useState<AuthModel | undefined>(authHelper.getPermission())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }
  const savePermission = (permission) => {
    setPermission(permission)
    if (permission) {
      authHelper.setPermission(permission)
    } else {
      authHelper.removePermission()
    }
  }

  const logoutMutation = (access_token: string) => {
    return signout(access_token);
  };
  const { mutate } = useMutation(logoutMutation as any);
  const logout = (access_token?: string) => {
    if (access_token) {
      mutate(access_token as any, {
        onSuccess: () => {
          // Handle the success response
          toast.success('You have been logged out Successfully', {
            theme: 'colored',
          });
          saveAuth(undefined);
          setCurrentUser(undefined);
          // Synchronize logout across tabs
          syncLogout();
        },
        onError: () => {
          // Handle logout error
          saveAuth(undefined);
          setCurrentUser(undefined);
          savePermission(undefined);
        },
      });
    } else {
      // Handle logout when no API token is provided
      saveAuth(undefined);
      setCurrentUser(undefined);
      savePermission(undefined);
      // Synchronize logout across tabs
      syncLogout();
    }
  };
  // Function to synchronize logout across tabs
  const syncLogout = () => {
    // Send a storage event to notify other tabs to logout
    window.localStorage.setItem('logout', Date.now().toString());
  };
  useEffect(() => {
    // Event listener to handle logout event from other tabs
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'logout') {
        logout();
      }
    };
    // Attach the event listener
    window.addEventListener('storage', handleStorageChange);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <AuthContext.Provider value={{auth, permission, savePermission, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, permission} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  
  // We should request user by authToken (IN OUR EXAMPLE IT'S access_token) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            const { data: permissionsData } = await get_user_permissions()
            authHelper.setPermission(permissionsData)
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser(auth.access_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
