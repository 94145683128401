import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { get_all_roles, get_all_users, update_user } from '../api/usersApi';
import { useNavigate } from 'react-router-dom';
import { get_all_department } from '../../departments/api/departmentsApi';
import { Form } from 'react-bootstrap';
type User = {
  id: number;
  department_id: string;
  department_name: string | undefined;
  title: string | undefined;
  firstname: string | undefined;
  middlename: string | undefined;
  lastname: string | undefined;
  telephone: string | undefined;
  email: string | undefined;
  password: string | undefined;
  mobile: string | undefined;
  picture: string | undefined;
  role: string | undefined;
  password_confirmation: string | undefined;
  status: number | undefined;
  created_at: string;
  deleted_at: string;
  updated_at: string;
};
interface userRoleType {
  id: number;
  name: string;
};
interface Department {
  id: number;
  name: string;
};
function EditUser() {
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [userPicture, setUserPicture] = useState<File | null>(null);
  const [roleType, setRoleType] = useState<userRoleType[]>([]);
  const [department, setDepartment] = useState<Department[]>([]);
  const [firstNameError, setFirstNameError] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [telephoneValidationError, setTelephoneValidationError] = useState('');
  const [userList, setUserList] = useState<User[]>([]);
  const [mobileValidationError, setMobileValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const user = (location.state as { user: User }).user;
  const { data: roleData } = useQuery('roleType', get_all_roles, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (roleData) {
      setRoleType(roleData.data as userRoleType[]);
    }
  }, [roleData]);
  useEffect(() => {
    setSelectedUser(user);
  }, [user]);
  const { data, isError, refetch } = useQuery('users', get_all_users, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (data) {
      setUserList(data.data);
    };
  }, [data]);
  const { data: departmentData } = useQuery('department', get_all_department, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (departmentData) {
      setDepartment(departmentData.data as Department[]);
    };
  }, [departmentData]);
  const ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const newFirstName = e.target.value;
      if (!newFirstName) {
        setFirstNameError('FirstName is required.');
      } else {
        setFirstNameError('');
      };
      setSelectedUser({
        ...selectedUser,
        firstname: newFirstName,
      });
    };
  };
  const handleTitleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (selectedUser) {
      const newTitleName = e.target.value;
      if (!newTitleName) {
        setTitleError('Title is required.');
      } else {
        setTitleError('');
      };
      setSelectedUser({
        ...selectedUser,
        title: newTitleName,
      });
    };
  };
  const handleDepartmentChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (selectedUser) {
      const newDepartment = e.target.value;
      if (!newDepartment) {
        setDepartmentError('Department is required.');
      } else {
        setDepartmentError('');
      };
      setSelectedUser({
        ...selectedUser,
        department_id: newDepartment,
      });
    };
  };
  const handleMidddleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const middleName = e.target.value;
      setSelectedUser({
        ...selectedUser,
        middlename: middleName,
      });
    };
  };
  const handlEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const email = e.target.value;
      if (!email) {
        setEmailError('Email is required.');
      } else {
        setEmailError('');
      };
      setSelectedUser({
        ...selectedUser,
        email: email,
      });
    };
  };
  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const lastName = e.target.value;
      setSelectedUser({
        ...selectedUser,
        lastname: lastName,
      });
    };
  };
  const handleTelephoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const newUserTelephone = e.target.value;
      setSelectedUser({
        ...selectedUser,
        telephone: newUserTelephone,
      });
      if (newUserTelephone) {
        const numberRegex = /^[+0-9]+$/;
        if (!numberRegex.test(newUserTelephone)) {
          setTelephoneValidationError('Telephone Number must be in numeric value.');
        } else {
          setTelephoneValidationError('');
        };
      };
    }
  };
  const handleUserRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (selectedUser) {
      const newRoleName = e.target.value;
      if (!newRoleName) {
        setRoleError('Role is required.');
      } else {
        setRoleError('');
      };
      setSelectedUser({
        ...selectedUser,
        role: newRoleName,
      });
    };
  };
  const handleUserMobileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const newUserMobile = e.target.value.trim();
      setSelectedUser({
        ...selectedUser,
        mobile: newUserMobile,
      });
      if (!newUserMobile) {
        setMobileValidationError('Mobile Number is required.');
      } else {
        const numberRegex = /^[+0-9]+$/;
        if (!numberRegex.test(newUserMobile)) {
          setMobileValidationError('Mobile Number must be in numeric value.');
        } else {
          setMobileValidationError('');
        };
      };
    };
  };
  const handleUserImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const newPicture = e.target.files?.[0] || null;
      setSelectedUser({
        ...selectedUser,
        picture: newPicture ? URL.createObjectURL(newPicture) : undefined,
      });
      setUserPicture(newPicture);
    };
  };
  const handleStatusChange = (e: any) => {
    if (selectedUser) {
      const status = e.target.value;
      setSelectedUser({
        ...selectedUser,
        status: status,
      });
    }
  };
  const handleUpdateUser = () => {
    try {
      setLoading(true);
      if (!selectedUser) {
        throw new Error('No User Selected for Update');
      }

      const {
        id,
        department_id,
        title,
        firstname,
        middlename,
        lastname,
        telephone,
        email,
        password,
        mobile,
        picture,
        role,
        password_confirmation,
        status
      } = selectedUser;
      const formData: any = new FormData();
      formData.append('id', id);
      formData.append('department_id', department_id || '');
      formData.append('title', title || '');
      formData.append('firstname', firstname || '');
      formData.append('middlename', middlename || '');
      formData.append('lastname', lastname || '');
      formData.append('telephone', telephone || '');
      formData.append('email', email || '');
      formData.append('password', password || '');
      formData.append('mobile', mobile || '');
      formData.append('status', status);
      if (userPicture) {
        formData.append('picture', userPicture);
      }
      formData.append('role', role || '');
      formData.append('password_confirmation', password_confirmation || '');
      updateUserMutation.mutate({
        id,
        formData,
      });
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 3000,
        });
      }
      setLoading(false);
    }
  };
  const updateUserMutation = useMutation((Data: any) => update_user(Data.id, Data.formData), {
    onSuccess: (response) => {
      refetch();
      setLoading(false);
      navigate('/users/users-list');
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
      ref.current && (ref.current.value = '')
    },
    onError: (error: any) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('No response received from the server.', {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else {
        toast.error('An unexpected error occurred.', {
          position: "top-right",
          autoClose: 3000,
        });
      }
      setLoading(false);
    },
  });
  if (isError) {
    return (
      <div className='text-center'>
        <h2>Error in Get All Users</h2>
      </div>
    );
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Edit User</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/users/users-list')}
          >
            List of Users
          </button>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {selectedUser ? (
            <>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Role</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={selectedUser ? selectedUser.role : ''}
                    onChange={(e) => {
                      handleUserRoleChange(e)
                    }}
                  >
                    <option value=''>Select a Role...</option>
                    {roleType.map((roleType) => (
                      <option key={roleType.id} value={roleType.name}>
                        {roleType.name}
                      </option>
                    ))}
                  </select>
                  {roleError && (
                    <div className='text-danger'>{roleError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Department</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={selectedUser ? selectedUser.department_id : ''}
                    onChange={(e) => handleDepartmentChange(e)}
                  >
                    <option value=''>Select a Department...</option>
                    {Array.isArray(department) && department.length > 0 ? (
                      department.map((depart) => (
                        <option key={depart.id} value={depart.id}>
                          {depart.name}
                        </option>
                      ))
                    ) : (
                      <option value='' disabled>No Department available</option>
                    )}
                  </select>
                  {departmentError && (
                    <div className='text-danger'>{departmentError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Title</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={selectedUser ? selectedUser.title : ''}
                    onChange={(e) => handleTitleChange(e)}
                  >
                    <option value=''>Select a Title...</option>
                    <option value='Mr.'>Mr.</option>
                    <option value='Mrs.'>Mrs.</option>
                    <option value='Ms.'>Ms.</option>
                  </select>
                  {titleError && (
                    <div className='text-danger'>{titleError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  <span>First Name</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='First Name'
                    value={selectedUser ? selectedUser.firstname : ''}
                    onChange={(e) => handleFirstNameChange(e)}
                  />
                  {firstNameError && (
                    <div className='text-danger'>{firstNameError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Middle Name</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Middle Name'
                    value={selectedUser ? selectedUser.middlename : ''}
                    onChange={(e) => handleMidddleNameChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Last Name</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Last Name'
                    value={selectedUser ? selectedUser.lastname : ''}
                    onChange={(e) => handleLastNameChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Telephone</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Telephone'
                    value={selectedUser ? selectedUser.telephone : ''}
                    onChange={(e) => handleTelephoneChange(e)}
                  />
                  {telephoneValidationError && (
                    <div className='text-danger'>{telephoneValidationError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Mobile Number</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Mobile number'
                    value={selectedUser ? selectedUser.mobile : ''}
                    onChange={(e) => handleUserMobileChange(e)}
                  />
                  {mobileValidationError && (
                    <div className='text-danger'>{mobileValidationError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  <span>Email</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='First Name'
                    value={selectedUser ? selectedUser.email : ''}
                    disabled={true}
                    // onChange={(e) => handlEmailChange(e)}
                  />
                  {emailError && (
                    <div className='text-danger'>{emailError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Picture</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e) => handleUserImageChange(e)}
                    name='picture'
                  />
                  <div className='text-danger mt-2'>
                    Recommended size 200 x 200 px. jpg, jpeg, or png only
                  </div>
                  {selectedUser.picture && (
                    <div className='mt-2'>
                      <img
                        src={selectedUser.picture}
                        alt='User Picture'
                        className='symbol'
                        width={200}
                        height={200}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Status</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <Form.Select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={selectedUser ? selectedUser.status : ''}
                    onChange={(e) => handleStatusChange(e)}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Form.Select>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          {firstNameError || titleError || telephoneValidationError || roleError || emailError || mobileValidationError ? (
            <button type='button' data-toggle="tooltip" data-placement="bottom" title="Update User" className='btn btn-primary disabled'>
              Update User
            </button>
          ) : (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Update User"
              type='button'
              className='btn btn-primary'
              onClick={handleUpdateUser}
              disabled={loading}
            >
              {!loading && 'Update User'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
};
export default EditUser;