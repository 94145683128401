import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDateFlatpickr, formatDateWithoutTime } from '../../../helper-functions/DateFunction';
import { get_single_stock, update_stock_in } from '../api/stocksApi';
import { useQuery } from 'react-query';
import { get_all_active_products } from '../../products/api/productsApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Flatpickr from 'react-flatpickr';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { get_all_active_vendors } from '../../vendors/api/vendorsApi';
import { KTIcon, KTSVG } from '../../../../_metronic/helpers';
import AddNewProduct from '../../purchases/components/AddNewProduct';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';
import Select from 'react-select';

interface Product {
  id: number;
  name: string;
};
const StockSchema = Yup.object().shape({ date: Yup.date().required('Please Select a Date'), });
function EditStockIn() {
  let grandTotal = 0;
  const [singleStock, setSingleStock] = useState<any | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [allProduct, setAllProduct] = useState<any[]>([]);
  const [priceProduct, setPriceProduct] = useState(1);
  const [quantityProduct, setQuantityProduct] = useState(1);
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<any[]>([]);
  const location = useLocation();
  const id = (location.state as { id: number }).id;
  const { data, isLoading, error } = useQuery(['ViewStockIn', id], () =>
    get_single_stock(id), {refetchOnWindowFocus: false}
  );
  const { data: allVendors } = useQuery('vendors', get_all_active_vendors, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (allVendors && Array.isArray(allVendors.data)) {
      setSelectedVendor(allVendors.data as any[]);
    } else {
      setSelectedVendor([]);
    }
  }, [selectedVendor]);
  useEffect(() => {
    if (data) {
      setSingleStock(data.data);
    }
  }, [data]);
  const navigate = useNavigate();
  const { data: productData } = useQuery('products', get_all_active_products, {refetchOnWindowFocus: false});
  const { data: allProducts } = useQuery('allProductSystem', get_all_active_products, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (productData) {
      setProducts(productData.data as Product[]);
    }
  }, [productData]);
  useEffect(() => {
    if (allProducts && Array.isArray(allProducts.data)) {
      setAllProduct(allProducts.data as any[]);
    } else {
      setAllProduct([]);
    }
  }, [allProducts]);

  useEffect(() => {
    // Initialize filteredProducts with all products
    setFilteredProducts(Array.isArray(allProduct) ? allProduct : []);
  }, [allProduct]);
  // Formik
  const initialValues: object = {
    supplier_name: singleStock ? singleStock.vendor_id : "",
    description: "",
    date_time: "",
  };
  const handleProceedToPurchase = async () => {
    await formik.validateForm();
    try {
      setLoading(true);
      const { supplier_name } = formik.values;
      const finalPODate = singleStock?.date_time;
      const Description = singleStock?.description;
      const stock_products = singleStock.stock_products.map((product) => {
        const { id, product_name, quantity, rate, ...otherProductDetails } = product;
        const priceProduct = product.rate * product.quantity;
        return {
          id,
          product_name,
          quantity: product.quantity,
          rate: product.rate || 0,
          price: priceProduct || 0,
          final_price: priceProduct || 0,
          ...otherProductDetails,
        };
      });
      const supplier_id = supplier_name || singleStock?.vendor_id;
      const purchaseData = {
        supplier_id,
        date_time: finalPODate,
        description: Description,
        stock_products,
        // ...other formik values if needed
      };
      const response = await update_stock_in(id, purchaseData);
      navigate("/stocks/stock-in-list");
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
    } finally {
      setLoading(false);
    };
  };
  const formik = useFormik<any>({
    validationSchema: StockSchema,
    initialValues,
    onSubmit: handleProceedToPurchase,
  });
  if (isLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className='text-center'>
        <h2>Error in Get All Products Logs</h2>
      </div>
    );
  }
  const getProductNameById = (productId) => {
    const product = products.find((p) => p.id == productId);
    return product ? product.name : 'Product not found';
  };
  const handleStockInDescription = (e) => {
    if (singleStock) {
      const newStockoutDes = e.target.value;
      setSingleStock({
        ...singleStock,
        description: newStockoutDes,
      });
    };
  };
  const handleStockoutDateChange = (selectedDates) => {
    if (singleStock) {
      const newtockOutDate = selectedDates[0];
      newtockOutDate?.setHours(0, 0, 0, 0);
      const formattedDate = formatDateFlatpickr(newtockOutDate);

      setSingleStock({
        ...singleStock,
        date_time: formattedDate,
      });
    }
  };

  // OnChange
  const handleQuantityChange = (productId, newQuantity) => {
    // Find the product in the state by productId and update its quantity
    const updatedStockProducts = singleStock.stock_products.map(product => {
      if (product.id === productId) {
        return { ...product, quantity: newQuantity };
      }
      return product;
    });
    // Update the state with the new stock products
    setSingleStock(prevState => ({
      ...prevState,
      stock_products: updatedStockProducts
    }));
  };
  // OnChange for Price
  const handlePriceChange = (productId, newPrice) => {
    const updatedStockProducts = singleStock.stock_products.map(product => {
      if (product.id === productId) {
        return { ...product, rate: newPrice };
      }
      return product;
    });
    setSingleStock(prevState => ({
      ...prevState,
      stock_products: updatedStockProducts
    }));
  };
  // Handle Add Product
  const handleProductChange = (e) => {
    const selectedProductId = e.value;
    setSelectedProduct(selectedProductId);
    // Find the selected product details
    const selectedProductDetails = allProduct.find((product) => product.id === parseInt(selectedProductId));
    // Update the state with selected product details
    if (selectedProductDetails) {
      setPriceProduct(selectedProductDetails.price || 1);
      setQuantityProduct(selectedProductDetails.quantity || 1);
      // You may set other related fields here if needed
    } else {
      // // Handle the case when the selected product details are not found
      setSelectedProduct(null);
      setPriceProduct(1);
    }
  };
  const handleAddProduct = () => {
    // Check if a product is selected
    if (selectedProduct) {
      // Find the selected product details directly using the selectedProduct object
      const selectedProductDetails = allProduct.find(
        (product) => product.id === parseInt(selectedProduct)
      );

      // Check if selectedProductDetails is defined
      if (selectedProductDetails) {
        // Check if the product already exists in the selectedStockIn array
        const productExists = singleStock.stock_products.some(
          (product) => product.product_id === selectedProductDetails.id
        );

        if (productExists) {
          // If the product already exists, show a toast and return
          toast.warning('Product Already Exist', {
            position: 'top-right',
            autoClose: 2000,
          });
          return;
        }

        // Create a new product object with details
        const newProduct = {
          id: 0,
          product_id: selectedProductDetails.id,
          product_name: selectedProductDetails.name || '',
          discount: 0,
          quantity: quantityProduct || 1, // Assuming quantity is a state variable
          rate: priceProduct || 0, // Assuming rate is a state variable
        };

        // Update the state with the new product
        setSingleStock((prevProducts: any) => {
          // If prevProducts is not an array (undefined or null), initialize it as an empty array
          const updatedProducts = Array.isArray(prevProducts.stock_products)
            ? [...prevProducts.stock_products, newProduct]
            : [newProduct];
          return { ...prevProducts, stock_products: updatedProducts };
        });

        // Show a success toast message
        toast.success('Product Added Successfully.', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    }
  };
  const handleRemoveProduct = (productId) => {
    // Implement the logic to remove the product from state
    const updatedStockProducts = singleStock.stock_products.filter(
      (product) => product.product_id !== productId
    )
    // Update the state with the new stock products array
    setSingleStock((prevProducts) => ({
      ...prevProducts,
      stock_products: updatedStockProducts,
    }));
  };
  // Add an event listener for keydown events
  const handleKeyDown = (event, productId) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      const targetTagName = event.target.tagName.toLowerCase();

      // Check if the event target is a button
      if (targetTagName === 'button') {
        event.preventDefault(); // Prevent the default form submission behavior
        handleRemoveProduct(productId);
      }
    }
  };
  return (
    <div className='card card-custom shadow'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Stock in Update</h3>
        </div>
      </div>
      <div
        className='card-header border-0 cursor-pointer'
      >

        <button
          data-toggle="tooltip" data-placement="bottom" title="Navigate to Add New Product"
          className='btn btn-sm btn-light-dark m-4'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_2'
        >
          <KTIcon iconName='plus' className='fs-3' />
          Add New Product
        </button>
        <button
          data-toggle="tooltip"
          data-placement="bottom"
          title="Add Product"
          className="btn btn-primary m-4"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_1"

        >
          Add Product
        </button>
      </div>
      <Form>
        <div className='card-header border-0 pt-5'>
          <div className="card-body">
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Date</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <Flatpickr
                  onChange={(selectedDates) => handleStockoutDateChange(selectedDates)}
                  className='form-control form-control-solid'
                  placeholder={formatDateWithoutTime(singleStock?.date_time)}
                  options={{
                    dateFormat: 'd/m/Y',
                  }}
                />
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Description</label>
              <div className='col-lg-8 fv-row'>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  {...formik.getFieldProps('description')}
                  value={singleStock ? singleStock?.description : ""}
                  onChange={(e) => handleStockInDescription(e)}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Vendor(Optional)</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('supplier_name')}
                  value={formik.values.supplier_name}
                >
                  <option value="">{singleStock?.vendor_name ? singleStock?.vendor_name : "Please Select a Vendor"}</option>
                  {Array.isArray(selectedVendor) && selectedVendor.length > 0 ? (
                    selectedVendor.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.supplier_name}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>No Vendors Available</option>
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>

          {singleStock && singleStock.stock_products && singleStock.stock_products.length > 0 && (
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-100px required'>Product</th>
                    <th className='min-w-100px required'>Quantity</th>
                    <th className='min-w-140px'>Rate ({getSettingValue('currency_symbol')})</th>
                    <th className='min-w-120px'>Price ({getSettingValue('currency_symbol')})</th>
                    <th className='min-w-150px'>Final Price ({getSettingValue('currency_symbol')})</th>
                    <th className='min-w-120px'>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {singleStock.stock_products.map((product: any) => {
                    const priceProduct = product.rate * product.quantity;
                    const finalPrice = priceProduct;
                    grandTotal += finalPrice;

                    return (
                      <tr key={product.id}>
                        <td>
                          <input className='card' value={getProductNameById(product.product_id)} readOnly />
                        </td>
                        <td>
                          <input
                            className='card'
                            type='number'
                            placeholder='Quantity'
                            value={product ? product.quantity : ''}
                            onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            className='card'
                            type='number'
                            placeholder='Rate'
                            value={product ? product.rate : ''}
                            onChange={(e) => handlePriceChange(product.id, e.target.value)}
                          />
                        </td>
                        <td>
                          <input className='card' value={priceProduct.toFixed(2)} readOnly />
                        </td>
                        <td>
                          <input className='card' value={finalPrice.toFixed(2)} readOnly />
                        </td>
                        <td className='text-end'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* Add a Remove button with an onClick event handler */}
                            <button
                              type='button'
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Delete Product"
                              className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                              onClick={() => handleRemoveProduct(product.product_id)}
                              onKeyDown={(e) => handleKeyDown(e, product.product_id)}
                              tabIndex={0}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr className='fw-bold text-muted'>
                    <td colSpan={4}></td>
                    <td colSpan={2}>Grand Total ({getSettingValue('currency_symbol')}):
                      <input
                        className='card'
                        type="number"
                        readOnly
                        value={grandTotal.toFixed(2)}
                      />
                    </td>
                    <td className='min-w-140px'></td>
                    <td colSpan={2}></td>
                    <td colSpan={1}></td>
                  </tr>

                </tfoot>
              </table>
            </div>
          )}

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              data-toggle='tooltip'
              data-placement='bottom'
              title='Stock In Update'
              type='button'
              className='btn btn-primary'
              disabled={loading}
              onClick={handleProceedToPurchase}
            >
              {!loading && 'Stock In Update'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </Form>
      {/* Add Product */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Product</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <Form>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Product</label>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      placeholder="Select "
                      options={filteredProducts.map((product) => ({
                        value: product.id,
                        label: product.name,
                      }))}
                      className="basic-multi-select form-select-lg p-2 bg-light text-black"
                      classNamePrefix="select border-light fw-bold"
                      onChange={handleProductChange}
                    />
                  </div>
                </div>
                {selectedProduct && (
                  <div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Quantity</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Quantity'
                          value={quantityProduct}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantityProduct(Number(e.target.value))}
                        />
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Rate ({getSettingValue('currency_symbol')})</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Rate'
                          value={priceProduct}
                          onChange={(e: any) => setPriceProduct(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Price ({getSettingValue('currency_symbol')})</label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          readOnly
                          value={(quantityProduct * priceProduct).toFixed(2)}
                        />
                      </div>
                    </div>
                  </div>)}
              </Form>
            </div>
            <div className='modal-footer'>
              <button
                data-toggle="tooltip"
                data-placement="bottom"
                title="Close"
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
              >
                Close
              </button>
              <button
                data-toggle="tooltip"
                data-placement="bottom"
                title="Add Product"
                className='btn btn-sm btn-light-dark'
                onClick={() => {
                  handleAddProduct();
                }}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add Product
              </button>
            </div>

          </div>
        </div>
      </div>
      {/* New Product Modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add New Product</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <AddNewProduct />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditStockIn;