import React, { useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';

const StoreToken: React.FC = () => {
    const removeLocalStorageItems = (): Promise<void> => {
        return new Promise((resolve) => {
            localStorage.removeItem('kt-auth-react-v');
            localStorage.removeItem('kt-auth-react-v-expiration');
            secureLocalStorage.removeItem('user_permissions');
            secureLocalStorage.removeItem('general_settings');
            secureLocalStorage.removeItem('baseURL');
            resolve();
        });
    };

    useEffect(() => {
        const handleMessage = async (event: MessageEvent) => {
            try {
                // Remove previous items
                await removeLocalStorageItems();

                // Destructure token and expiration from event data
                const { token, expiration } = event.data;

                // Set new values in localStorage
                localStorage.setItem('kt-auth-react-v', JSON.stringify(token));
                localStorage.setItem('kt-auth-react-v-expiration', expiration);

                // Send success response back to source
                const source = event.source as Window | null;
                if (source) {
                    source.postMessage('Token stored successfully', event.origin);
                } else {
                    console.warn('Unable to identify the event source.');
                }
            } catch (error) {
                console.error('Error handling the event:', error);
            }
        };

        window.addEventListener('message', handleMessage);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div>
            <h1>Store Token</h1>
        </div>
    );
};

export default StoreToken;