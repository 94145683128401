import axios from "axios";
import secureLocalStorage from "react-secure-storage";
// Add New Organization
export const add_general_settings = async (formData:object) => {
    const response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/general-settings", formData, {
    });
    return response.data;
}
  // Get All General Settings
  export const get_all_general_settings = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/general-settings");
    return response.data;
  };

  export const get_general_settings_logs = async () => {
    let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/activity-logs-for-general-setting");
    return response.data;
  };
