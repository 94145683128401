import axios from "axios";
import { config_multipart } from "../../../api-helper/api-header-config";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

// List Of quotation
export const get_all_quotations = async () => {
    let response = await axios.get(baseURL + "/api/quotation");
    return response.data;
}
// Delete quotation
export const delete_quotation = async (id: number) => {
    let response = await axios.delete(baseURL + "/api/quotation/" + id);
    return response.data;
}
//signle quotation
export const get_single_quotation = async (id:number) => {
    let response = await axios.get(baseURL + "/api/quotation/" + id);
    return response.data; 
}
export const update_quotation= async (updatedQuotation) => {
    let id : number;
    id = updatedQuotation.id
    let response = await axios.patch(baseURL + "/api/quotation/"+id, updatedQuotation);
    return response.data;
}
// Add New Quotation
export const add_quotation = async (values:object) => {
    let response = await axios.post(baseURL + "/api/quotation", values,{
        headers: {
            'Content-Type': 'multipart/form-data',
          }
    });
    return response.data;
}
// Get Single Requisitions Logs by ID
export const get_quotation_logs = async (id: number) => {
    let response = await axios.get(baseURL + "/api/quotation-activity-log/" + id);
    return response.data;
  };
  // Get All Requisitions Logs
  export const get_all_quotations_logs = async () => {
    let response = await axios.get(baseURL + "/api/quotation-activity-logs");
    return response.data;
  };

  // List Of Requisition Document
export const get_all_quotation_documents = async (id: number) => {
    let response = await axios.get(baseURL + "/api/get-quotation-documents/" + id);
    return response.data;
}
export const add_quotation_document = async (values: object) => {
    const response = await axios.post(baseURL + "/api/quotation-document", values, config_multipart);
    return response.data;
};
// Delete Requisition document
export const delete_quotation_document = async (id: number) => {
    let response = await axios.delete(baseURL + "/api/delete-quotation-document/" + id);
    return response.data;
}
export const update_quotation_document = async (updatedDocument) => {
    let id: number;
    id = updatedDocument.id
    let response = await axios.post(baseURL + "/api/update-quotation-document/" + id, updatedDocument, config_multipart);
    return response.data;
}
// List Of quotation
export const get_all_quotation_vendors = async (id:number) => {
    let response = await axios.get(baseURL + "/api/quotation-vendor-list/" + id);
    return response.data;
}
export const get_filtered_quotations = async (requisitionId, fromDate, toDate) => {
    const response = await axios.post(baseURL + '/api/get-quotation-by-filter', {
        date_flag: fromDate ? "specific" : "",
        from_date: fromDate,
        to_date: toDate,
        requisition_flag: requisitionId ? "specific" : "",
        requisition_id: requisitionId
    });
    return response.data;
};