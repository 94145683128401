/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, get_user_permissions, login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { toast } from 'react-toastify'
import secureLocalStorage from 'react-secure-storage'
import { get_all_general_settings } from '../../../pages/general-settings/api/generalSettingsApi'
import { getSettingValue } from '../../../helper-functions/general_settings_helper'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 Characters')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser, savePermission, auth } = useAuth()
  const currentUserId = auth?.id;

  const navigate = useNavigate()
  function getCookie(name) {
    let cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");

      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }
  useEffect(() => {
    // Retrieve the 'key' cookie
    const keyValue = getCookie('key');
    console.log('key:', keyValue);

    //   console.log('store_token.html loaded');
    //   window.addEventListener('message', (event) => {
    //     console.log('Received from:', event.origin);
    //     console.log('Data:', event.data);

    //     if (event.origin === 'https://central.wriko.staginghosts.com') {
    //         const { token, expiration } = event.data;
    //         localStorage.setItem('tempToken', token);
    //         localStorage.setItem('tempTokenExpiration', expiration);
    //         console.log('Token stored in localStorage:', token);

    //         if (event.source && 'postMessage' in event.source) {
    //           // Check if postMessage is available on event.source and send confirmation
    //           (event.source as Window).postMessage('Token stored successfully', 'https://central.wriko.staginghosts.com');
    //       } else {
    //           console.warn('Message source is null or does not support postMessage');
    //       }
    //   } else {
    //       console.warn('Received message from unexpected origin');
    //   }
    // });

    const head = document.querySelector('head');
    document.querySelectorAll('link[rel="apple-touch-icon"], link[rel="icon"], link[rel="manifest"]').forEach(link => {
      link.remove();
    });
  })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)
        saveAuth(auth)
        // window.location.reload()
        const { data: permissionsData } = await get_user_permissions()
        savePermission(permissionsData)
        const { data: user } = await getUserByToken(auth.api_token)
        setCurrentUser(user)        
        const getGeneralSettingsFlag = await get_all_general_settings();
        secureLocalStorage.setItem("general_settings", getGeneralSettingsFlag.data)
        navigate('/loading')
        const head = document.querySelector('head');
        const link = document.createElement('link') as HTMLLinkElement;
        link.type = 'image/png';
        link.rel = 'icon';
        link.href = getSettingValue('favicon');
        link.setAttribute('sizes', '16x16');
        head?.appendChild(link);
      } catch (error: any) {
        if (error.response) {
          toast.error(error.response.data.message, {
            position: 'top-right',
            autoClose: 3000,
          });
        }
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Log In</h1>
      </div>
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Log In</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
