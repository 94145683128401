import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { get_all_products_in_stock, get_all_stocks, get_single_stock, update_stock_out } from '../api/stocksApi';
import { get_all_users_against_department } from '../../users/api/usersApi';
import { get_all_department } from '../../departments/api/departmentsApi';
import { formatDateFlatpickr, formatDateWithoutTime } from '../../../helper-functions/DateFunction';
import { KTSVG } from '../../../../_metronic/helpers';
type StockOut = {
    id: number;
    description: string | undefined;
    department_id: number;
    user_id: number | undefined;
    date_time: string;
    updated_by: string;
    updated_at: string;
    stock_products: {
        id: number;
        product_id: number;
        quantity: number;
        updated_by: string;
        updated_at: string;
    }[];
};
interface SingleStockOut {
    id: number;
    description: string | undefined;
    department_id: number;
    user_id: number | undefined;
    date_time: string;
    updated_by: string;
    updated_at: string;
    stock_products: {
        id: number;
        product_id: number;
        quantity: number;
        updated_by: string;
        updated_at: string;
    }[];
};
interface Department {
    id: number;
    name: string;
};
interface Product {
    product_id: number;
    product_name: string;
    remaining_stock: number;
};
interface User {
    id: number;
    full_name: string;
};

function EditStockOut() {
    const [selectedStockOut, setSelectedStockOut] = useState<StockOut | null>();
    const [singleStockOut, setSingleStockOut] = useState<SingleStockOut | null>(null);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedId, setSelectedId] = useState(0);
    const [departmentId, setDepartmentId] = useState(0);
    const [productValue, setProductValue] = useState('');
    const [quantityValue, setQuantityValue] = useState('');
    const [remainingQuantity, setRemainingQuantity] = useState('');
    const [dateValidationError, setDateValidationError] = useState('');
    const [departmentValidationError, setDepartmentValidationError] = useState('');
    const [userValidationError, setUserValidationError] = useState('');
    const [productValidationError, setProductValidationError] = useState('');
    const [quantityValidationError, setQuantityValidationError] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const stockout = (location.state as { stock: StockOut }).stock;

    const { refetch, } = useQuery('stockout', get_all_stocks, {refetchOnWindowFocus: false});
    useEffect(() => {
        setSelectedStockOut(stockout);
        setSelectedId(stockout.id)
        setDepartmentId(stockout.department_id)
    }, [stockout]);

    const { data: departmentData } = useQuery('department', get_all_department, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (departmentData) {
            setDepartments(departmentData.data as Department[]);
        };
    }, [departmentData]);

    const { data: productsData } = useQuery('products', get_all_products_in_stock, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (productsData) {
            setProducts(productsData.data as Product[]);
        };
    }, [productsData]);
    const { data: singleStockOutData, isLoading: isSingleStockoutLoading } = useQuery(['singleRequisition', selectedId], () => get_single_stock(selectedId), {
        refetchOnWindowFocus: false,
        enabled: !!selectedId,
    });
    useEffect(() => {
        if (singleStockOutData) {
            setSingleStockOut(singleStockOutData.data as SingleStockOut);
            setDepartmentId(singleStockOutData.data.department_id)
        };
    }, [singleStockOutData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await get_all_users_against_department(departmentId);
                if (Array.isArray(userData.data) && userData.data.length > 0) {
                    setUsers(userData.data);
                } else {
                    toast.error("No users found against this department", {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                    setUsers([]);
                }
            } catch (error: any) {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    setUsers([]);
                }
            }
        };
        fetchData();
    }, [singleStockOutData]);

    const updateStockOutMutation = useMutation(update_stock_out, {
        onSuccess: (response) => {
            refetch();
            setLoading(false);
            navigate('/stocks/stock-out-list');
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
        },
        onError: (error: any) => {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
            }
        },
    });
    const handleUpdateStockOut = () => {
        setLoading(true);
        if (singleStockOut) {
            const { id, description, date_time, department_id, user_id, stock_products } = singleStockOut;
            // Check if any product name is empty
            const isProductNameEmpty = stock_products.some(product => !product.product_id);
            if (isProductNameEmpty) {
                toast.error('Product name is required for all products.', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                setLoading(false);
                return;
            }
            const updatedProducts = stock_products.map((product) => ({
                id: product.id,
                quantity: product.quantity,
                product_id: product.product_id,
            }));
            updateStockOutMutation.mutate({
                id,
                date_time,
                description,
                department_id,
                user_id,
                stock_products: updatedProducts,
            });
        };
    };
    const handleDepartmentChange = async (selectedDepartmentId) => {
        if (singleStockOut) {
            const newDepartment = selectedDepartmentId;
            if (!newDepartment) {
                setDepartmentValidationError('Department is required.');
            } else {
                setDepartmentValidationError('');
            };

            setSingleStockOut({
                ...singleStockOut,
                department_id: newDepartment,
            });
        };
        try {
            const userData = await get_all_users_against_department(selectedDepartmentId);

            if (Array.isArray(userData.data) && userData.data.length > 0) {

                setUsers(userData.data);
                return userData.data;
            } else {
                toast.error("No users found against this department", {
                    position: 'top-right',
                    autoClose: 3000,
                });
                setUsers([]);
                return [];
            }
        } catch (error: any) {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
            };
            setUsers([]);
            return [];
        }
    };
    const handleQuantityValidation = (e: any) => {
        const newQuantity = e.target.value;
        if (!newQuantity) {
            setQuantityValidationError('Quantity is required.');
        } else if (newQuantity > remainingQuantity) {
            setQuantityValidationError('Quantity must be less than Remaining Quantity');
        } else {
            setQuantityValidationError('');
        };
    };
    const handleStockoutDateChange = (selectedDates) => {
        if (singleStockOut) {
            const newtockOutDate = selectedDates[0];
            if (!newtockOutDate) {
                setDateValidationError('Due Date is required.');
            } else {
                setDateValidationError('');
            }
            newtockOutDate?.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(newtockOutDate);

            setSingleStockOut({
                ...singleStockOut,
                date_time: formattedDate,
            });
        }
    };
    const handleStockOutDesChange = (e) => {
        if (singleStockOut) {
            const newStockoutDes = e.target.value;

            setSingleStockOut({
                ...singleStockOut,
                description: newStockoutDes,
            });
        };
    };
    const handleUserChange = (e) => {
        if (singleStockOut) {
            const newUser = e.target.value;
            if (!newUser) {
                setUserValidationError('User is required.');
            } else {
                setUserValidationError('');
            };
            setSingleStockOut({
                ...singleStockOut,
                user_id: newUser,
            });
        };
    };
    const handleAddProduct = () => {
        if (products.length === 0) {
            toast.error('No products available. Add products before adding to the requisition.', {
                position: 'top-right',
                autoClose: 3000,
            });
            return;
        }
        if (
            singleStockOut &&
            singleStockOut.stock_products.length > 0 &&
            !singleStockOut.stock_products[singleStockOut.stock_products.length - 1].product_id
        ) {
            toast.error('Product is required before adding a new one.', {
                position: 'top-right',
                autoClose: 3000,
            });
            return;
        }
        setSingleStockOut((prev: any) => ({
            ...prev,
            stock_products: [
                ...prev.stock_products,
                { id: 0, quantity: quantityValue, product_id: productValue, updated_by: '', updated_at: '' },
            ],
        }));
        setProductValue('');
        setQuantityValue('');
        setRemainingQuantity('')
    };

    const handleRemoveProduct = (index) => {
        setSingleStockOut((prev: any) => ({
            ...prev,
            stock_products: [
                ...prev.stock_products.slice(0, index),
                ...prev.stock_products.slice(index + 1),
            ],
        }));
    };
    const handleProductValidation = (e: any) => {
        const newProduct = Number(e.target.value);
        if (!newProduct) {
            setProductValidationError('Product is required.');
        }
        else {
            const productExists = singleStockOut?.stock_products.some(stockProduct => stockProduct.product_id == newProduct);
            if (productExists) {
                setProductValidationError("This Product is already selected");
            } else {
                setProductValidationError("");
            }
        }
    };
    const getProductNameById = (productId: number) => {
        const product = products.find((p) => p.product_id == productId);
        return product ? product.product_name : 'Product not found';
    };
    const getRemainingQuantity = (productId: number) => {
        const product = products.find((p) => p.product_id == productId);
        const remain: any = product ? product.remaining_stock : 'Product not found';
        setRemainingQuantity(remain)
    };
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Edit Stock out</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/stocks/stock-out-list')}
                    >
                        List of Stock Out
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {singleStockOut ? (
                        <>
                            {/* ... Edit Form ... */}
                            <Form>
                                <div className='card-body border-top p-9'>
                                    <div className='mb-2 row mb-6'>
                                        <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>Due Date</label>
                                        <div className='col-lg-8 fv-row'>
                                            <Flatpickr
                                                onChange={(selectedDates) => handleStockoutDateChange(selectedDates)}
                                                className='form-control form-control-solid'
                                                placeholder={formatDateWithoutTime(singleStockOut.date_time)}
                                                options={{
                                                    dateFormat: 'd/m/Y',
                                                }}
                                            />
                                            {dateValidationError && (
                                                <div className='text-danger'>{dateValidationError}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Description</label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Short Description'
                                                value={singleStockOut ? singleStockOut.description : ''}
                                                onChange={(e) => handleStockOutDesChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        {/* department */}
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Department</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <select
                                                className='form-select form-select-solid form-select-lg fw-bold'
                                                value={singleStockOut ? singleStockOut.department_id : ''}
                                                onChange={(e) => {
                                                    handleDepartmentChange(Number(e.target.value));
                                                }}
                                            >
                                                <option value=''>Select a Department...</option>
                                                {Array.isArray(departments) && departments.length > 0 ? (
                                                    departments.map((depart) => (
                                                        <option key={depart.id} value={depart.id}>
                                                            {depart.name}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value='' disabled>No Department available</option>
                                                )}
                                            </select>
                                            {departmentValidationError && (
                                                <div className='text-danger'>{departmentValidationError}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        {/* User */}
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>User</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <select
                                                className='form-select form-select-solid form-select-lg fw-bold'
                                                value={singleStockOut ? singleStockOut.user_id : ''}
                                                onChange={(e) => handleUserChange(e)}
                                            >
                                                <option value=''>Select a User...</option>
                                                {Array.isArray(users) && users.length > 0 ? (
                                                    users.map((user) => (
                                                        <option key={user.id} value={user.id}>
                                                            {user.full_name}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value='' disabled>No user available</option>
                                                )}
                                            </select>
                                            {userValidationError && (
                                                <div className='text-danger'>{userValidationError}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row card mb-5 mb-xl-10'>
                                        <div
                                            className='card-header border-0 cursor-pointer row'
                                        >
                                            <div className='card-title m-3 col-lg-7'>
                                                <h3 className='fw-bolder m-0'>Products for Stock Out</h3>
                                            </div>
                                            <div className='mt-3 col-lg-3'>
                                                <Button data-toggle="tooltip" data-placement="bottom" title="Add Product" variant='primary'
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_1" >
                                                    Add Product
                                                </Button>
                                            </div>
                                        </div>
                                        <div>
                                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mx-6 '>
                                                <thead>
                                                    <tr className='fw-bold text-muted'>
                                                        <th className='min-w-150px required '>Product</th>
                                                        <th className='min-w-100px required'>Quantity</th>
                                                        <th className='min-w-100px '>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {singleStockOut.stock_products.map((product, index) => (
                                                        <tr key={index}>
                                                            <td
                                                            >
                                                                <input
                                                                    type='text'
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    placeholder='Product'
                                                                    value={getProductNameById(product.product_id)}
                                                                    readOnly
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type='number'
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    placeholder='Quantity'
                                                                    value={product.quantity}
                                                                    onChange={(e) => {
                                                                        const newQuantity = parseFloat(e.target.value);
                                                                        const updatedProducts = [...singleStockOut.stock_products];
                                                                        updatedProducts[index].quantity = newQuantity;
                                                                        setSingleStockOut({
                                                                            ...singleStockOut,
                                                                            stock_products: updatedProducts,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <button
                                                                    data-toggle="tooltip" data-placement="bottom" title="Remove this product"
                                                                    type='button'
                                                                    className='btn btn-danger '
                                                                    onClick={() => handleRemoveProduct(index)}
                                                                >
                                                                    Remove Product
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </>
                    ) : null}
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {departmentValidationError || dateValidationError || userValidationError ? (
                        <button type='button' className='btn btn-primary disabled'>
                            Update Requisition
                        </button>
                    ) : (
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Update Requisition"
                            type='button'
                            className='btn btn-primary'
                            onClick={handleUpdateStockOut}
                            disabled={loading}
                        >
                            {!loading && 'Update Stock Out'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    )}
                </div>
            </div>
            {/* Add Product Modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Stock Products</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <>
                                {/* ... Edit Form ... */}
                                <Form>
                                    <div className='form-group'>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'>Product</span>
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <Form.Select
                                                    className='form-select form-select-solid form-select-lg fw-bold'
                                                    value={productValue}
                                                    onChange={(e) => {
                                                        getRemainingQuantity(Number(e.target.value))
                                                        setProductValue(e.target.value)
                                                        handleProductValidation(e)
                                                    }}
                                                >
                                                    <option value=''>Select a Product...</option>
                                                    {Array.isArray(products) && products.length > 0 ? (
                                                        products.map((product) => (
                                                            <>
                                                            { singleStockOut?.stock_products.some(stockProduct => stockProduct.product_id == product.product_id) ? (

                                                                <option key={product.product_id} value={product.product_id} disabled className="bg-secondary text-white">
                                                                    {product.product_name} ding dong
                                                                </option>
                                                            ) : (

                                                                <option key={product.product_id} value={product.product_id}>
                                                                    {product.product_name}
                                                                </option>
                                                            )}
                                                            </>
                                                    ))
                                                    ) : (
                                                    <option value='' disabled>No products available</option>
                                                    )}

                                                </Form.Select>
                                                {productValidationError && (
                                                    <div className='text-danger'>{productValidationError}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                Remaining Qunatity
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Quantity'
                                                    value={remainingQuantity}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                                Qunatity
                                            </label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Quantity'
                                                    value={quantityValue}
                                                    onChange={(e) => {
                                                        setQuantityValue(e.target.value)
                                                        handleQuantityValidation(e)
                                                    }}
                                                />
                                                {quantityValidationError && (
                                                    <div className='text-danger'>{quantityValidationError}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        </div>
                        <div className='modal-footer'>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                {quantityValidationError || productValidationError || !quantityValue ? (
                                    <button data-toggle="tooltip" data-placement="bottom" title="Add Product" type='button' className='btn btn-primary disabled'>
                                        Add Product
                                    </button>
                                ) : (
                                    <button
                                        data-toggle="tooltip" data-placement="bottom" title="Add Product"
                                        type='button'
                                        className='btn btn-primary'
                                        data-bs-dismiss='modal'
                                        onClick={handleAddProduct}
                                        disabled={loading}
                                    >
                                        {!loading && 'Add Product'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EditStockOut;