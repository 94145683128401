import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { KTIcon } from '../../../../_metronic/helpers';
import { KTSVG } from '../../../../_metronic/helpers';
import { change_active_status_user, delete_user, get_all_users, get_filtered_users } from '../api/usersApi';
import { formatDateFlatpickr, formatDateWithTime } from '../../../helper-functions/DateFunction';
import { useNavigate } from 'react-router-dom';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
import { addDepartmentList } from '../../../modules/accounts/components/settings/SettingsModel';
import { Roles } from '../roles/components/RolesModel';
import { get_active_department } from '../../departments/api/departmentsApi';
import { get_all_active_roles } from '../roles/api/rolesApi';
import { Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';

type User = {
  id: number;
  title: string | undefined;
  picture: string | undefined;
  address: string | undefined;
  email: string | undefined;
  firstname: string | undefined;
  middlename: string | undefined;
  role: string | undefined;
  lastname: string | undefined;
  mobile: string | undefined;
  telephone: string | undefined;
  status: number | undefined;
  department_name: string | undefined;
  created_at: string;
  updated_at: string;
};
var user_add, user_edit, user_view, user_logs, user_delete
function UsersList() {
  const [userList, setUserList] = useState<User[]>([]);
  const [departmentList, setDepartmentList] = useState<addDepartmentList[]>([]);
  const [roleList, setRoleList] = useState<Roles[]>([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [roleId, setRoleId] = useState('');
  const [departmentId, setdepartmentId] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [visibleData, setVisibleData] = useState<User[]>([]);
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const fromDateRef = useRef<Flatpickr>(null);
  const toDateRef = useRef<Flatpickr>(null);
  user_add = permissionCheck('user-create')
  user_edit = permissionCheck('user-update')
  user_view = permissionCheck('user-view')
  user_logs = permissionCheck('user-activity-log')
  user_delete = permissionCheck('user-delete')
  const { data, isLoading, isError } = useQuery('users', get_all_users, { refetchOnWindowFocus: false });
  useEffect(() => {
    if (data) {
      setUserList(data.data)
    }
  }, [data])
  const { data: departmentData } = useQuery('departments', get_active_department, {
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    if (departmentData) {
      setDepartmentList(departmentData.data as addDepartmentList[])
    }
  }, [departmentData])
  const { data: rolesData } = useQuery('roles', get_all_active_roles, {
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    if (rolesData) {
      setRoleList(rolesData.data as Roles[])
    }
  }, [rolesData])
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems = userList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  useEffect(() => {
    if (userList.length > 0) {
      let sortedData = [...userList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          if (sortBy && sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else {
            const aValue = (a[sortBy?.key] || '') as string;
            const bValue = (b[sortBy?.key] || '') as string;
            return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage, // Update slice based on selected items per page
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [userList, currentPage, selectedItemsPerPage, sortBy]);
  const navigate = useNavigate();
  const handleUserLogs = (user: any) => {
    navigate("/users/user-logs", { state: { id: user.id } });
  };
  const handleDeleteUser = useMutation((id: number) => {
    return delete_user(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        get_all_users()
          .then((data) => {
            setUserList(data?.data)
          })
          .catch((error: any) => {
            if (error.response) {
              const errorMessage = error.response.data.message;
              toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 2000,
              });
            }
          });
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.message
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        };
      });
  });
  const { data: filterData, refetch } = useQuery(
    'filterData',
    () => get_filtered_users(fromDateFilter, toDateFilter, departmentId, roleId, selectedStatus, updatedBy),
    {
      refetchOnWindowFocus: false,
      onSuccess: (filteredData) => {
        setUserList(filteredData.data);
      },
      onError: (error: any) => {
        // Optionally, you can display a toast or any other error handling mechanism
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        };
      },
      enabled: false,
    }
  );
  const handleApplyFilters = () => {
    // Manually refetch data when applying filters
    refetch();
  };
  const handleClearFilters = () => {
    if (fromDateRef.current) {
      fromDateRef.current.flatpickr.clear();
    }
    if (toDateRef.current) {
      toDateRef.current.flatpickr.clear();
    }
    setFromDateFilter('');
    setToDateFilter('');
    setRoleId("");
    setdepartmentId("");
    setSelectedStatus("");
    setUpdatedBy("")
    if (data) {
      setUserList(data.data);
    }
  }
  const handleDateFromChange = (date) => {
    if (date && date[0]) {
      const selectedDate = new Date(date[0]);
      selectedDate.setHours(0, 0, 0, 0);
      const formattedDate = formatDateFlatpickr(selectedDate);
      setFromDateFilter(formattedDate);

      // Update the "To Date" Flatpickr options
      if (toDateRef.current) {
        toDateRef.current.flatpickr.set('minDate', selectedDate);
      }
    } else {
      setFromDateFilter('');
      // Reset the "To Date" picker minDate when "From Date" is cleared
      if (toDateRef.current) {
        toDateRef.current.flatpickr.set('minDate', null);
      }
    }
  };
  const handleDateToChange = (date) => {
    if (date && date[0]) {
      const selectedDate = new Date(date[0]);
      selectedDate.setHours(0, 0, 0, 0);
      const formattedDate = formatDateFlatpickr(selectedDate);
      setToDateFilter(formattedDate);
    } else {
      setToDateFilter('');
    }
  };
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value.toLowerCase();
    setSearchInput(searchTerm);
    const filteredItems = data.data.filter((user: any) => {
      return (
        user.firstname?.toLowerCase().includes(searchTerm) ||
        user.lastname?.toLowerCase().includes(searchTerm) ||
        user.title?.toLowerCase().includes(searchTerm) ||
        user.role?.toLowerCase().includes(searchTerm) ||
        user.middlename?.toLowerCase().includes(searchTerm) ||
        user.email?.toLowerCase().includes(searchTerm) ||
        user.mobile?.toLowerCase().includes(searchTerm) ||
        user.telephone?.toString().includes(searchTerm)
      );
    });
    setUserList(filteredItems);
  };
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_user(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        return get_all_users().then((data) => {
          setUserList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key) {
      // If sortBy is not defined or if sorting a different column, set ascending to true
      setSortBy({ key, ascending: false });
    } else if (key === "updated_by_name") {
      // Toggle between ascending and descending order
      setSortBy({ key, ascending: !sortBy.ascending });
    } else {
      // If sorting the same column, toggle the ascending state
      setSortBy({ key, ascending: !sortBy.ascending });
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleUserEdit = (user: any) => {
    navigate("/users/edit-user", { state: { user: user } });
  };
  const handleUserView = (user: any) => {
    navigate("/users/view-user", { state: { user: user } });
  };
  if (isLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Users List</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {user_add && (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Navigate to Add Subcategory"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/users/add-user')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add User
            </button>
          )}
        </div>
      </div>
      {/*Search And Filter*/}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            data-toggle="tooltip" data-placement="bottom" title="Search a User"
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search User'
            value={searchInput}
            onChange={handleInputChange}
          />
        </h3>

        <div
          className='card-toolbar'
        >
          <h5 className='p-3'>Filter by:</h5>
          <div className='mx-3'>
            <Flatpickr
              ref={fromDateRef}
              onChange={handleDateFromChange}
              className='form-control form-control-solid'
              placeholder='From Date'
              options={{
                dateFormat: 'd/m/Y',
              }}
            />
          </div>
          <div className='mx-3'>
            <Flatpickr
              ref={toDateRef}
              onChange={handleDateToChange}
              className='form-control form-control-solid'
              placeholder='From Date'
              options={{
                dateFormat: 'd/m/Y',
              }}
            />
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <div
          className='card-toolbar'
        >
          <div className='mx-3'>
            <Form.Group controlId='category'>
              <Form.Select
                value={roleId}
                className='form-select form-select-solid form-select-lg fw-bold'
                onChange={(e) => setRoleId(e.target.value)}
              >
                <option value=''>Select a Role...</option>
                {Array.isArray(roleList) && roleList.length > 0 ? (
                  roleList?.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))
                ) : (
                  <option value='' disabled>No roles available</option>
                )}
              </Form.Select>
            </Form.Group>
          </div>
          <div className='mx-3'>
            <Form.Select
              value={departmentId}
              className={`form-select form-select-solid form-select-lg fw-bold`}
              onChange={(e) => setdepartmentId(e.target.value)}
            >
              <option value=''>Select a Department...</option>
              {Array.isArray(departmentList) && departmentList.length > 0 ? (
                departmentList?.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.name}
                  </option>
                ))
              ) : (
                <option value='' disabled>No departments available</option>
              )}
            </Form.Select>
          </div>
          <div className='mx-3'>
            <Form.Select
              value={updatedBy}
              className={`form-select form-select-solid form-select-lg fw-bold`}
              onChange={(e) => setUpdatedBy(e.target.value)}
            >
              <option value=''>Updated By...</option>
              {Array.isArray(userList) && userList.length > 0 ? (
                userList?.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstname + ' ' + user.lastname? user.lastname : ''}
                  </option>
                ))
              ) : (
                <option value='' disabled>No users available</option>
              )}
            </Form.Select>
          </div>
          <div className='mx-3'>
            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value=''>Filter by Status...</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
        <div>
          <button
            className='btn btn-primary me-3'
            onClick={handleApplyFilters}
          >
            Apply Filters
          </button>
          <button
            className='btn btn-light-danger'
            onClick={handleClearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-180px'>Picture</th>
                <th className='min-w-180px'>Full Name <span onClick={() => handleSort('full_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-180px'>Role <span onClick={() => handleSort('role')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-180px'>Department <span onClick={() => handleSort('department_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-190px'>Status <span onClick={() => handleSort('status')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-190px'>Updated By <span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-120px'>Updated At <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                {user_delete || user_edit || user_logs || user_view ? (
                  <th className='min-w-160px text-center'>Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {isError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : userList && userList.length > 0 ? (
                visibleData?.map((user: any) => (
                  <tr key={user.id}>
                    <td>
                      <div className='symbol'>
                        {user && user.picture === null ? (
                          <div className="symbol symbol-circle symbol-50px">
                            <div className="symbol-label  fs-1 fw-bold text-inverse-dark bg-dark">{user.firstname ? user.firstname[0].toUpperCase() : ''}</div>
                          </div>
                        ) : (
                          <img alt='User Picture' src={user?.picture} />
                        )}
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer text-hover-success fs-6'>
                            {user.full_name}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {user.role}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {user.department_name}
                        </div>
                      </div>
                    </td>
                    <td>{user.status === 1 ? (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                            Active
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>
                            Inactive
                          </h3>
                        </div>
                      </div>
                    )}
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {user.updated_by_name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {formatDateWithTime(user.updated_at)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {user_edit && (
                          <button
                            onClick={() => handleUserEdit({ ...user })}
                            data-toggle="tooltip" data-placement="bottom" title="Edit User"
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        )}
                        {user_view && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="See User Details"
                            onClick={() => handleUserView(user)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </button>
                        )}
                        {user_add || user_edit ? (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Switch Status"
                            onClick={() => handleStatusUpdate.mutate(user.id)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='update-folder' className='fs-3' />
                          </button>
                        ) : null}
                        {user_logs && (
                          <button
                            onClick={() => {
                              handleUserLogs(user);
                            }}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-custom-class='tooltip-dark'
                            title='User Logs'
                          >
                            <i className='bi bi-journal-text'></i>
                          </button>
                        )}
                        {user_delete && (
                          <button
                            onClick={() => setSelectedUser({ ...user })}
                            data-toggle="tooltip" data-placement="bottom" title="Delete User"
                            className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Users Available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
        <div className='align-items-center row'>
          <div className='d-flex'>
            <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
            <select
              style={{ width: '60px' }}
              className='form-select form-select-md px-2 col-md-1'
              value={selectedItemsPerPage}
              onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
            >
              {itemsPerPageOptions.map(option => (
                <option key={option} value={option} className='p-3 '>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {currentPage === 1 ? (
            <button
              className='btn btn-light'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          ) : (
            <button
              className='btn btn-primary'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          <button
            className='btn btn-primary ms-2'
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete User Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedUser ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold'>FullName: </span>    {selectedUser.title} {selectedUser.firstname}  {selectedUser.middlename}  {selectedUser.lastname}
                  </p>
                  <p className='mb-2'>
                    <span className='fw-bold'>Email: </span>{' '}
                    {selectedUser.email}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  if (selectedUser) {
                    handleDeleteUser.mutate(selectedUser.id)
                  }
                }}
                data-bs-dismiss='modal'
              >
                Delete User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default UsersList;