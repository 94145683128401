import { useEffect } from 'react'
import secureLocalStorage from 'react-secure-storage';
export function Logout() {
  useEffect(() => {
    localStorage.removeItem('kt-auth-react-v');
    localStorage.removeItem('kt-auth-react-v-expiration');
    localStorage.removeItem('kt_theme_mode_value');
    secureLocalStorage.removeItem('user_permissions');
    // secureLocalStorage.removeItem('baseURL');
    secureLocalStorage.removeItem('general_settings');
    window.location.href = '/auth/login';
  }, []);

  return (
    <></>
  );
}
