import { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { addProduct, addProductsInitValues as initialValues } from '../../../modules/accounts/components/settings/SettingsModel'
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom'
import { add_product } from '../../products/api/productsApi';
import { get_all_categories } from '../../categories/api/categoriesApi';
import { get_all_subcategories } from '../../subcategories/api/subcategoriesApi';
import { toast } from 'react-toastify';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';

const productSchema = Yup.object().shape({
  product_type: Yup.string().required("Product Type is required"),
  categoryTitle: Yup.string().required('Category is required'),
  subcategoryTitle: Yup.string().required('Subcategory Title is required'),
  pName: Yup.string().required('Product Name is required'),
  shortDescription: Yup.string().required('Short Description is required'),
  price: Yup.number()
    .required('Price is required')
    .test(
      'maxDigitsAfterDecimal',
      'Price field must have 2 digits after decimal or less',
      (number) => Number.isInteger(number * 10 ** 2)
    )
    .positive('Price must be a positive number'),
});
interface Subcategory {
  id: number;
  title: string;
  categories_id: number;
  categories_title: string;
};
interface Category {
  id: number;
  title: string;
};
function AddNewProduct() {
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [productPicture, setProductPicture] = useState<File | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const { data: categoryData } = useQuery('categories', get_all_categories, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (categoryData && Array.isArray(categoryData.data)) {
      setCategories(categoryData.data as Category[]);
    }
  }, [categoryData]);
  const formik = useFormik<addProduct>({
    initialValues,
    validationSchema: productSchema,
    onSubmit: async (values, action) => {
      setLoading(true);
      // Create a FormData object
      const formData = new FormData();
      // Append form data
      formData.append('categories_id', values.categoryTitle);
      formData.append('sub_categories_id', values.subcategoryTitle);
      formData.append('name', values.pName);
      formData.append('product_type', values.product_type);
      formData.append('short_description', values.shortDescription);
      formData.append('long_descrition', values.longDescription);
      formData.append('price', values.price);
      if (productPicture) {
        formData.append('picture', productPicture);
      };
      try {
        const result = await add_product(formData);
        toast.success(result.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        action.resetForm();
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      } finally {
        setLoading(false);
      };
    },
  });
  const HandleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setProductPicture(e.target.files[0]);
    };
  };
  const handleCategoryChange = async (selectedCategoryId) => {
    const subcategoriesData = await get_all_subcategories();
    if (Array.isArray(subcategoriesData.data)) {
      // Access the "data" property
      const filteredSubcategories = subcategoriesData.data.filter(
        (subcategory) => subcategory.categories_id === selectedCategoryId
      )
      setSubcategories(filteredSubcategories);
      return filteredSubcategories;
    } else {
      return [];
    };
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Category</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('categoryTitle')}
                  value={formik.values.categoryTitle}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleCategoryChange(Number(e.target.value));
                  }}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Select a Type...</option>
                  {Array.isArray(categories) && categories.length > 0 ? (
                    categories.map((categoryType) => (
                      <option key={categoryType.id} value={categoryType.id}>
                        {categoryType.title}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>No categories available</option>
                  )}
                </select>
                {formik.touched.categoryTitle && formik.errors.categoryTitle && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.categoryTitle}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Subcategory</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('subcategoryTitle')}
                  value={formik.values.subcategoryTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Select a Type...</option>
                  {Array.isArray(subcategories) && subcategories.length > 0 ? (
                    subcategories.map((subcategoryType) => (
                      <option key={subcategoryType.id} value={subcategoryType.id}>
                        {subcategoryType.title}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>No subcategories available</option>
                  )}
                </select>
                {formik.touched.subcategoryTitle && formik.errors.subcategoryTitle && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.subcategoryTitle}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Product Type:</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('product_type')}
                >
                  <option value=''>Select a Type...</option>
                  <option value='Asset'>Asset</option>
                  <option value='consumable'>Consumable</option>
                </select>
                {formik.touched.product_type && formik.errors.product_type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.product_type}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Product Name</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product Name'
                  {...formik.getFieldProps('pName')}
                />
                {formik.touched.pName && formik.errors.pName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.pName}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Product Picture</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='file'
                  className='form-control form-control-lg form-control-solid'
                  onChange={(e) => HandleImageChange(e)}
                  name='picture'
                />
                <div className='text-danger mt-2'>
                  Recommended size 200 x 200 px. jpg, jpeg, or png only
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Short Description
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Short Description'
                  {...formik.getFieldProps('shortDescription')}
                />
                {formik.touched.shortDescription && formik.errors.shortDescription && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.shortDescription}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Long Description</label>
              <div className='col-lg-8 fv-row'>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Long Description'
                  {...formik.getFieldProps('longDescription')}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Price ({getSettingValue('currency_symbol')})</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  step='0.01'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Price'
                  {...formik.getFieldProps('price')}
                />
                {formik.touched.price && formik.errors.price && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.price}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              data-bs-dismiss='modal'
              data-toggle="tooltip"
              data-placement="bottom"
              title="Add New Product"
              type='submit'
              className='btn btn-primary'
              disabled={loading || !formik.isValid} // Disable the button if form is invalid or loading
            >
              {!loading && 'Add Product'}
              {loading && (
                <span className='indicator-progress d-block'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddNewProduct;
