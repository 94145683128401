import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
// Add stock out
export const add_stock_out = async (values) => {
  let response = await axios.post(baseURL + "/api/stock-out-products", values);
  return response.data;
}
// Add Stock
export const add_stock_in = async (formData: object) => {
  const response = await axios.post(baseURL + "/api/stock/", formData, {
  });
  return response.data;
};
// Add Stock in against po
export const add_stock_in_against_po = async (id: number) => {
  const response = await axios.post(baseURL + "/api/stock/" + id );
  return response.data;
};
// Get ALL Stock out
export const get_all_stock_out = async () => {
  let response = await axios.post(baseURL + "/api/filter-stock-out");
  return response.data;  
}
// Get Single Stock
export const get_all_stocks= async () => {
  let response = await axios.get(baseURL + "/api/stock");
  return response.data;  
}
// Get Single Stock
export const get_single_stock= async (id:number) => {
  let response = await axios.get(baseURL + "/api/stock/"+id);
  return response.data;  
}
// Delete User
export const delete_stock_out = async (id: number) => {
  let response = await axios.delete(baseURL + "/api/stock/" + id);
  return response.data;
}
// List Of Stocks
export const get_all_stock_in = async () => {
  let response = await axios.post(baseURL + "/api/filter-stock-in");
  return response.data;
}
// Delete Stocks
export const delete_stock_in = async (id: number) => {
  let response = await axios.delete(baseURL + "/api/stock/"+id);
  return response.data;
}
export const update_stock_out= async (updatedStockout) => {
  let id : number;
  id = updatedStockout.id
  let response = await axios.post(baseURL + "/api/stock-out-update/"+id, updatedStockout);
  return response.data;
}
// Get ALL Products in stock
export const get_all_products_in_stock = async () => {
  let response = await axios.get(baseURL + "/api/stock-products-with-balance");
  return response.data;  
}
// Update Stock In
export const update_stock_in = async (id, stockData) => {
    const response = await axios.post(
      `${baseURL}/api/stock-in-update/${id}`,
      stockData
    );
    return response.data;
};
// Get Single Stock Logs by ID
export const get_single_stocks_logs = async (id: number) => {
  let response = await axios.get(baseURL + "/api/stock-activity-logs/" + id);
  return response.data;
};
// Get All Stock Logs by ID
export const get_all_stock_in_logs = async () => {
  let response = await axios.get(baseURL + "/api/activity-logs-for-all-stock-in");
  return response.data;
};
// Get All Stock Logs
export const get_all_stock_out_logs = async () => {
  let response = await axios.get(baseURL + "/api/activity-logs-for-all-stock-out");
  return response.data;
}
  //Filters on Stock in Date Range And Vendor
  export const get_all_filters_item_stock_in =async (fromDate, toDate,supplier) => {
    const response = await axios.post(baseURL + '/api/filter-stock-in', {
        from_date: fromDate,
        to_date: toDate,
        supplier_id:supplier
      });
  
      return response.data;
  };

   //Filters on Stock Out in Date Range And Vendor 
   export const get_all_filters_item_stock_out=async (fromDate, toDate,department,user) => {
    const response = await axios.post(baseURL + '/api/filter-stock-out', {
        from_date: fromDate,
        to_date: toDate,
        department_id:department,
        user_id:user
      });
  
      return response.data;
  };
  export const stock_requisitions_products = async (id: number) => {
    let response = await axios.post(baseURL + "/api/stock-requisition-products/" + id)
    return response.data;
}