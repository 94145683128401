/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { permissionCheck } from '../../../../../app/helper-functions/PermissionCheck'

var vendor_add, vendor_view, vendor_logs
var category_add, category_view, category_logs
var subcategory_add, subcategory_view, subcategory_logs
var product_add, product_view, product_logs
var requisition_type_add, requisition_type_view, requisition_type_logs
var requisition_add, requisition_view, requisition_logs
var quotation_add, quotation_view, quotation_logs
var purchaseorder_add, purchaseorder_view, purchaseorder_logs
var stock_add, stock_view, stock_logs
var department_add, department_logs, department_view
var user_add, user_view, user_logs
var role_add, role_view, role_logs

const SidebarMenuMain = () => {
  const intl = useIntl()
  vendor_add = permissionCheck('supplier-create')
  vendor_view = permissionCheck('supplier-view')
  vendor_logs = permissionCheck('supplier-activity-log')

  category_add = permissionCheck('category-create')
  category_view = permissionCheck('category-view')
  category_logs = permissionCheck('category-activity-log')

  subcategory_add = permissionCheck('subcategory-create')
  subcategory_view = permissionCheck('subcategory-view')
  subcategory_logs = permissionCheck('subcategory-activity-log')

  product_add = permissionCheck('product-create')
  product_view = permissionCheck('product-view')
  product_logs = permissionCheck('product-activity-log')

  requisition_type_add = permissionCheck('requisitionType-create')
  requisition_type_view = permissionCheck('requisitionType-view')
  requisition_type_logs = permissionCheck('requisitionType-activity-log')

  requisition_add = permissionCheck('requisition-create')
  requisition_view = permissionCheck('requisition-view')
  requisition_logs = permissionCheck('requisition-activity-log')

  quotation_add = permissionCheck('quotation-create')
  quotation_view = permissionCheck('quotation-view')
  quotation_logs = permissionCheck('quotation-activity-log')

  purchaseorder_add = permissionCheck('purchaseOrder-create')
  purchaseorder_view = permissionCheck('purchaseOrder-view')
  purchaseorder_logs = permissionCheck('purchaseOrder-activity-log')

  department_add = permissionCheck('department-create')
  department_logs = permissionCheck('department-activity-log')
  department_view = permissionCheck('department-view')

  stock_add = permissionCheck('stock-create')
  stock_view = permissionCheck('stock-view')
  stock_logs = permissionCheck('stock-activity-log')

  user_add = permissionCheck('user-create')
  user_view = permissionCheck('user-view')
  user_logs = permissionCheck('user-activity-log')

  role_add = permissionCheck('role-create')
  role_view = permissionCheck('role-view')
  role_logs = permissionCheck('role-activity-log')
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
      </div>
      {/* vendors  */}
      {vendor_add || vendor_logs || vendor_view ? (
        <SidebarMenuItemWithSub
          to='/vendors'
          title='Vendors'
          icon='profile-circle'
          fontIcon='bi-person'
        > {vendor_add && (<SidebarMenuItem to='/vendors/add-vendor' title='Add Vendor' hasBullet={true} />)}
          {vendor_view && (<SidebarMenuItem to='/vendors/vendors-list' title='List of Vendors' hasBullet={true} />)}
          {vendor_logs && (<SidebarMenuItem to='/vendors/all-vendors-logs' title='Logs' hasBullet={true} />)}
        </SidebarMenuItemWithSub>
      ) : null}
      {/* categories */}
      {category_add || category_logs || category_view || subcategory_add || subcategory_logs || subcategory_view ? (
        <SidebarMenuItemWithSub
          to='/categories'
          title='Categories'
          fontIcon='bi-archive'
          icon='element-plus'
        >
          {category_add && (<SidebarMenuItem to='/categories/add-category' title='Add Category' hasBullet={true} />)}
          {category_view && (<SidebarMenuItem to='/categories/categories-list' title='List of Categories' hasBullet={true} />)}
          {category_logs && (<SidebarMenuItem to='/categories/all-categories-logs' title='Category Logs' hasBullet={true} />)}
          {subcategory_add && (<SidebarMenuItem to='/categories/subcategories/add-subcategory' title='Add Subcategory' hasBullet={true} />)}
          {subcategory_view && (<SidebarMenuItem to='/categories/subcategories/subcategories-list' title='List of Subcategories' hasBullet={true} />)}
          {subcategory_logs && (<SidebarMenuItem to='/categories/subcategories/all-subcategories-logs' title='Subcategory Logs' hasBullet={true} />)}
        </SidebarMenuItemWithSub>
      ) : null}


      {/*  products */}
      {product_add || product_logs || product_view ? (

        <SidebarMenuItemWithSub
          to='/products'
          title='Products'
          icon='add-item'
          fontIcon='bi-person'
        >
          {product_add && (<SidebarMenuItem to='/products/add-product' title='Add Product' hasBullet={true} />)}
          {product_view && (<SidebarMenuItem to='/products/products-list' title='List of Products' hasBullet={true} />)}
          {product_logs && (<SidebarMenuItem to='/products/all-products-logs' title='Logs' hasBullet={true} />)}
        </SidebarMenuItemWithSub>
      ) : null}
      {/* requisitions */}
      {requisition_type_add || requisition_type_logs || requisition_type_view || requisition_add || requisition_logs || requisition_view ? (

        <SidebarMenuItemWithSub
          to='/requisitions'
          title='Requisitions'
          fontIcon='bi-archive'
          icon='abstract-25'
        >
          {requisition_type_add || requisition_type_logs || requisition_type_view ? (
            <SidebarMenuItemWithSub to='/requisition-type' title='Requisition Type' hasBullet={true}>
              {requisition_type_add && (<SidebarMenuItem to='/requisition-type/add-requisition-type' title='Add Requisition Type ' hasBullet={true} />)}
              {requisition_type_view && (<SidebarMenuItem to='/requisition-type/requisition-type-list' title='List of Requisition Types' hasBullet={true} />)}
              {requisition_type_logs && (<SidebarMenuItem to='/requisition-type/all-requisition-type-logs' title='Logs' hasBullet={true} />)}
            </SidebarMenuItemWithSub>
          ) : null}
          {requisition_add || requisition_logs || requisition_view ? (

            <SidebarMenuItemWithSub to='/requisitions' title='Requisitions' hasBullet={true}>
              {requisition_add && (<SidebarMenuItem to='/requisitions/add-requisition' title='Add Requisition' hasBullet={true} />)}
              {requisition_view && (<SidebarMenuItem to='/requisitions/requisitions-list' title='List of Requisitions' hasBullet={true} />)}
              {requisition_logs && (<SidebarMenuItem to='/requisitions/all-requisitions-logs' title='Logs' hasBullet={true} />)}
            </SidebarMenuItemWithSub>
          ) : null}
        </SidebarMenuItemWithSub>
      ) : null}

      {/* quotations */}
      {quotation_add || quotation_logs || quotation_view ? (
        <SidebarMenuItemWithSub
          to='/quotations'
          title='Quotations'
          fontIcon='bi-archive'
          icon='abstract-28'
        >
          {/* <SidebarMenuItemWithSub to='/quotation-type' title='Quotation Type' hasBullet={true}>
          <SidebarMenuItem to='/quotation-type/add-quotation-type' title='Add Quotation Type' hasBullet={true} />
          <SidebarMenuItem to='/quotation-type/quotation-type-list' title='List of Quotation Types' hasBullet={true} />
          <SidebarMenuItem to='/quotation-type/all-quotations-type-logs' title='Logs' hasBullet={true} />
        </SidebarMenuItemWithSub> */}

          {/* <SidebarMenuItemWithSub to='/quotations' title='New Quotations' hasBullet={true}> */}
          {quotation_add && (<SidebarMenuItem to='/quotations/add-quotation' title='Add Quotation' hasBullet={true} />)}
          {quotation_view && (<SidebarMenuItem to='/quotations/quotations-list' title='List of Quotations' hasBullet={true} />)}
          {quotation_logs && (<SidebarMenuItem to='/quotations/all-quotations-logs' title='Logs' hasBullet={true} />)}
          {/* </SidebarMenuItemWithSub> */}
        </SidebarMenuItemWithSub>
      ) : null}

      {/*Purchases*/}
      {purchaseorder_add || purchaseorder_view || purchaseorder_logs ? (
        <SidebarMenuItemWithSub
          to='/purchase-order'
          title='Purchase Orders'
          fontIcon='bi-archive'
          icon='receipt-square'
        >
          {purchaseorder_add && (<SidebarMenuItem to='/purchase-order/add-purchase-order' title='Add Purchase Order' hasBullet={true} />)}
          {purchaseorder_view && (<SidebarMenuItem to='/purchase-order/purchase-order-list' title='List of Purchase Orders' hasBullet={true} />)}
          {purchaseorder_logs && (<SidebarMenuItem to='/purchase-order/all-purchases-logs' title='Logs' hasBullet={true} />)}
        </SidebarMenuItemWithSub>
      ) : null}
      {/*Stocks*/}
      {stock_add || stock_logs || stock_view ? (
        <SidebarMenuItemWithSub
          to='/stocks'
          title='Stocks'
          fontIcon='bi-archive'
          icon='package'
        >
          {stock_add && (<SidebarMenuItem to='/stocks/add-stock-in' title='Stock In' hasBullet={true} />)}
          {stock_view && (<SidebarMenuItem to='/stocks/stock-in-list' title='List of Stock In' hasBullet={true} />)}
          {stock_logs && (<SidebarMenuItem to='/stocks/all-stock-in-logs' title='Stock In Logs' hasBullet={true} />)}
          {stock_add && (<SidebarMenuItem to='/stocks/add-stock-out' title='Stock Out' hasBullet={true} />)}
          {stock_view && (<SidebarMenuItem to='/stocks/stock-out-list' title='List of Stock Out' hasBullet={true} />)}
          {stock_logs && (<SidebarMenuItem to='/stocks/all-stock-out-logs' title='Stock Out Logs' hasBullet={true} />)}
        </SidebarMenuItemWithSub>
      ) : null}

      {department_add || department_view || department_logs ? (
        <SidebarMenuItemWithSub
          to='/departments'
          title='Department'
          fontIcon='bi-archive'
          icon='some-files'
        >
          {department_add && (<SidebarMenuItem to='/departments/add-department' title='Add Department' hasBullet={true} />)}
          {department_view && (<SidebarMenuItem to='/departments/departments-list' title='List of Department' hasBullet={true} />)}
          {department_logs && (<SidebarMenuItem to='/departments/all-department-logs' title='Logs' hasBullet={true} />)}
        </SidebarMenuItemWithSub>
      ) : null}

      {/* Users */}
      {user_add || user_logs || user_view || role_add || role_logs || role_view ? (
        <SidebarMenuItemWithSub
          to='/users'
          title='User Management'
          icon='profile-circle'
          fontIcon='bi-person'
        >
          {user_add || user_logs || user_view ? (
            <SidebarMenuItemWithSub to='/users' title='Users' hasBullet={true}>
              {user_add && (<SidebarMenuItem to='/users/add-user' title='Add User' hasBullet={true} />)}
              {user_view && (<SidebarMenuItem to='/users/users-list' title='User List' hasBullet={true} />)}
              {user_logs && (<SidebarMenuItem to='/users/all-users-logs' title='Logs' hasBullet={true} />)}
            </SidebarMenuItemWithSub>
          ) : null}
          {role_add || role_logs || role_view ? (
            <SidebarMenuItemWithSub to='/users/roles' title='Roles' hasBullet={true}>
              {role_add && (<SidebarMenuItem to='/users/roles/add-role' title='Add Role' hasBullet={true} />)}
              {role_view && (<SidebarMenuItem to='/users/roles/roles-list' title='List of Roles' hasBullet={true} />)}
              {role_logs && (<SidebarMenuItem to='/users/roles/all-roles-logs' title='Logs' hasBullet={true} />)}
            </SidebarMenuItemWithSub>
          ) : null}

        </SidebarMenuItemWithSub>
      ) : null}
      {/* Product Types */}
      {product_view && (
      <SidebarMenuItemWithSub
        to='/productTypes'
        title='Product Types'
        icon='abstract-26'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/productTypes/assets-list' title='List of Assets' hasBullet={true} />
        <SidebarMenuItem to='/productTypes/consumables-list' title='List of Consumables' hasBullet={true} />
      </SidebarMenuItemWithSub>
      )}
      {/* Organization */}
      <SidebarMenuItem icon='setting-2' to='/generalSettings/general-setting' title='General Settings' />
      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }
