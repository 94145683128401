import secureLocalStorage from "react-secure-storage";
export const getSettingValue = (settingKey) => {
    const value = secureLocalStorage.getItem("general_settings");
    if (Array.isArray(value)) {
        const setting = value.find(item => item.setting_key === settingKey);
        return setting ? setting.setting_value : null;
    } else {
        console.error('Data is not available in localStorage or not in the correct format (not an array)');
        return null;
    }
}