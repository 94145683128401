import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  addVendor,
  addVendorInitValues as initialValues,
} from '../../../modules/accounts/components/settings/SettingsModel'
import {add_vendor} from '../api/vendorsApi'
import {useMutation, useQueryClient} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
const numberRegex = /^[+0-9]+$/;
const profileDetailsSchema = Yup.object().shape({
  type: Yup.string().required("Vendor's Type is required"),
  vName: Yup.string().required("Vendor's name is required"),
  vEmail: Yup.string()
    .required('Email is required')
    .matches(/^[A-Za-z0-9._%+-]+@([A-Za-z0-9.-]+\.[A-Za-z]{2,4})$/, 'Invalid email address'),
    vTelephone: Yup.string()
    .matches(numberRegex, 'Telephone number must be in numeric value'),
  vMobile: Yup.string()
    .required('Mobile Number is required')
    .matches(numberRegex, 'Mobile Number must be in numeric value'),
  address1: Yup.string().required('Address is required'),
  address2: Yup.string(),
  town: Yup.string().required('Town is required'),
  city: Yup.string().required('City is required'),
  postcode: Yup.string().required('Postcode is required'),
})

function AddVendor() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const mutation = useMutation(add_vendor, {
    onSettled: (data, error) => {
      if (!error) {
        toast.success(data.message, {
          position: 'top-right',
          autoClose: 3000,
        })
        navigate('/vendors/vendors-list')
        setLoading(false)
        queryClient.invalidateQueries('vendors')
      } else {
        toast.error('An error occurred. Please try again later.', {
          position: 'top-right',
          autoClose: 3000,
        })
        setLoading(false)
      }
    },
  })
  const [loading, setLoading] = useState(false)
  const formik = useFormik<addVendor>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      mutation.mutate({
        supplier_type: values.type,
        supplier_name: values.vName,
        email: values.vEmail,
        mobile_no: values.vMobile,
        telephone: values.vTelephone,
        address_line_1: values.address1,
        address_line_2: values.address2,
        town: values.town,
        city: values.city,
        post_code: values.postcode,
        status: values.status,
      })
    },
  })
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Vendor</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Vendor Type:</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('type')}
                >
                  <option value=''>Select a Type...</option>
                  <option value='Individual'>Individual</option>
                  <option value='Company'>Company</option>
                </select>
                {formik.touched.type && formik.errors.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.type}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Vendor's Name</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder="Vendor's Name"
                  {...formik.getFieldProps('vName')}
                />
                {formik.touched.vName && formik.errors.vName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.vName}</div>
                  </div>
                )}
                </div>
              </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Vendor's Email</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder="Vendor's Email"
                  {...formik.getFieldProps('vEmail')}
                />
                {formik.touched.vEmail && formik.errors.vEmail && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.vEmail}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Telephone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telephone Number'
                  {...formik.getFieldProps('vTelephone')}
                />
                {formik.touched.vTelephone && formik.errors.vTelephone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.vTelephone}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Mobile</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Mobile Number'
                  {...formik.getFieldProps('vMobile')}
                />
                {formik.touched.vMobile && formik.errors.vMobile && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.vMobile}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Address Line 1</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 1'
                  {...formik.getFieldProps('address1')}
                />
                {formik.touched.address1 && formik.errors.address1 && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.address1}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Address Line 2</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 2'
                  {...formik.getFieldProps('address2')}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Town</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Town'
                  {...formik.getFieldProps('town')}
                />
                {formik.touched.town && formik.errors.town && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.town}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>City</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='City'
                  {...formik.getFieldProps('city')}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.city}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Postcode</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Postcode'
                  {...formik.getFieldProps('postcode')}
                />
                {formik.touched.postcode && formik.errors.postcode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.postcode}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Status</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('status')}
                >
                  <option value='1'>Active</option>
                  <option value='0'>Inactive</option>
                </select>                
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button data-toggle="tooltip" data-placement="bottom" title="Add new Vendor" type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Add Vendor'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddVendor
