import { useState, useEffect, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { get_all_vendors, update_vendor } from '../api/vendorsApi';
import { toast } from 'react-toastify';
type Vendor = {
  id: number;
  supplier_type: string | undefined;
  supplier_name: string | undefined;
  email: string | undefined;
  mobile_no: string | undefined;
  telephone: string | undefined;
  address_line_1: string | undefined;
  address_line_2: string | undefined;
  town: string | undefined;
  city: string | undefined;
  post_code: string | undefined;
  status: string | undefined;
  updated_by: string | undefined;
  created_at: string | undefined;
  updated_at: string | undefined;
};
function EditVendor() {
  const [typeValidationError, setTypeValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>();
  const [telephoneValidationError, setTelephoneValidationError] = useState('');
  const [nameValidationError, setNameValidationError] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');
  const [mobileValidationError, setMobileValidationError] = useState('');
  const [addressValidationError, setAddressValidationError] = useState('');
  const [townValidationError, setTownValidationError] = useState('');
  const [cityValidationError, setCityValidationError] = useState('');
  const [postcodeValidationError, setPostcodeValidationError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const vendor = (location.state as { vendor: Vendor }).vendor;
  const { isError, refetch } = useQuery('vendors', get_all_vendors, {refetchOnWindowFocus: false});
  useEffect(() => {
    setSelectedVendor(vendor);
  }, [vendor]);
  const updateVendorMutation = useMutation(update_vendor, {
    onSuccess: (response) => {
      refetch();
      setLoading(false);
      navigate('/vendors/vendors-list');
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    },
    onError: (error: any) => {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
      setLoading(false);
    },
  });
  const handleUpdateSupplier = () => {
    setLoading(true);
    if (selectedVendor) {
      const {
        id,
        supplier_type,
        supplier_name,
        email,
        mobile_no,
        telephone,
        address_line_1,
        address_line_2,
        town,
        city,
        post_code,
        status,
      } = selectedVendor;
      updateVendorMutation.mutate({
        id,
        supplier_type,
        supplier_name,
        email,
        mobile_no,
        telephone,
        address_line_1,
        address_line_2,
        town,
        city,
        post_code,
        status,
      });
    };
  };
  if (isError) {
    return (
      <div className='text-center'>
        <h2>Error in Get All Vendor logs</h2>
      </div>
    );
  };
  const handleSupplierTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (selectedVendor) {
      const newSupplierType = e.target.value;
      if (!newSupplierType) {
        setTypeValidationError('Supplier type is required.');
      } else {
        setTypeValidationError('');
      };
      setSelectedVendor({
        ...selectedVendor,
        supplier_type: newSupplierType,
      });
    };
  };
  const handleSupplierStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (selectedVendor) {
      setSelectedVendor({
        ...selectedVendor,
        status: e.target.value,
      });
    };
  };
  const handleSupplierNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedVendor) {
      const newSupplierName = e.target.value;
      if (!newSupplierName) {
        setNameValidationError('Supplier Name is required.');
      } else {
        setNameValidationError('');
      };
      setSelectedVendor({
        ...selectedVendor,
        supplier_name: newSupplierName,
      });
    };
  };
  const handleSupplierEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedVendor) {
      const newSupplierEmail = e.target.value;
      setSelectedVendor({
        ...selectedVendor,
        email: newSupplierEmail,
      });
      const emailRegex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9.-]+\.[A-Za-z]{2,4})$/;
      if (!newSupplierEmail.match(emailRegex)) {
        setEmailValidationError('Invalid email address.');
      } else {
        setEmailValidationError('');
      };
    };
  };
  const handleSupplierMobileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedVendor) {
      const newVendorMobile = e.target.value.trim();
      setSelectedVendor({
        ...selectedVendor,
        mobile_no: newVendorMobile,
      });
      if (!newVendorMobile) {
        setMobileValidationError('Mobile Number is required.');
      } else {
        const numberRegex = /^[+0-9]+$/;
        if (!numberRegex.test(newVendorMobile)) {
          setMobileValidationError('Mobile Number must be in numeric value.');
        } else {
          setMobileValidationError('');
        };
      };
    };
  };
  const handleSupplierTelephoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedVendor) {
      const newVendorTelephone = e.target.value;
      setSelectedVendor({
        ...selectedVendor,
        telephone: newVendorTelephone,
      });
      const telephoneRegex = /^[0-9]*$/;
      if (!newVendorTelephone.match(telephoneRegex)) {
        setTelephoneValidationError('Invalid telephone format.');
      } else {
        setTelephoneValidationError('');
      };
    };
  };
  const handleSupplierAddress1Change = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedVendor) {
      const newVendorAddress = e.target.value;
      if (!newVendorAddress) {
        setAddressValidationError('Address is required.');
      } else {
        setAddressValidationError('');
      };
      setSelectedVendor({
        ...selectedVendor,
        address_line_1: newVendorAddress,
      });
    };
  };
  const handleSupplierAddress2Change = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedVendor) {
      setSelectedVendor({
        ...selectedVendor,
        address_line_2: e.target.value,
      });
    };
  };
  const handleSupplierTownChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedVendor) {
      const newVendorTown = e.target.value;
      if (!newVendorTown) {
        setTownValidationError('Town is required.');
      } else {
        setTownValidationError('');
      };
      setSelectedVendor({
        ...selectedVendor,
        town: newVendorTown,
      });
    };
  };
  const handleSupplierCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedVendor) {
      const newVendorCity = e.target.value;
      if (!newVendorCity) {
        setCityValidationError('City is required.');
      } else {
        setCityValidationError('');
      };
      setSelectedVendor({
        ...selectedVendor,
        city: newVendorCity,
      });
    };
  };
  const handleSupplierPostcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedVendor) {
      const newVendorPostcode = e.target.value;
      if (!newVendorPostcode) {
        setPostcodeValidationError('Post code is required.');
      } else {
        setPostcodeValidationError('');
      };
      setSelectedVendor({
        ...selectedVendor,
        post_code: newVendorPostcode,
      });
    };
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Edit Vendor</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to List Vendors"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/vendors/vendors-list')}
          >
            List of Vendors
          </button>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {selectedVendor ? (
            <>
              {/* ... Edit Form ... */}
              <Form>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Vendor Type</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <Form.Select
                      className='form-select form-select-solid form-select-lg fw-bold'
                      name='type'
                      id='type'
                      value={selectedVendor ? selectedVendor.supplier_type : ''}
                      onChange={(e) => handleSupplierTypeChange(e)}
                    >
                      <option>Individual</option>
                      <option>Company</option>
                    </Form.Select>
                    {typeValidationError && (
                      <div className='text-danger'>{typeValidationError}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Vendor's Name</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='UpdateSupplierName'
                      aria-describedby='UpdateSupplierName'
                      placeholder='Enter New Name'
                      value={selectedVendor ? selectedVendor.supplier_name : ''}
                      onChange={(e) => handleSupplierNameChange(e)}
                    />
                    {nameValidationError && (
                      <div className='text-danger'>{nameValidationError}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Vendor's Email</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='email'
                      className='form-control form-control-lg form-control-solid'
                      id='UpdateSupplierName'
                      aria-describedby='UpdateSupplierName'
                      placeholder='Enter New Name'
                      value={selectedVendor ? selectedVendor.email : ''}
                      onChange={(e) => handleSupplierEmailChange(e)}
                    />
                    {emailValidationError && (
                      <div className='text-danger'>{emailValidationError}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Telephone</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='tel'
                      className='form-control form-control-lg form-control-solid'
                      id='UpdateSupplierName'
                      aria-describedby='UpdateSupplierName'
                      placeholder='Enter Telephone No.'
                      value={selectedVendor ? selectedVendor.telephone : ''}
                      onChange={(e) => handleSupplierTelephoneChange(e)}
                    />
                    {telephoneValidationError && (
                      <div className='text-danger'>{telephoneValidationError}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Mobile</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='tel'
                      className='form-control form-control-lg form-control-solid'
                      id='UpdateSupplierName'
                      aria-describedby='UpdateSupplierName'
                      placeholder='Enter Mobile No.'
                      value={selectedVendor ? selectedVendor.mobile_no : ''}
                      onChange={(e) => handleSupplierMobileChange(e)}
                    />
                    {mobileValidationError && (
                      <div className='text-danger'>{mobileValidationError}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Address Line 1</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='UpdateSupplierName'
                      aria-describedby='UpdateSupplierName'
                      placeholder='Enter Address Line 1'
                      value={selectedVendor ? selectedVendor.address_line_1 : ''}
                      onChange={(e) => handleSupplierAddress1Change(e)}
                    />
                    {addressValidationError && (
                      <div className='text-danger'>{addressValidationError}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Address Line 2</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='UpdateSupplierName'
                      aria-describedby='UpdateSupplierName'
                      placeholder='Enter Address Line 2'
                      value={selectedVendor ? selectedVendor.address_line_2 : ''}
                      onChange={(e) => handleSupplierAddress2Change(e)}
                    />
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Town</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='UpdateSupplierName'
                      aria-describedby='UpdateSupplierName'
                      placeholder='Enter Town'
                      value={selectedVendor ? selectedVendor.town : ''}
                      onChange={(e) => handleSupplierTownChange(e)}
                    />
                    {townValidationError && (
                      <div className='text-danger'>{townValidationError}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>City</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='UpdateSupplierName'
                      aria-describedby='UpdateSupplierName'
                      placeholder='Enter City'
                      value={selectedVendor ? selectedVendor.city : ''}
                      onChange={(e) => handleSupplierCityChange(e)}
                    />
                    {cityValidationError && (
                      <div className='text-danger'>{cityValidationError}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Postcode</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      id='UpdateSupplierName'
                      aria-describedby='UpdateSupplierName'
                      placeholder='Enter Postcode'
                      value={selectedVendor ? selectedVendor.post_code : ''}
                      onChange={(e) => handleSupplierPostcodeChange(e)}
                    />
                    {postcodeValidationError && (
                      <div className='text-danger'>{postcodeValidationError}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Status</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <Form.Select
                      className='form-select form-select-solid form-select-lg fw-bold'
                      name='type'
                      id='type'
                      value={selectedVendor ? selectedVendor.status : ''}
                      onChange={(e) => handleSupplierStatusChange(e)}
                    >
                      <option value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </Form.Select>                    
                  </div>
                </div>
              </Form>
            </>
          ) : null}
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        {typeValidationError ||
          nameValidationError ||
          emailValidationError ||
          mobileValidationError ||
          telephoneValidationError ||
          addressValidationError ||
          townValidationError ||
          cityValidationError ||
          postcodeValidationError ? (
          <button data-toggle="tooltip" data-placement="bottom" title="Update Vendor" type='button' className='btn btn-primary disabled'>
            Update Vendor
          </button>
        ) : (
          <button
            data-toggle="tooltip" data-placement="bottom" title="Update Vendor"
            type='button'
            className='btn btn-primary'
            onClick={handleUpdateSupplier}
            disabled={loading}
          >
            {!loading && 'Update Vendor'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  );
}

export default EditVendor;
