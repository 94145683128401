import { useState, useEffect, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { get_all_products, update_product } from '../api/productsApi';
import { get_all_active_categories } from '../../categories/api/categoriesApi';
import { get_all_active_subcategories } from '../../subcategories/api/subcategoriesApi';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';
type Product = {
    id: number | undefined;
    name: string | undefined;
    product_type: string | undefined;
    price: number | undefined;
    categories_id: number | undefined;
    category_title: string | undefined;
    sub_categories_id: number | undefined;
    sub_category_title: string | undefined;
    short_description: string | undefined;
    long_description: string | undefined;
    picture: string | undefined;
    status: string | undefined;
    updated_by: string | undefined;
    updated_at: string;
};
interface Subcategory {
    id: number;
    title: string;
    categories_id: number;
    categories_title: string;
    sub_categories_id: number;
    sub_category_title: string;
};
interface Category {
    id: number;
    title: string;
    categories_id: number;
    category_title: string;
};
function EditProduct() {
    const [selectedProduct, setSelectedProduct] = useState<Product | null>();
    const [categories, setCategories] = useState<Category[]>([]);
    const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
    const [typeValidationError, setTypeValidationError] = useState('');
    const [productImage, setProductImage] = useState<File | null>(null);
    const [selectedSubcategoryId, setSelectedSubcategoryId] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const [nameValidationError, setNameValidationError] = useState('');
    const [priceValidationError, setPriceValidationError] = useState('');
    const [shortDescriptionError, setShortDescriptionError] = useState('');
    const [subCategoryValidationError, setSubCategoryValidationError] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const product = (location.state as { product: Product }).product;
    const {
        refetch,
    } = useQuery('products', get_all_products, {refetchOnWindowFocus: false});
    useEffect(() => {
        setSelectedProduct(product);
    }, [product]);
    const ref = useRef<HTMLInputElement>(null);
    const { data: categoryData } = useQuery('categories', get_all_active_categories, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (categoryData) {
            setCategories(categoryData.data as Category[]);
        };
    }, [categoryData]);
    const handleUpdateProducts = () => {
        try {
            setLoading(true);
            if (!selectedProduct) {
                throw new Error('No product selected for update');
            }
            const {
                id,
                name,
                short_description,
                long_description,
                price,
                product_type,
                categories_id,
                category_title,
                sub_categories_id,
                sub_category_title,
                status,
            } = selectedProduct;
            const formData: any = new FormData();
            formData.append('id', id);
            formData.append('name', name);
            formData.append('product_type', product_type);
            formData.append('short_description', short_description);
            formData.append('long_description', long_description);
            formData.append('price', price);
            formData.append('categories_id', categories_id);
            formData.append('category_title', category_title);
            formData.append('sub_categories_id', sub_categories_id);
            formData.append('sub_category_title', sub_category_title);
            formData.append('status', status);
            if (productImage) {
                formData.append('picture', productImage);
            }
            updateProductMutation.mutate({
                id,
                formData,
            });
        } catch (error: any) {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
            }
            setLoading(false);
        };
    };
    const updateProductMutation = useMutation((Data: any) => update_product(Data.id, Data.formData), {
        onSuccess: (response) => {
            refetch();
            setLoading(false);
            navigate('/products/products-list');
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
            ref.current && (ref.current.value = '');
        },
        onError: (error: any) => {
            if (error.response) {
                const errorMessage = error.response.data.message;
                toast.error(errorMessage, {
                    position: 'top-right',
                    autoClose: 2000,
                })
            }
            setLoading(false);
        },
    });
    const handleProductNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedProduct) {
            const newProductName = e.target.value;
            if (!newProductName) {
                setNameValidationError('Product Name is required.');
            } else {
                setNameValidationError('');
            };
            setSelectedProduct({
                ...selectedProduct,
                name: newProductName,
            });
        };
    };
    const handleProductImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedProduct) {
            const newPicture = e.target.files?.[0] || null;
            setSelectedProduct({
                ...selectedProduct,
                picture: newPicture ? URL.createObjectURL(newPicture as Blob) : undefined,
            })
            setProductImage(newPicture);
        };
    };
    const handleProductshortDesChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedProduct) {
            const newProductshortDes = e.currentTarget.value;
            if (!newProductshortDes) {
                setShortDescriptionError('Short Description is required.');
            } else {
                setShortDescriptionError('');
            };
            setSelectedProduct({
                ...selectedProduct,
                short_description: newProductshortDes,
            });
        };
    };
    const handleProductSubCategoryChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (selectedProduct) {
            const newProductSubcategory = Number(e.target.value);
            if (!newProductSubcategory) {
                setSubCategoryValidationError('Subcategory is required.');
            } else {
                setSubCategoryValidationError('');
            };
            setSelectedProduct({
                ...selectedProduct,
                sub_categories_id: newProductSubcategory,
                sub_category_title: subcategories.find(
                    (subcategory) => subcategory.id === newProductSubcategory
                )?.title,
            });
        };
    };
    const handleProductTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (selectedProduct) {
            const producttype = e.target.value;
            if (!producttype) {
                setTypeValidationError('Product type is required.');
            } else {
                setTypeValidationError('');
            };
            setSelectedProduct({
                ...selectedProduct,
                product_type: producttype,
            });
        };
    };
    const handleProductLongDesChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (selectedProduct) {
            const newProductLongDes = e.target.value;
            setSelectedProduct({
                ...selectedProduct,
                long_description: newProductLongDes,
            });
        };
    };
    const handleProductPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedProduct) {
            const newProductPrice = Number(e.target.value);
            if (isNaN(newProductPrice) || newProductPrice <= 0) {
                setPriceValidationError('Please enter a valid positive number for the price.');
            } else {
                const priceRegex = /^\d+(\.\d{0,2})?$/
                if (!priceRegex.test(newProductPrice.toString())) {
                    setPriceValidationError('Please enter a valid price with up to 2 decimal places.');
                } else {
                    setPriceValidationError('');
                };
            };
            setSelectedProduct({
                ...selectedProduct,
                price: newProductPrice,
            });
        };
    };
    const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (selectedProduct) {
          setSelectedProduct({
            ...selectedProduct,
            status: e.target.value,
          });
        };
      };
    const handleCategoryTypeChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        if (selectedProduct) {
            const newCategoryType = Number(e.target.value);
            setSelectedProduct({
                ...selectedProduct,
                categories_id: newCategoryType,
                category_title: categories.find((category) => category.id === newCategoryType)?.title,
            });
            const newSubcategories = await handleCategoryChange(newCategoryType);
            setSubcategories(newSubcategories);
        };
    };
    const handleCategoryChange = async (selectedCategoryId) => {
        const subcategoriesData = await get_all_active_subcategories();
        if (Array.isArray(subcategoriesData.data)) {
            const filteredSubcategories = subcategoriesData.data.filter(
                (subcategory) => subcategory.categories_id === selectedCategoryId
            )
            setSubcategories(filteredSubcategories);
            if (
                selectedProduct &&
                selectedProduct.sub_categories_id &&
                filteredSubcategories.some(
                    (subcategory) => subcategory.id === selectedProduct.sub_categories_id
                )
            ) {
                setSelectedSubcategoryId(selectedProduct.sub_categories_id);
            } else {
                setSelectedSubcategoryId(null);
                setSubCategoryValidationError('Please Select a Subcategory');
                setSelectedProduct((prevProduct: any) => ({
                    ...prevProduct,
                    sub_categories_id: null,
                    sub_category_title: 'Select a Subcategory',
                }));
            };
            return filteredSubcategories;
        } else {
            return [];
        };
    };
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Edit Product</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/products/products-list')}
                    >
                        List of Products
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    {selectedProduct ? (
                        <>
                            {/* ... Edit Form ... */}
                            <Form>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Category</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <Form.Group controlId='type'>
                                            <Form.Select
                                                name='type'
                                                className='form-select form-select-solid form-select-lg fw-bold'
                                                value={selectedProduct ? selectedProduct.categories_id : ''}
                                                onChange={(e) => {
                                                    handleCategoryTypeChange(e)
                                                    handleCategoryChange(Number(e.target.value))
                                                }}
                                            >
                                                {categories.map((categoryType) => (
                                                    <option key={categoryType.id} value={categoryType.id}>
                                                        {categoryType.title}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Subcategory</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <Form.Group controlId='subCategorytypeNew'>
                                            <Form.Select
                                                name='subCategorytypeNew'
                                                className={`form-select form-select-solid form-select-lg fw-bold ${subCategoryValidationError ? 'is-invalid' : ''
                                                    }`}
                                                value={selectedProduct ? selectedProduct.sub_categories_id : ''}
                                                onChange={handleProductSubCategoryChange}
                                            >
                                                {selectedProduct && selectedProduct.categories_id && (
                                                    <option value={selectedProduct.sub_categories_id}>
                                                        {selectedProduct.sub_category_title}
                                                    </option>
                                                )}
                                                {subcategories.map((subcategoryType) => (
                                                    <option key={subcategoryType.id} value={subcategoryType.id}>
                                                        {subcategoryType.title}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {subCategoryValidationError && (
                                                <div className='invalid-feedback'>{subCategoryValidationError}</div>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Product Type:</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <select
                                            className='form-select form-select-solid form-select-lg fw-bold'
                                            name='type'
                                            value={selectedProduct ? selectedProduct.product_type : ''}
                                            onChange={(e) => handleProductTypeChange(e)}
                                        >
                                            <option value="asset">Asset</option>
                                            <option value="consumable">Consumable</option>
                                        </select>                                        
                                        {typeValidationError && (
                                            <div className='text-danger'>{typeValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Product Name
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            id='UpdateProductName'
                                            aria-describedby='UpdateProductName'
                                            placeholder='Enter New Name'
                                            value={selectedProduct ? selectedProduct.name : ''}
                                            onChange={(e) => handleProductNameChange(e)}
                                        />
                                        {nameValidationError && (
                                            <div className='text-danger'>{nameValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>Product Picture</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        {selectedProduct.picture && (
                                            <img
                                                src={selectedProduct.picture}
                                                alt='Product Picture'
                                                className='product-image'
                                                style={{ maxWidth: '60%', height: 'auto' }}
                                            />
                                        )}
                                        <input
                                            type='file'
                                            ref={ref}
                                            name='picture'
                                            className='form-control form-control-lg form-control-solid mt-2'
                                            onChange={(e) => handleProductImageChange(e)}
                                        />
                                        <div className='text-danger mt-2'>
                                            Recommended size 200 x 200 px. jpg, jpeg, or png only
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Short Description
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            id='UpdateProductShortDes'
                                            aria-describedby='UpdateProductShortDes'
                                            placeholder='Enter Short Description'
                                            value={selectedProduct ? selectedProduct.short_description : ''}
                                            onChange={(e) => handleProductshortDesChange(e)}
                                        />
                                        {shortDescriptionError && (
                                            <div className='text-danger'>{shortDescriptionError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        Long Description
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <textarea
                                            className='form-control form-control-lg form-control-solid'
                                            id='UpdateProductLongDes'
                                            aria-describedby='UpdateProductLongDes'
                                            placeholder='Enter Long Description'
                                            value={selectedProduct ? selectedProduct.long_description : ''}
                                            onChange={(e) => handleProductLongDesChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Price ({getSettingValue('currency_symbol')})</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='number'
                                            step='0.01'
                                            className='form-control form-control-lg form-control-solid'
                                            id='UpdateProductPrice'
                                            aria-describedby='UpdateProductPrice'
                                            placeholder='Price'
                                            value={selectedProduct && selectedProduct.price !== 0 ? selectedProduct.price : 'Price'}
                                            onChange={(e) => handleProductPriceChange(e)}
                                        />
                                        {priceValidationError && (
                                            <div className='text-danger'>{priceValidationError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>Status</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <Form.Select
                                            className='form-select form-select-solid form-select-lg fw-bold'
                                            name='type'
                                            id='type'
                                            value={selectedProduct ? selectedProduct.status : ''}
                                            onChange={(e) => handleStatusChange(e)}
                                        >
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </Form>
                        </>
                    ) : null}
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        {nameValidationError || priceValidationError || subCategoryValidationError || shortDescriptionError ? (
                            <button type='button' data-toggle="tooltip" data-placement="bottom" title="Update Product" className='btn btn-primary disabled'>
                                Update Product
                            </button>
                        ) : (
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Update Product"
                                type='button'
                                className='btn btn-primary'
                                onClick={handleUpdateProducts}
                                disabled={loading}
                            >
                                {!loading && 'Update Product'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EditProduct;